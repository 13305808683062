import { getPathnameWithQuery, RequestHelp, RequestUtils } from "../requests";

export default class HelpModel {
    static getHelps = async(url='') => {
        let response = await RequestHelp.getHelps(url);
        let data = RequestUtils.getResponseData(response);
        const {helps} = data;
        if (helps.length > 0) {
            return data;
        }
    }

    static addHelp = async(params) => {
        let response = await RequestHelp.addHelp(params);
        return RequestUtils.getResponseData(response);
    }

    static updateHelp = async(params) => {
        let response = await RequestHelp.updateHelp(params);
        return RequestUtils.getResponseData(response);
    }

    static deleteHelp = async(id) => {
        let response = await RequestHelp.deleteHelp(id);
        return RequestUtils.getResponseData(response);
    }
}