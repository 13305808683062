import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableFormInput, { IndexCell, ToolbarButtons } from "../../components/FormInput/TableFormInput";
import { useState, useRef, useEffect } from "react";
import { Input, MenuItem, Select } from "@mui/material";
import LeagueModel from "../../serverUtils/models/LeagueModel";
import LoadingList from "../../components/LoadingList/LoadingList";
import AlertPane from "../../components/FormInput/AlertPane";
import TextAreaFormInput from "../../components/FormInput/TextAreaFormInput";
import ImageFormInput from "../../components/FormInput/ImageFormInput";
import { DATA_HAS_CHANGED_MESSAGE, IMAGE_TYPE, hasTableFormChange } from "../../components/Form/Form";
import CartModel from "../../serverUtils/models/CartModel";
import { STATUS_OPTIONS } from "../../components/Bracket/Bracket";
import "./StoreItems.scss";
import { StoreItemSchema } from "../../serverUtils/Models";
import Utils from "../../serverUtils/Utils";
import SelectFormInput from "../../components/FormInput/SelectFormInput";

export const STORE_ITEMS_HEAD_CELLS = [
  {
    id: "images",
    numeric: false,
    disablePadding: false,
    label: "Images",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "sku",
    numeric: false,
    disablePadding: false,
    label: "SKU",
  },
  {
    id: "unit_available",
    numeric: false,
    disablePadding: false,
    label: "Unit Available",
  },
  {
    id: "price",
    numeric: false,
    disablePadding: false,
    label: "Price",
    align: "center",
  },
  {
    id: "shipping_cost",
    numeric: true,
    disablePadding: false,
    label: "Shipping",
    align: "center",
  },
  {
    id: "other_cost",
    numeric: true,
    disablePadding: false,
    label: "Other Fee",
  },
  {
    id: "description",
    numeric: true,
    disablePadding: false,
    label: "Description",
  },
];

const CRC_Fields = Utils.listObjectKeys(StoreItemSchema().model);
const editable = { cursor: 'pointer' };
export default function LeagueStoreItems({ league }) {
  const [data, setData] = useState();
  const [message, setMessage] = useState("");
  const checkCRCMessageRef = useRef();
  const checkCRCMessageRef1 = useRef();
  const toolbarButtonsRef = useRef();
  const toolbarButtonsRef1 = useRef();
  const checkCRC = () => {
    checkCRCMessageRef.current.innerHTML = '';
    checkCRCMessageRef1.current.innerHTML = '';
    if (hasTableFormChange(league.getAgeGroups(), CRC_Fields)) {
        checkCRCMessageRef.current.innerHTML = DATA_HAS_CHANGED_MESSAGE;
        checkCRCMessageRef1.current.innerHTML = DATA_HAS_CHANGED_MESSAGE;
        toolbarButtonsRef.current.setIndicator({save: 'yellow'});
        toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
    }else {
        toolbarButtonsRef.current.setIndicator({save: ''});
        toolbarButtonsRef1.current.setIndicator({save: ''});
    }
  }

  const getData = async () => {
    let storeItems = await LeagueModel.getStoreItems(league.id);
    storeItems = storeItems.filter(si => si);
    storeItems.forEach(si => !si.crc && (si.crc = Utils.getCRC(si, CRC_Fields)));
    setData(storeItems);
  };
  useEffect(() => {
    league && getData();
  }, [league]);

  const tableRef = useRef();

  const doSave = async () => {
    await CartModel.storeItemsSave(setMessage, data, tableRef);
    data.forEach(si => !si.crc && (si.crc = Utils.getCRC(si, CRC_Fields)));
  };

  const doNew = () => {
    CartModel.newStoreItem(data, setData, 'league', league.id);
    checkCRC();
  };

  const doDelete = async () => {
    await CartModel.storeItemDelete(setMessage, data, tableRef, setData);
    checkCRC();
  };

  const getFilterData = ({ headCell, filterString }, filterData, isFilter) => {
    
  };

  const tableComparator = (a, b, orderBy) => {
    let _a, _b;
    if (orderBy === "images") {
      return 0;
    } else {
      _a = a[orderBy];
      _b = b[orderBy];
    }

    _a = _a ? _a.toString() : "";
    _b = _b ? _b.toString() : "";
    if (_b < _a) {
      return -1;
    }
    if (_b > _a) {
      return 1;
    }
    return 0;
  };

  const toolbarButtons = (ref ) => {
    return <ToolbarButtons ref={ref} doAdd={doNew} doDelete={doDelete} doSave={doSave} />
  }
  return !data ? <LoadingList /> :
    <div className="LeagueStoreItems">
      <AlertPane message={message} />
      <TableFormInput name="LeagueStoreItems"
        isEditable
        ref={tableRef}
          headCells={STORE_ITEMS_HEAD_CELLS}
        data={data}
        getFilterData={getFilterData}
        comparator={tableComparator}
        onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
        onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
        toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
        toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
        renderTRow={({
          row,
          isSelected,
          index,
          handleClick,
          }) => {
            return <StoreItems row={row} key={index}
              isSelected={isSelected}
              index={index}
              handleClick={handleClick}
              data={data} 
              onChange={checkCRC}
            />
        }}
      />
    </div>
}

export const StoreItems = ({
  row,
  isSelected,
  index,
  handleClick,
  onChange
}) => {
  const [refresh, setRefresh] = useState(false);
  const isItemSelected = isSelected && isSelected(row.id);
  const labelId = `enhanced-table-checkbox-${index}`;
  return <TableRow className={`StoreItem TableRow ${row.id.startsWith('-')? 'is-add':''}`}
    key={index}
    hover
    onClick={(event) => {
      if (event.target.tagName.toUpperCase() === "INPUT") {
        return handleClick(event, row.id);
      }
    }}
    role="checkbox"
    aria-checked={isItemSelected}
    tabIndex={-1}
    selected={isItemSelected}
  >
    <TableCell padding="checkbox">
      <IndexCell isItemSelected={isItemSelected} labelId={labelId} index={index + 1} />
    </TableCell>
    <TableCell>
      <div style={editable}>
        <div className="images">
          <ImageFormInput name="images"
            images={row.getImages && row.getImages()}
            onImage={images => {
              row.getImages = () => images.map(i => {
                i.image_type = IMAGE_TYPE.store_item;
                return i;
              });
              onChange();
            }}
            multiple
          />
        </div>
      </div>
    </TableCell>
    <TableCell >
      <Input defaultValue={row.name} className="name"
        onChange={e => {
          row.sku = e.target.value;
          onChange();
        }}
        onClick={e => e.stopPropagation()} />
    </TableCell>
    <TableCell >
      <SelectFormInput value={(row.status) || ''} className="status"
        onChange={v => {
          row.status = v;
          setRefresh(!refresh);
          onChange();
        }} 
        options={STATUS_OPTIONS}
      />
    </TableCell>
    <TableCell align="center">
      <Input className="sku"
        onClick={e => e.stopPropagation()} inputProps={{ style: { textTransform: 'uppercase' } }} 
        defaultValue={row.sku} 
        onChange={e => {
          row.sku = e.target.value;
          onChange();
        }}
        />
    </TableCell>
    <TableCell align="center">
      <Input className="unit_available"
        defaultValue={row.unit_available} 
        onChange={e => {
          row.sku = e.target.value;
          onChange();
        }}
        onClick={e => e.stopPropagation()} />
    </TableCell>
    <TableCell align="center">
      <Input defaultValue={row.unit_price} 
        onChange={e => {
          row.sku = e.target.value;
          onChange();
        }}
        onClick={e => e.stopPropagation()} />
    </TableCell>
    <TableCell align="center">
      <Input defaultValue={row.shipping_cost} 
        onChange={e => {
          row.sku = e.target.value;
          onChange();
        }}
        onClick={e => e.stopPropagation()} />
    </TableCell>
    <TableCell align="center">
      <Input defaultValue={row.other_cost} 
        onChange={onChange}
        onClick={e => e.stopPropagation()} />
    </TableCell>
    <TableCell align="center">
      <TextAreaFormInput defaultValue={row.description}
        onChange={e => {
          row.description = e.target.value;
          onChange();
        }}
        onClick={e => e.stopPropagation()}/>
    </TableCell>
  </TableRow>;
}

