import { useEffect, useState } from "react";
import {
  IonContent, IonPage, IonButtons,
  IonIcon,
  useIonViewWillEnter
} from "@ionic/react";
import pageStyles from "./../Page.module.scss";
import LoadingList from "../../components/LoadingList/LoadingList";
import classNames from "classnames";
import UserModel from "../../serverUtils/models/UserModel";
import { addCircleOutline as addIcon } from "ionicons/icons";
import { Link, ThemeProvider } from "@mui/material";
import { useStore } from "../../Store";
import Theme from "../../components/FormInput/Theme";
import UserChip from "../../components/UserChip/UserChip";
import "./UsersPage.scss";
import { getFlag } from "../UserPage/UserPage";
import { FilterInput } from "../../components/Form/Form";
import Utils from "../../serverUtils/Utils";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import { COUNTRIES } from "../../components/Form/AddressForm";
import GymModel from "../../serverUtils/models/GymModel";
import TeamModel from "../../serverUtils/models/TeamModel";

const UsersPage = (props) => {
  const session = useStore((state) => state.session);
  const [users, setUsers] = useState(props.memberships);
  const [country, setCountry] = useState('');
  const [team, setTeam] = useState('');
  const [gym, setGym] = useState('');
  const [gyms, setGyms] = useState();
  const [teams, setTeams] = useState();
  const searchStrings = useStore(state => state.searchStrings);
  const setSearchStrings = useStore(state => state.setSearchStrings);
  const countries = COUNTRIES.map(c => ({label: c.name, value:c.two_code}));

  const getData = async (search_string = searchStrings.users) => {
    const users = await UserModel.getUsers({ search_string });
    if (users) {
      setUsers(users.map(u => {
        u.last_name = u.last_name.trim();
        return u;
      }).sort((a, b) => Utils.sorter(a, b, 'last_name')));
    }
  };

  useEffect(() => {
    if (!team && !gym) {
      doFilter();
    }
    if (country) {
      GymModel.getGymsbyCountry(country).then(gs => setGyms(gs.map(g => ({value: g.id, label: g.name}))));
      TeamModel.getTeamsByCountry(country).then(ts => setTeams(ts.map(t => ({value: t.id, label: t.name}))));
    }else {
      setGym(null);
      setTeam(null);
    }
  }, [country]);

  useEffect(() => {
    doFilter();
  }, [team, gym]);

  useIonViewWillEnter(() => {
    if (!users) {
      getData(searchStrings.users);
    }
  }, [users, searchStrings.users, session]);

  const doFilter = s => {
    let sObj = {
      user_filter: s,
      country: country,
      gym: gym||'',
      team: team||''
    }
    s = encodeURIComponent(JSON.stringify(sObj));
    setSearchStrings('users', s);
    getData(s);
  };

  const UserItem = ({user}) => {
    const [flag, setFlag] = useState();
    useEffect(() => getFlag(user.country).then(f => setFlag(f)), [user]);
    return <div className="UserItem">
      <div className="user">
        <UserChip membership={user}/>
      </div>
      {user.getGym() && <Link >{user.getGym().name || ''}<span className="entity-type">@gym</span></Link>}
      {user.getTeam() && <Link >{user.getTeam().name || ''}<span className="entity-type">@team</span></Link>}
      {user.getLeague() && <Link >{user.getGym().name || ''}<span className="entity-type">@league</span></Link>}
      <p>{user.country} {flag && <IonIcon className={`flag`} icon={flag} />}</p>
      {/*{tournament.locations.map((location, i) => <p key={`location-${i}`}>{location.city + ','} {location.state + ','} {location.country}</p>)}*/}
    </div>;
  }

  return (
    <IonPage className={`${pageStyles.page} UsersPage`}>
      <ThemeProvider theme={Theme}>
        <IonContent>
          <div className={`${pageStyles.pageHeader}`}>
            <div className={pageStyles.pageHeaderImage}/>
            <div className={`${pageStyles.pageHeaderTitleWrapper} pageHeaderTitleWrapper`}>
              <h1
                id="users_page"
                className={classNames("display-font", pageStyles.pageHeaderTitle)}
              >
                Users
              </h1>
              <div className="filters">
                <div className="filter">
                  <FilterInput label="Filter by Name" entity="users" onFilter={v => doFilter(v)} />
                </div>
                <div className="filter select">
                  <SelectFormInput
                    label="Country"
                    options={[{value: '', label: <span>&nbsp;</span>}, ...countries]}
                    onChange={v => {
                      setCountry(v);
                    }}
                    value={country}
                  />
                </div>
                <div className="filter select">
                  <SelectFormInput
                    label="Gym"
                    options={[{value: '', label: <span>&nbsp;</span>}, ...(gyms||[])]}
                    onChange={v => {
                      setGym(v);
                    }}
                    value={gym}
                  />
                </div>
                <div className="filter select">
                  <SelectFormInput
                    label="Team"
                    options={[{value: '', label: <span>&nbsp;</span>}, ...(teams||[])]}
                    onChange={v => {
                      setTeam(v);
                    }}
                    value={team}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={`${pageStyles.marginBottom} entity-list-body`}>
            {UserModel.isAdmin(session) && (
              <IonButtons className={pageStyles.create_button}>
                <Link href={`/users/add`} id="user_add" >
                  <button className="button">
                    <IonIcon icon={addIcon} /> Add a new User
                  </button>
                </Link>
              </IonButtons>
            )}
            {users ? (
              <div className="users">
                {users.map((user, i) => (
                  <UserItem user={user} key={i}/>
                ))}
              </div>
            ) : (
              <LoadingList />
            )}
          </div>
        </IonContent>
      </ThemeProvider>
    </IonPage>
  );
};

export default UsersPage;
