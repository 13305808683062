import TextFormInput from "../FormInput/TextFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import { DATA_HAS_CHANGED_MESSAGE, STATUS, hasTableFormChange, reloadPage } from "../Form/Form";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import "./../Form/Form.scss";
import "./Promos.scss";
import { GENDER_TYPES } from "../LeagueInformationForm/WeightClasses";
import { getDivisionDisplay } from "../LeagueInformationForm/Divisions";
import { RequestTournament, RequestUtils } from "../../serverUtils/requests";
import { Checkbox, FormControlLabel, Input, Radio, RadioGroup, TableCell, TableRow } from "@mui/material";
import TableFormInput, { IndexCell, ToolbarButtons } from "../FormInput/TableFormInput";
import AlertPane from "../FormInput/AlertPane";
import Utils from "../../serverUtils/Utils";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import TeamModel from "../../serverUtils/models/TeamModel";
import { default as DeleteIcon } from "@mui/icons-material/HighlightOff";
import UserModel from "../../serverUtils/models/UserModel";
import DateFormInput1 from "../FormInput/DateFormInput1";
const { DISCOUNT_TYPES, PROMO_RULE_TYPES } = require("../../serverUtils/models/modelMethods");

export default function Promos({ tournament }) {
  const [message, setMessage] = useState("");
  return (
    <div className="Promos" >
      <AlertPane message={message} timeOut={5000}/>
      <PromoTable tournament={tournament} setMessage={setMessage} />
    </div>
  );
}

function DiscountRules({ discountRules, tournament, onAccept }) {
  const [add, setAdd] = useState(false);
  const [rules, setRules] = useState(discountRules || []);

  const onChange = (v) => {
    if (v.logic === 'remove') {
      rules.splice(v.index, 1);
    } else {
      rules[v.index] = v;
    }

    setRules([...rules]);
    onAccept(rules);
  }

  const addRule = (rule_type) => {
    let rule = { rule_type, logic: 'OR' };
    setRules([...rules, rule]);
    setAdd(false);
  }

  return <div className="DiscountRules">
    {rules && rules.map((r, i) => {
      r.index = i;
      return <div key={i} className="DiscountRule">
        <DiscountRule index={i} key={i} rule={r} tournament={tournament} onChange={onChange} />
      </div>;
    })}
    {add &&
      <SelectFormInput name="select_rule" label="Select Rule"
        options={Object.keys(PROMO_RULE_TYPES)
          .map(t => {
            return {
              value: t,
              label: t ? t.split('_').map(n => Utils.capitalize(n)).join(' ') : t
            }
          })}
        onChange={addRule}
      />}

    <div className="buttons" >
      <button className="button small_button" onClick={(e) => {
        e.stopPropagation();
        setAdd(true);
      }}>Add Rule</button>
    </div>
  </div>;
}

const DiscountRule = ({ index, rule, tournament, onChange }) => {
  const { control } = useForm();
  const update = (v) => {
    onChange(Object.assign(rule, v));
  };

  if (rule.rule_type === PROMO_RULE_TYPES.registered_date_range) {
    return <RangeDateValue rule={rule} label="Register Date" index={index}
      onChange={update} />
  }
  if (rule.rule_type === PROMO_RULE_TYPES.age_range) {
    return <RangeValue rule={rule} label="Age" index={index}
      onChange={update} />
  }
  if (rule.rule_type === PROMO_RULE_TYPES.fee_range) {
    return <RangeValue rule={rule} label="Fee" index={index}
      onChange={update} />
  }
  if (rule.rule_type === PROMO_RULE_TYPES.weight_range) {
    return <RangeValue rule={rule} label="Weight" index={index}
      onChange={update} />
  }
  if (rule.rule_type === PROMO_RULE_TYPES.divisions) {
    return <MultiSelectValue control={control} label="Division" index={index}
        mapValues={v => {
          let d = tournament.getDivisions().find(td => td.id === v);
          return { value: v, label: getDivisionDisplay(d) };
        }}
        rule={rule}
        fetchOptions={(search) => tournament.getDivisions()
          .filter(d => {
            return !search || getDivisionDisplay(d).toUpperCase().includes(search.toUpperCase());
          }).map((d) => ({ value: d.id, label: getDivisionDisplay(d) }))}
        onChange={update}
      />
  }
  if (rule.rule_type === PROMO_RULE_TYPES.ranks) {
    return <MultiSelectValue control={control} label="Ranks" index={index}
        mapValues={v => {
          let d = tournament.getRanks().find(td => td.id === v);
          return { value: v, label: d.name };
        }}
        rule={rule}
        fetchOptions={(search) => tournament.getRanks()
          .filter(r => {
            return !search || r.name.toUpperCase().includes(search.toUpperCase());
          }).map((r) => ({ value: r.id, label: r.name }))}
        onChange={update}
      />
  }
  if (rule.rule_type === PROMO_RULE_TYPES.teams) {
    return <MultiSelectValue control={control} label="Teams" index={index}
        rule={rule}
        fetchOptions={search => TeamModel.searchTeam(search)}
        onChange={update}
      />
  }
  if (rule.rule_type === PROMO_RULE_TYPES.memberships) {
    return <MultiSelectValue control={control} label="Memberships" index={index}
        rule={rule}
        fetchOptions={async search => {
          let rs = await UserModel.searchUser(search);
          return rs.map(r => ({ value: r.id, label: r.display }));
        }}
        onChange={update}
      />
  }
  if (rule.rule_type === PROMO_RULE_TYPES.gender) {
    return <SelectValue index={index}
        onChange={update}
        rule={rule}
        label="Gender"
        options={GENDER_TYPES}
      />
  }
  return '';
}

const SelectValue = ({ rule, onChange, label, options, index }) => {
  const [value, setValue] = useState();
  const [logic, setLogic] = useState();
  useEffect(() => {
    if (rule) {
      setValue(JSON.parse(rule.value || '[]'));
      setLogic(rule.logic);
    }
  }, [rule]);

  useEffect(() => {
    value && onChange({ value, logic });
  }, [value, logic]);

  return <div className="SelectValue">
    <SelectFormInput
      onChange={v => {
        setValue(v);
      }}
      name={`select_${index}`}
      value={value}
      label={label}
      placeholder={label}
      options={options}
    />
    <LogicOption logic={logic} onChange={v => {
      if (!v) {
        rule.logic = 'remove';
        return onChange(rule);
      }
      setLogic(v);
    }} />
  </div>
}

const MultiSelectValue = ({ rule, onChange, label, control, fetchOptions, mapValues, index }) => {
  const [values, setValues] = useState();
  const [logic, setLogic] = useState();
  useEffect(() => {
    if (rule) {
      let vs = JSON.parse(rule.value || '[]');
      setValues(mapValues ? vs.map(v => mapValues(v)) : vs);
      setLogic(rule.logic);
    }
  }, [rule]);

  useEffect(() => {
    values && onChange({ value: JSON.stringify(mapValues ? values.map(v => v.value) : values), logic });
  }, [values, logic]);

  return <div className="MultiSelectValue">
    <MultiSelectFormInput
      control={control}
      value={values}
      name={`mutivalue_${index}`}
      label={label}
      optionLabel="label"
      optionValue="value"
      fetchOptions={fetchOptions}
      onChange={v => setValues(v)}
    />
    <LogicOption logic={logic} onChange={v => {
      if (!v) {
        rule.logic = 'remove';
        return onChange(rule);
      }
      setLogic(v);
    }} />
  </div>
}

const LogicOption = ({ logic = 'OR', onChange }) => {
  return <RadioGroup className="LogicOption"
    aria-labelledby="demo-controlled-radio-buttons-group"
    name="logic"
    value={logic}
    onChange={e => {
      onChange(e.target.value);
    }}
  >
    <FormControlLabel value="OR" control={<Radio />} label="Or" />
    <FormControlLabel value="AND" control={<Radio />} label="And" />
    <DeleteIcon className="delete"
      onClick={(e) => {
        onChange();
      }} />
  </RadioGroup>;
}

const RangeDateValue = ({ rule, onChange, label, index }) => {
  const { control } = useForm();
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [logic, setLogic] = useState();
  useEffect(() => {
    if (rule) {
      let v = JSON.parse(rule.value || '{}');
      setStart(v.start);
      setEnd(v.end);
      setLogic(rule.logic);
    }
  }, [rule]);

  useEffect(() => {
    start && end && onChange({ value: JSON.stringify({ start, end }), logic });
  }, [start, end, logic]);

  return <div className="RangeDateValue">
    <div className="dates">
      <DateFormInput1 hasTime
        control={control}
        onChange={e => {
          setStart(new Date(e).getTime());
        }}
        name={`start_${index}`}
        value={start}
        label={`Start ${label}`}
      />
      <DateFormInput1 hasTime
        control={control}
        onChange={e => {
          setEnd(new Date(e).getTime());
        }}
        name={`end_${index}`}
        value={end}
        label={`End ${label}`}
      />
    </div>
    <LogicOption logic={logic}
      onChange={v => {
        if (!v) {
          rule.logic = 'remove';
          return onChange(rule);
        } 
        setLogic(v);
      }} />
  </div>
}

const RangeValue = ({ rule, onChange, label, index }) => {
  const [start, setStart] = useState();
  const [end, setEnd] = useState();
  const [logic, setLogic] = useState();
  useEffect(() => {
    if (rule) {
      let v = JSON.parse(rule.value || '{}');
      setStart(v.start);
      setEnd(v.end);
      setLogic(rule.logic);
    }
  }, [rule]);

  useEffect(() => {
    start && end && onChange({ value: JSON.stringify({ start, end }), logic });
  }, [start, end, logic]);

  return <div className="RangeValue">
    <div className="range">
      <TextFormInput
        onChange={e => {
          setStart(e.target.value);
        }}
        name={`start_${index}`}
        value={start}
        label={`Start ${label}`}
      />
      <TextFormInput
        onChange={e => {
          setEnd(e.target.value);
        }}
        name={`end_${index}`}
        value={end}
        label={`End ${label}`}
      />
    </div>
    <LogicOption logic={logic}
      onChange={v => {
        if (!v) {
          rule.logic = 'remove';
          return onChange(rule);
        } 
        setLogic(v);
      }} />
  </div>
}


const headCells = [
  {
    id: "name",
    disablePadding: false,
    label: "Promo",
    align: 'left',
    crc: true,
  },
  {
    id: "status",
    disablePadding: false,
    label: "Status",
    crc: true,
  },
  {
    id: "code",
    disablePadding: false,
    label: "Code",
    crc: true,
  },
  {
    id: "start_date",
    disablePadding: false,
    label: "Start Date",
    crc: true,
  },
  {
    id: "end_date",
    disablePadding: false,
    label: "End Date",
    crc: true,
  },
  {
    id: "discount_type",
    disablePadding: false,
    label: "Discount Type",
    crc: true,
  },
  {
    id: "amount",
    disablePadding: false,
    label: "Amount",
    crc: true,
  },
  {
    id: "rules",
    label: "Discount Rules",
    crc: true,
  },
  {
    id: "is_membership_require",
    disablePadding: false,
    label: "Require League Membership?",
    crc: true,
  },
]

const alignCenter = { display: 'flex', alignItems: 'center', justifyContent: 'center' };
const PromoTable = ({ tournament, setMessage }) => {
  const tableRef = useRef();
  const [refresh, setRefresh] = useState(false);
  const [checkCRC, setCheckCRC] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const CRC_Fields = headCells.filter(h => h.crc).map(h => h.id);
  useEffect(() => tournament.getPromos().forEach(r => r.crc = Utils.getCRC(r, CRC_Fields)), []);
  useEffect(() => setHasChange(hasTableFormChange(tournament.getPromos(), CRC_Fields)), [checkCRC]);


  const save = async () => {
    setMessage('');
    let updates = tournament.getPromos()
      .filter(r => {
        let crc = Utils.getCRC(r, CRC_Fields);
        return r.crc !== crc;
      });
    if (updates.length === 0) {
      return 
    }
    try{
      for (let r of updates) {
        if (!r.name || !r.status || !r.code) {
          return setMessage('error: Name, Status, and Code fields must have values');
        }
        r.status = r.status || 'A';
        r.discount_type = r.discount_type || '$';
        let _r = Utils.copy(r);
        if (_r.rules) {
          _r.rules = _r.rules.map(di => {
            di.value = encodeURIComponent(di.value);
            return di;
          });
        }
        let response;
        if (!_r.id.startsWith('-1')) {
          response = await RequestTournament.updatePromo(_r);
        } else {
          r.tournament = tournament.id;
          response = await RequestTournament.addPromo(_r);
        }
        let data = RequestUtils.getResponseData(response);
        if (!data || data.error) {
          return setMessage('error: Error updating promo ' + r.name);
        } else if (!r.id) {
          r.id = response.id;
          r.crc = Utils.getCRC(r, CRC_Fields);
        }
      }
      tableRef.current.setSelected([]);
      setMessage('success: Successfully updated server.');
    }finally {
      updates.forEach(p => delete p.updated);
      setRefresh(!refresh);
    }
  }

  const deleteSelected = async () => {
    setMessage('');
    let selects = tournament.getPromos().filter(r => tableRef.current.selected.includes(r.id));
    if (selects.length === 0) {
      return setMessage('info: Nothing selected.');
    }
    for (let r of selects) {
      if (r.id.startsWith('-')) {
        continue;
      }
      let response = await RequestTournament.deletePromo(r.id);
      let data = RequestUtils.getResponseData(response);
      if (data.error) {
        return setMessage('error: Deleting promo ' + r.name);
      }
    }
    let promos = tournament.getPromos().filter(r => !tableRef.current.selected.includes(r.id));
    tournament.getPromos = () => promos;
    tableRef.current.setSelected([]);
    setMessage('success: Successfully deleted from server.');
    setCheckCRC(!checkCRC);
  }

  const doAdd = () => {
    let promos = [
      ...tournament.getPromos(),
      {
        id: `-${new Date().getTime()}`,
        start_date: new Date().getTime(),
        end_date: new Date().getTime(),
        discount_type: '$',
        status: 'A',
        ref: tournament.id
      }];
    tournament.getPromos = () => promos;
    reloadPage(setRefresh, () => tableRef.current.gotoLastPage());
    setCheckCRC(!checkCRC);
  }

  let promos = (tournament.getPromos && tournament.getPromos()) || [];
  let updates = promos.filter(p => p.updated);
  useEffect(() => setRefresh(!refresh), [updates.length]);
  return <div className="Promos">
    <TableFormInput name="Promos"
      onSelectedUpdate={hasChange? <span className="warning">{DATA_HAS_CHANGED_MESSAGE}</span>:''}
      isEditable
      ref={tableRef}
      headCells={headCells}
      data={promos}
      toolbarButtons={() => <ToolbarButtons doAdd={doAdd} doDelete={deleteSelected} doSave={save} />}
      renderTRow={({ row, isSelected, index, handleClick }) => {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        return (
          <TableRow
            hover
            onClick={(event) => {
              if (event.target.tagName.toUpperCase() === "INPUT" && event.target.type === 'checkbox') {
                return handleClick(event, row.id);
              }
            }}
            role="checkbox"
            aria-checked={isItemSelected}
            tabIndex={-1}
            key={index}
            selected={isItemSelected}
          >
            <TableCell padding="checkbox" >
              <IndexCell labelId={labelId} index={index + 1} isItemSelected={isItemSelected} />
            </TableCell>
            <TableCell >
              <Input defaultValue={row.name} 
                onChange={e => {
                  row.name = e.target.value;
                  row.updated = true;
                }}/>
            </TableCell>
            <TableCell >
              <SelectFormInput value={(row.status) || ''} 
                onChange={v => {
                  row.updated = true;
                  row.status = v;
                }}
                options={STATUS}
              />
            </TableCell>
            <TableCell >
              <Input className="code"
                defaultValue={row.code ||''}
                onChange={e => {
                  row.updated = true;
                  row.code = e.target.value.toUpperCase();
                }}/>
            </TableCell>

            <TableCell >
              <DateFormInput1
                name={`start_date`}
                hasTime
                label=""
                value={row.start_date || ""}
                onChange={v => {
                    row.start_date = v;
                    setCheckCRC(!checkCRC);
                }}/>
            </TableCell>
            <TableCell >
              <DateFormInput1
                name={`end_date`}
                hasTime
                label=""
                value={row.end_date || ""}
                onChange={v => {
                  row.end_date = v;
                  setCheckCRC(!checkCRC);
              }}/>
              
            </TableCell>
            <TableCell >
              <SelectFormInput
                value={(row.discount_type) || ''}
                onChange={v => {
                  row.updated = true;
                  row.discount_type = v;
                }} 
                options={DISCOUNT_TYPES}
              />
            </TableCell>
            <TableCell >
              <Input 
                defaultValue={row.amount ||''}
                onChange={e => {
                  row.updated = true;
                  row.amount = Utils.toFloatString(e.target.value);
                }}/>
            </TableCell>
            <TableCell >
              <DiscountRules
                discountRules={row.rules}
                tournament={tournament}
                onAccept={(rules) => {
                  row.updated = true;
                  row.rules = rules;
                }} />
            </TableCell>
            <TableCell >
              <div style={alignCenter}>
                <Checkbox checked={row.is_membership_require? true:false}
                  onClick={e => {
                    e.stopPropagation();
                    row.updated = true;
                    row.is_membership_require = !row.is_membership_require;
                    setRefresh(!refresh);
                  }} />
              </div>
            </TableCell>
          </TableRow>
        )
      }} />
  </div>
}

