import "./TournamentPage.scss";
import TournamentPageStyles from './TournamentPage.module.scss';
import { useEffect, useState, useRef, forwardRef, useImperativeHandle } from "react";
import { useStore } from "../../Store";
import Utils from "../../serverUtils/Utils";
import { RequestUtils } from "../../serverUtils/requests";
import { useHistory } from 'react-router-dom';
import { getDivisionTypeChips, getTournamentDivisionsByFilters } from "./DivisionRegistration";
import { BRACKET } from "../../components/Bracket/Bracket";
import { ThemeProvider } from "@emotion/react";
import Theme from "../../components/FormInput/Theme";
import { Chip } from "@mui/material";
import SelectFormInput from "../../components/FormInput/SelectFormInput";
import AlertPane from "../../components/FormInput/AlertPane";

const DivisionFilter = forwardRef(({value: chip, tournament, onSelect, onChip, includeBracketTypeSize, isActiveTab, isEditMode, division: _division}, ref) => {
    const history = useHistory();
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const [selected, setSelected] = useState();
    const [division, setDivision] = useState();
    const filterDivisions = useRef({});
    const urlParams = RequestUtils.getURLParameters();

    useImperativeHandle(ref, () => ({
        filters: filterDivisions.current, 
        setSelected, 
        initDivision, setDivision,
        division, 
        selected,
        setChipByDivision: div => {
            let chip = [...filterDivisions.current]
                .sort((a, b) => Utils.sort(a.divisions.length, b.divisions.length))
                .find(fs => fs.divisions.find(d => d.id === div));
            chip && setSelected(chip.value);
        }
    }));
    const createFilters = () => {
        filterDivisions.current = getDivisionTypeChips(tournament);
        for (let fs of filterDivisions.current) {
            let divisions = getTournamentDivisionsByFilters(tournament, [fs], [], () => [], () => [], isEditMode);
            fs.divisions = divisions;
        }

        return Object.keys(filterDivisions.current).length > 0 && filterDivisions.current;
    }

    const initDivision = (d) => {
        if (d) {
            d.getTournament = () => tournament;
            setDivision(d.pool || d.id);
            isActiveTab && RequestUtils.insertURLParam('id', d.pool || d.id, history);
            onSelect(d.pool || d.id);
        }
    }

    useEffect(() => {
        // console.log('_division', _division)
        // _division && initDivision(_division);
    }, [_division]);

    useEffect(() => {
        // console.log('isActiveTab', isActiveTab)
        // setRefresh(!refresh);
    }, [isActiveTab]);

    useEffect(() => {
        if (!tournament) {
            return;
        }
        
        let fs = createFilters();
        if (fs) {
            let f;
            if (chip) {
                f = fs.find(_f => _f.value === chip);
            }
            if (!f) {
                f = fs.find(_f => _f.value === urlParams.chip);
                if (!f) {
                    f = fs.find(filter => filter.divisions.length > 0);
                }
            }
            if (f) {
                setSelected(f.value);
                let div = f.divisions[0];
                if (urlParams.id){
                    let d = f.divisions.find(_d => [_d.id, _d.pool].includes(urlParams.id));
                    if (d) {
                        div = d;
                    }
                }
                initDivision(div);
            }
        }
    }, [tournament, isBracketEntriesUpdate, chip, ]); //urlParams.chip, urlParams.id

    const getBracketTypeSize = (b) => {
        if (includeBracketTypeSize) {
            try{
                let d = tournament.getAvailableDivisions().find(_d => [b.id, b.pool].includes(_d.id));
                if (d) {
                    if (!d.getRegistrations) {
                        d.getRegistrations = () => tournament.getRegistrations().filter(r => [...r.pool.split('|'), r.division].includes(d.id));
                    }
                    let regs = d.getRegistrations().filter(r => r.status === 'A');
                    let length = regs.length;
                    if (d.pool === d.id) {
                        length = regs.filter(r => r.pool.split('|').includes(d.pool)).length;
                    }
                    let hasThird = d.bracket_type===BRACKET.Single_Elimination && d.is_third? ' [has 3rd]':'';
                    let isModifyRR = d.is_modify_round_robin? ' modify RR':'';
                    return ` [${b.bracket_type.toUpperCase()}-${length}]${hasThird}${isModifyRR}`;
                }
            }catch(e) {
                console.log(e);
            }   
        }
        return '';
    }

    const getDivisionOptions = () => {
        if (!selected) {
            return [];
        }
        let f = filterDivisions.current.find(f => f.value === selected);
        return !f? [] : f.divisions.map(b => ({ value: b.pool || b.id, label: <div>{b.pool || b.name}<b>{getBracketTypeSize(b)}</b></div>}));
    }

    return <ThemeProvider theme={Theme}>
        {Object.keys(filterDivisions.current).length>0? 
            <div className={`DivisionFilter ${TournamentPageStyles.DivisionFilter}`}>
                <div className={`filter-chips ${TournamentPageStyles.DivisionFilterButtons}`}>
                    {filterDivisions.current.filter(f => f.divisions.length > 0).map((f, i) => {
                        return <Chip
                            key={i}
                            className="filter"
                            label={f.label}
                            variant={f.value === selected ? 'filled' : 'outlined'}
                            color={f.value === selected ? 'primary' : undefined}
                            onClick={() => {
                                setSelected(f.value);
                                onChip && onChip(f.value);
                                let divs = f.divisions;
                                initDivision(divs.length>0? divs[0] : null);
                            }}
                        />
                    })}
                </div>
                <div className="SelectFormInput-wrapper">
                    {selected && <SelectFormInput width={'98%'}
                        name="division_filter"
                        label="Division"
                        value={division || ''}
                        options={getDivisionOptions()}
                        onChange={value => {
                            isActiveTab && RequestUtils.insertURLParam('id', value, history);
                            onSelect(value);
                            setDivision(value);
                        }}
                    />}
                </div>
            </div> : <AlertPane message={`warning: There is no available division.  Please 
                check the registration status of the competitors tab.  Registration 
                status must be active.`} />}

    </ThemeProvider>
});


export default DivisionFilter;