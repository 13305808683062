import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import FormStyles from "./../Form/Form.module.scss";
import { default as AddIcon } from "@mui/icons-material/AddCircle";

import TextFormInput from "../FormInput/TextFormInput";
import ImageFormInput from "../FormInput/ImageFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import {
  CURRENCIES,
  DATA_HAS_CHANGED_MESSAGE_WARNING,
  FormActionButtons,
  handleSubListAdd,
  hasTableFormChange,
  IMAGE_TYPE, STATUS,
  stopFormSubmitByEnter
} from "../Form/Form";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import UserModel from "../../serverUtils/models/UserModel";
import GymModel from "../../serverUtils/models/GymModel";
import ContactInfos from "../Form/ContactInfos";
import SocialMedias from "../Form/SocialMedias";
import ImageModel from "../../serverUtils/models/ImageModel";
import MembershipTypes from "../Form/MembershipTypes";
import PaymentInfos, { GIHTransactionFee } from "../Form/PaymentInfos";
import CheckboxFormInput from "../FormInput/CheckboxFormInput";
import { useStore } from "../../Store";
import { AddressForm } from "../Form/AddressForm";
import "./GymInformationForm.scss";
import TeamModel from "../../serverUtils/models/TeamModel";
import { GymSchema } from "../../serverUtils/Models";
import Utils from "../../serverUtils/Utils";
import AlertPane from "../FormInput/AlertPane";
import { IonSpinner } from "@ionic/react";

const CRC_Fields = [...Utils.listObjectKeys(GymSchema().model), 'banner'];
export default function GymInformationForm({ gym }) {
  const session = useStore((state) => state.session);
  const setSession = useStore((state) => state.setSession);
  const [isMerging, setIsMerging] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, dirtyFields },
    control,
    getValues,
    getFieldState,
  } = useForm();
  const [message, setMessage] = useState("");
  const [mergeMessage, setMergeMessage] = useState("");
  const [formData, setFormData] = useState(gym || {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [hasChange, setHasChange] = useState(false);
  const [mergeGym, setMergeGym] = useState();
  const checkCRCTimeout = useRef();

  useEffect(() => {
    if (gym) {
      setValue("admins", gym.admins);
      setValue("head_coaches", gym.head_coaches);
      setValue("executives", gym.executives);
      setValue("contact_infos", gym.contact_infos);
      setValue("social_medias", gym.social_medias);
      setValue("name", gym.name);
      setValue("city", gym.city);
      setValue("email", gym.email);
      gym.crc = Utils.getCRC({...gym, ...getValues()}, CRC_Fields, true);
      setFormData({ ...gym });
    }
  }, [gym, getValues('is_auto_approve'), getValues('payment_info')]);

  const checkCRC = () => {
    checkCRCTimeout.current && clearTimeout(checkCRCTimeout.current);
    checkCRCTimeout.current = setTimeout(() => {
      let d = {...formData, ...getValues()};
      // console.log(d);
      let c = hasTableFormChange(d, CRC_Fields);
      setHasChange(c);
    }, 1000);
  }

  const onSubmit = async (_d) => {
    if (isSubmitting) {
      return;
    }
    setMessage("");
    setIsSubmitting(true);
    try {
      console.log(_d);
      let d = { ..._d, id: formData.id };
      let banner = d.banner;
      delete d.banner;
      let result = await GymModel[d.id ? "updateGym" : "addGym"](d);
      if (result && result.id) {
        d.id = result.id;
        if (banner) {
          await ImageModel.addEntityImage(banner, d, IMAGE_TYPE.gym);
          formData.getImage &&
            formData.getImage()?.id &&
            (await ImageModel.deleteImage(formData.getImage().id));
          setFormData({ ...d });
        }
        UserModel.updateAdminInfo(session, setSession, "gyms", {
          id: result.id,
          name: formData.name,
        });

        setMessage(
          `success: Successfully ${formData.id ? "updated" : "added"} gym to server.`
        );

        return setTimeout(() => document.location.reload(), 2000);
      }
      setMessage(`error: Error ${formData.id ? "updating" : "adding"} gym to server.`);
    } finally {
      setIsSubmitting(false);
    }
  };
  const doDelete = async () => {
    if (isSubmitting) {
      return;
    }
    try {
      let result = await GymModel.deleteGym(formData.id);
      if (result && result.id) {
        setMessage("success: Successfully deleted gym.");
        return setTimeout(() => {
          document.location.href = "/gyms";
        }, 1000);
      }
      setMessage("error: Error deleting gym.");
    }finally {
      setIsSubmitting(false);
    }
  };

  const onImage = (d) => {
    console.log('GymInformation')
    setValue("banner", d, { shouldDirty: true });
    checkCRC();
  }

  const merge = async () => {
    try {
      let r = await GymModel.mergeGym(formData.id, mergeGym.id);
      if (!r || r.error) {
        setMergeMessage('error: Error merging gym')
      }else {
        setMergeGym('');
        setMergeMessage('success: Successfully merged gym');
      }
    }finally {
      setIsMerging(false);
    }
    
  }

  return formData && 
    <div className="GymInformationForm">
      <div className={FormStyles.fieldDescription}>
        <h3>Merge Gym</h3>
      </div>
      <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper merge-gym`}>
        <MultiSelectFormInput
          multiple={false}
          control={control}
          value={mergeGym}
          name="mergeGym"
          label="Merge Gym"
          optionLabel="display"
          optionValue="id"
          fetchOptions={(searchVal) => GymModel.searchGym(searchVal, true).then(rs => rs.filter(rs => rs.id !== formData.id))}
          onChange={v => setMergeGym(v)}
        />
        <button className="button" 
          onClick={e => {
            e.stopPropagation();
            setIsMerging(true);
            merge();
          }}>
          Merge {isMerging && <IonSpinner name="circles" className="IonSpinner" />}
        </button>
        <AlertPane message={mergeMessage} setMessage={setMergeGym} timeOut={3000}/>
      </div>

      <form className={FormStyles.form} 
        onKeyDown={stopFormSubmitByEnter}
        onSubmit={handleSubmit(onSubmit)}>
        {session && session.is_super && <GIHTransactionFee register={register} formData={formData} />}

        <div className={FormStyles.fieldDescription}>
          <h3>Banner</h3>
        </div>
        <div className={FormStyles.fields}>
          <ImageFormInput
            name="banner"
            images={ImageModel.setImageFormInput(
              IMAGE_TYPE.gym,
              getValues,
              getFieldState,
              formData,
              "banner"
            )}
            size={600} maxWidth={200}
            onImage={onImage}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>General Information</h3>
          <p>This is the name of the tournament.</p>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            value={formData?.name}
            name="name"
            label="Gym Name"
            isRequired
            register={register} setValue={setValue}
            onChange={checkCRC}
          />
          <SelectFormInput
            value={formData?.status || ''}
            name="status"
            label="Gym Status"
            isRequired
            setValue={setValue}
            placeholder="Select status"
            options={STATUS}
            onChange={checkCRC}
          />
          <TextFormInput
            value={formData?.email}
            type="email"
            name="email"
            label="Email"
            register={register} setValue={setValue}
            isRequired validateWithTrim
            onChange={checkCRC}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>Address</h3>
        </div>
        <AddressForm
          address={{
            country: formData.country,
            state: formData.state,
            address: formData.address,
            city: formData.city,
            zip: formData.zip,
            suburb: formData.suburb,
            region: formData.region,
          }}
          isRequired={['city']}
          register={register} setValue={setValue}
          onChange={checkCRC}
        />
        <div className={FormStyles.fieldDescription}>
          <h3>Executives</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            control={control}
            value={formData.executives || []}
            name="executives"
            label="Executives"
            optionLabel="display"
            optionValue="id"
            fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
            setValue={setValue}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Team</h3>
        </div>
        <div className={`MultiSelectFormInput-wrapper ${FormStyles.fields}`}>
          <MultiSelectFormInput
            control={control}
            value={formData.teams || []}
            multiple={true}
            name="teams"
            label="Teams"
            fetchOptions={searchVal => {
              return TeamModel.searchTeam(searchVal);
            }}
            setValue={setValue}
            onChange={v => {
              checkCRC();
            }}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Head Coaches</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            control={control}
            value={getValues("head_coaches") || formData.head_coaches}
            name="head_coaches"
            label="Head Coaches"
            optionLabel="display"
            optionValue="id"
            fetchOptions={searchVal => UserModel.searchUser(searchVal)}
            setValue={setValue}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Admins</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            isRequired
            control={control}
            value={getValues("admins") || formData.admins}
            name="admins"
            label="Admins"
            optionLabel="display"
            optionValue="id"
            fetchOptions={searchVal => UserModel.searchUser(searchVal)}
            setValue={setValue}
            onChange={checkCRC}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>
            Contacts
            <AddIcon
              id="add_contacts"
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "contact_infos";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
                checkCRC()
              }}
            />
          </h3>
          <p>Set contact information for this league</p>
        </div>
        <div className={FormStyles.fields}>
          <ContactInfos
            data={getValues("contact_infos") || formData.contact_infos}
            register={register} setValue={setValue}
            errors={errors} setError={setError}
            refresh={() => setRefresh(!refresh)}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Social Medias
            <AddIcon
              id="add_social_medias"
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "social_medias";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
                checkCRC();
              }}
            />
          </h3>
          <p>Set social medias of this tournament</p>
        </div>
        <div className={FormStyles.fields}>
          <SocialMedias
            data={getValues("social_medias") || formData.social_medias}
            register={register} setValue={setValue}
            errors={errors.social_medias}
            refresh={() => setRefresh(!refresh)}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Membership Type
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                handleSubListAdd(setFormData, formData, "membership_type");
                checkCRC();
              }}
            />
          </h3>
        </div>
        <div className={FormStyles.fields}>
          <MembershipTypes
            errors={errors?.membership_type}
            setFormData={setFormData}
            membership_type={formData.membership_type}
            register={register}
            setValue={setValue}
            refresh={() => setRefresh(!refresh)}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Registration Fee</h3>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            value={gym?.registration_fee}
            name="registration_fee"
            label="Registration Fee"
            register={register} setValue={setValue}
            type="float"
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Currency</h3>
        </div>
        <div className={FormStyles.fields}>
          <SelectFormInput width={200}
            name="currency"
            label="Currency"
            isRequired
            value={gym?.currency || ''}
            setValue={setValue}
            placeholder="Currency"
            options={CURRENCIES}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Payment Information
            <AddIcon
              id="add_payment_info"
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "payment_info";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
                checkCRC();
              }}
            />
          </h3>
          <p>Enter your payment gateway information</p>
        </div>
        <div className={FormStyles.fields}>
          <PaymentInfos
            data={getValues("payment_info") || formData.payment_info}
            register={register} setValue={setValue}
            refresh={() => setRefresh(!refresh)}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}></div>
        <div className={FormStyles.fields}>
          <CheckboxFormInput
            label="Auto Membership Approve"
            name="is_auto_approve"
            value={formData.is_auto_approve}
            register={register}
            setValue={setValue}
            onChange={v => {
              formData.is_auto_approve = v;
              checkCRC();
            }}
          />
        </div>
        <div className={FormStyles.fieldDescription}/>
        <FormActionButtons doDelete={doDelete} message={message} id={formData.id} setMessage={setMessage}/>
      </form>
      {hasChange && <AlertPane isFloat message={DATA_HAS_CHANGED_MESSAGE_WARNING}/>}
    </div>;
}
