import "./Help.scss";
import { useEffect, useRef, useState } from "react";
import { useStore } from '../../Store';
import { default as CloseIcon} from '@mui/icons-material/CloseOutlined';
import { default as TableOfContentsIcon} from '@mui/icons-material/HouseOutlined';
import HelpModel from "../../serverUtils/models/HelpModel";
import { getPathnameWithQuery } from "../../serverUtils/requests";
import { Link } from "@mui/material";


const Help = ({}) => {
    const isHelp = useStore(state => state.isHelp);
    const setIsHelp = useStore(state => state.setIsHelp);
    const [help, setHelp] = useState();
    const divRef = useRef();
    const isMouseDown = useRef(false);
    const helpDataRef = useRef();

    useEffect(() => {
        if (isHelp) {
            let key = getPathnameWithQuery();
            HelpModel.getHelps(key).then(helpData => createHelpHTML(helpData, key));
        }
    }, [document.location.href, isHelp]);

    const createHelpHTML = (helpData, url) => {
        if (!helpData) {
            HelpModel.getHelps().then(helpData => {
                helpDataRef.current = helpData;
                createTableOfContents();
            });
        }else {
            const {helps, files_doc} = helpData;
            let h = helps.find(i => i.url === url);
            if (h && url === h.url) {
                let fileHtml = files_doc.find(f => f.id === h.file).data.join('');
                h.images.forEach(img => {
                    let imgFile = files_doc.find(f => f.id === img);
                    fileHtml = fileHtml.replace(imgFile.name, imgFile.data.join(''));
                })
                h.getHTML = () => fileHtml;
                h.helps = helps;
                return setHelp(h);    
            }
        }
    }

    const createTableOfContents = () => {
        if (!helpDataRef.current) {
            return HelpModel.getHelps().then(helpData => {
                helpDataRef.current = helpData;
                createTableOfContents();
            });;
        }
        let {helps} = helpDataRef.current;
        setHelp({
            type: 'all',
            title: 'Table of Content',
            getHTML: () => <ul>
                {helps.map((i, j) => <li key={j}>
                    <Link className="Link"
                        onClick={() => {
                            HelpModel.getHelps(i.url).then(_help => {
                                if (!_help) {
                                    return;
                                }
                                const {helps} = _help;
                                if (helps.length > 0) {
                                    createHelpHTML(_help, i.url);
                                }
                            });
                    }}>{i.title}</Link>
                </li>)}
            </ul>
        });
    }

    const mouseDown = e => {
        isMouseDown.current = true;
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    const handleMouseUp = e => {
        isMouseDown.current = false;
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    }

    const handleMouseMove = e => {
        if (isMouseDown) {
            const {x, width} = divRef.current.getBoundingClientRect();
            let w = Math.floor((x - e.clientX) + width);
            console.log(w);
            divRef.current.style.width = `${w}px`;
        }
    }

    return !isHelp || !help? '': 
        <div className="Help main" ref={divRef}>
            <div className="buttons">
                <div className="home" onClick={() => createTableOfContents()}><TableOfContentsIcon />Home</div>  
                <div className="close" onClick={() => setIsHelp()}><CloseIcon /> Close</div>
            </div>
            <div className="resize-divider" onMouseDown={mouseDown}/>
            <div className="body">
                <h5>{help.title}</h5>  
                {help.type === 'all'? help.getHTML()
                    :  
                    <div className="help-content" dangerouslySetInnerHTML={{ __html: help.getHTML() }} />}
            </div>
        </div>;
}

export default Help;