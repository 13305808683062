import React from "react";
import moment from "moment-timezone";
import timezones from './timezones.json';
const CryptoJS = require('crypto-js');
export const TIME_ZONES = timezones.map(tz => ({value: tz.value}));

const js_importer = {
    url: (url) => {
        return new Promise((resolve, reject) => {
            let script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = url;
            script.addEventListener('load', () => resolve(script), false);
            script.addEventListener('error', () => reject(script), false);
            document.body.appendChild(script);
        });
    },
    urls: (urls) => {
        return Promise.all(urls.map(js_importer.url));
    }
};

export default class Utils {
    static readLocalFile = async (file, format = "dataURL") => {
        const reader = new FileReader();
    
        const fileContent = await new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result);
            reader.onerror = () => reject(new Error("File reading failed"));
            
            // Choose the reading method based on format
            if (format === "dataURL") {
                reader.readAsDataURL(file);
            } else if (format === "text") {
                reader.readAsText(file);
            } else {
                reject(new Error("Unsupported file format"));
            }
        });
    
        return fileContent;
    };
    
    static listObjectKeys(obj, keys = new Set()) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                keys.add(key);
                if (typeof obj[key] === 'object' && obj[key] !== null) {
                    Utils.listObjectKeys(obj[key], keys);
                }
            }
        }
        return Array.from(keys);
    }
    
    static getArrayRepeatedElements(arr, returnNonRepeat) {
        const elementCount = {};
        const repeatedElements = [];
    
        // Count occurrences of each element
        for (let i = 0; i < arr.length; i++) {
            const element = arr[i];
            if (elementCount[element]) {
                elementCount[element]++;
            } else {
                elementCount[element] = 1;
            }
        }
    
        // Filter out elements that appear more than once
        for (const element in elementCount) {
            if (elementCount[element] > 1) {
                repeatedElements.push(parseInt(element));
            }
        }
    
        if (returnNonRepeat) {
            return Utils.arrayDifference(arr, repeatedElements);
        }
        return repeatedElements;
    }

    static arraysEquals(arr1=[], arr2=[]) {
        arr1 = arr1 || [];
        arr2 = arr2 || [];
        if (arr1.length !== arr2.length) {
          return false;
        }
      
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }
      
        return true;
    }

    static hasRangeIntersection(start1, end1, start2, end2) {
        // Ensure start is less than or equal to end for both ranges
        if (start1 > end1) [start1, end1] = [end1, start1];
        if (start2 > end2) [start2, end2] = [end2, start2];
        
        // Check if ranges intersect
        let r = !(end1 < start2 || end2 < start1);
        return r;
    }

    static extractTextFromElement(element) {
        if (typeof element === 'string' || typeof element === 'number') {
          return element.toString();
        }
      
        if (React.isValidElement(element)) {
          const { children } = element.props;
          return React.Children.map(children, Utils.extractTextFromElement).join('');
        }
      
        if (Array.isArray(element)) {
          return element.map(Utils.extractTextFromElement).join('');
        }
      
        return '';
    }

    static htmlToText(html) {
        // Create a temporary DOM element
        const tempDiv = document.createElement('div');
        // Set the HTML content
        tempDiv.innerHTML = html;
        // Retrieve and return the text content
        return tempDiv.textContent || tempDiv.innerText || '';
    }

    static isWordsInString = (txt, word) => {
        txt = txt.toString().toLowerCase();
        if (word.startsWith('"')){
          word = word.replace(/['"]/g, '').trim().split(' ').map(w => w.trim()).filter(w => w);
          if (Utils.intersection(txt.split(" ").map(t => t.trim()).filter(t => t), word).length === word.length) {
            return true;
          }
        }else if (txt.includes(word.toLowerCase())) {
          return true;
        }
        return false;
    }

    static separateWords(sentence) {
        if (typeof sentence !== 'string') {
            throw new Error('Input must be a string');
        }

        // Regular expression to match words or quoted phrases
        const regex = /"([^"]+)"|(\S+)/g;
        const words = [];
        let match;

        // Find all matches based on the regex
        while ((match = regex.exec(sentence)) !== null) {
            if (match[1]) {
                // Include quotes and push the quoted phrase
                words.push(`"${match[1]}"`);
            } else {
                // Push the regular word
                words.push(match[2]);
            }
        }

        return words;
    }

    static stringToChunks(text, chunkSize=50000) {
        const chunks = [];
        for (let i = 0; i < text.length; i += chunkSize) {
          chunks.push(text.slice(i, i + chunkSize));
        }
        return chunks;
    }
    
    static isMobile = () => {
        let isMobile = navigator.userAgent.match(
            /(Mobile)|(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
        );
        return isMobile ? 'mobile' : '';
    };

    static getBrowserTimeZone() {
        let tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        tz = timezones.find(t => t.utc.includes(tz));
        return tz && tz.value;
    }
    static getBrowerCountry() {
        const userLanguage = navigator.language;
        return userLanguage.split('-')[1];
    }
    static toPercentage(floatNumber, decimals) {
        return (floatNumber * 100).toFixed(0 || decimals) + '%';
    }
    static bytesToSize(bytes) {
        const sizes = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb'];
        if (bytes === 0) return '0 Bytes';
        const i = Math.floor(Math.log(bytes) / Math.log(1024));
        const size = (bytes / Math.pow(1024, i)).toFixed(2);
        return `${size} ${sizes[i]}`;
    }

    static removeObjectUnsetFields(obj) {
        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                if (obj[key] === undefined || obj[key] === null || Number.isNaN(obj[key])) {
                    delete obj[key];
                }
            }
        }
        return obj;
    }

    static camelize(str) {
        str = str || '';
        return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
            if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
            return index === 0 ? match.toLowerCase() : match.toUpperCase();
        });
    }

    static containsWords = (s, ss) => {
        s = s.toLowerCase().trim();
        ss = ss.toLowerCase().trim();
        let ssArr = ss.split(' ');
        return s.includes(ss) || Utils.intersection(s.split(' '), ssArr).length >= ssArr.length;
    }

    static getBrowserTimeZone = () => {
        return new Date().toTimeString().split('(')[1].split(')')[0];
    }

    static formatSeconds(seconds) {
        if (!isNaN(seconds)) {
            seconds = parseInt(seconds);
            let minutes = ~~(seconds / 60);
            let extraSeconds = seconds % 60;
            return `${Utils.padZeros(minutes, 2)}:${Utils.padZeros(extraSeconds, 2)}`;
        }
        return '';
    }

    static stringToUtf8ByteArray(str) {
        var value = [];
        var destIndex = 0;
        for (var index = 0; index < str.length; index++) {
            var code = str.charCodeAt(index);
            if (code <= 0x7F) {
                value[destIndex++] = code;
            } else if (code <= 0x7FF) {
                value[destIndex++] = ((code >> 6 ) & 0x1F) | 0xC0;
                value[destIndex++] = ((code >> 0 ) & 0x3F) | 0x80;
            } else if (code <= 0xFFFF) {
                value[destIndex++] = ((code >> 12) & 0x0F) | 0xE0;
                value[destIndex++] = ((code >> 6 ) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 0 ) & 0x3F) | 0x80;
            } else if (code <= 0x1FFFFF) {
                value[destIndex++] = ((code >> 18) & 0x07) | 0xF0;
                value[destIndex++] = ((code >> 12) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 6 ) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 0 ) & 0x3F) | 0x80;
            } else if (code <= 0x03FFFFFF) {
                value[destIndex++] = ((code >> 24) & 0x03) | 0xF0;
                value[destIndex++] = ((code >> 18) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 12) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 6 ) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 0 ) & 0x3F) | 0x80;
            } else if (code <= 0x7FFFFFFF) {
                value[destIndex++] = ((code >> 30) & 0x01) | 0xFC;
                value[destIndex++] = ((code >> 24) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 18) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 12) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 6 ) & 0x3F) | 0x80;
                value[destIndex++] = ((code >> 0 ) & 0x3F) | 0x80;
            } else {
                throw new Error("Unsupported Unicode character \"" 
                    + str.charAt(index) + "\" with code " + code + ") at index " + index
                    + ". Cannot represent it as UTF-8 byte sequence.");
            }
        }
        return value;
    }

    static toLocalTimeZone(timeInMilliseconds) {
        const date = new Date(timeInMilliseconds);
        // Get the local timezone offset in minutes
        const timezoneOffsetInMinutes = date.getTimezoneOffset();
        // Add the offset to the original time to convert it to the local timezone
        const localTimeInMilliseconds = timeInMilliseconds - (timezoneOffsetInMinutes * 60 * 1000);
        // Create a new Date object with the local time
        return new Date(localTimeInMilliseconds);
    }

    static toTimeZoneDisplay(time, zone) {
        let tz = timezones.find(tz => tz.value===zone);
        zone = tz? tz.utc[0] : zone;
        return new Date(time).toLocaleString('en-US', { timeZone: zone });
    }

    static getTimeZoneTime(time, zone){
        return new Date(Utils.toTimeZoneDisplay(time, zone)).getTime();
    }

    static getTimeZone(value) {
        return timezones.find(t => {
            return t.text===value || t.value===value || t.utc.includes(value);
        });
    }

    static addCRC(listOfObj){
        if (listOfObj && Array.isArray(listOfObj)){
            for (let obj of listOfObj){
                obj.crc = Utils.getCRC(obj);
            }
        }
        return listOfObj;
    }

    static getCRC(obj, keys, isInit) {
        if (!obj || typeof obj !== 'object'){
            return obj;
        }
        let s = Utils.serialize(obj, keys);
        let r = Utils.md5((s||'').replace(/\s+/g, '')) + '-' + s.length;
        // console.log(`\nSerialized ${isInit? true:''}:\n`, s, r);
        return r
    }

    static md5(inputString){
        return CryptoJS.MD5(inputString).toString();
    }

    static md5Save(inputString) {
        let hc="0123456789abcdef";
        function rh(n) {let j,s="";for(j=0;j<=3;j++) s+=hc.charAt((n>>(j*8+4))&0x0F)+hc.charAt((n>>(j*8))&0x0F);return s;}
        function ad(x,y) {let l=(x&0xFFFF)+(y&0xFFFF);let m=(x>>16)+(y>>16)+(l>>16);return (m<<16)|(l&0xFFFF);}
        function rl(n,c)            {return (n<<c)|(n>>>(32-c));}
        function cm(q,a,b,x,s,t)    {return ad(rl(ad(ad(a,q),ad(x,t)),s),b);}
        function ff(a,b,c,d,x,s,t)  {return cm((b&c)|((~b)&d),a,b,x,s,t);}
        function gg(a,b,c,d,x,s,t)  {return cm((b&d)|(c&(~d)),a,b,x,s,t);}
        function hh(a,b,c,d,x,s,t)  {return cm(b^c^d,a,b,x,s,t);}
        function ii(a,b,c,d,x,s,t)  {return cm(c^(b|(~d)),a,b,x,s,t);}
        function sb(x) {
            let i;let nblk=((x.length+8)>>6)+1;let blks=new Array(nblk*16);for(i=0;i<nblk*16;i++) blks[i]=0;
            for(i=0;i<x.length;i++) blks[i>>2]|=x.charCodeAt(i)<<((i%4)*8);
            blks[i>>2]|=0x80<<((i%4)*8);blks[nblk*16-2]=x.length*8;return blks;
        }
        let i,x=sb(inputString),a=1732584193,b=-271733879,c=-1732584194,d=271733878,olda,oldb,oldc,oldd;
        for(i=0;i<x.length;i+=16) {olda=a;oldb=b;oldc=c;oldd=d;
            a=ff(a,b,c,d,x[i+ 0], 7, -680876936);d=ff(d,a,b,c,x[i+ 1],12, -389564586);c=ff(c,d,a,b,x[i+ 2],17,  606105819);
            b=ff(b,c,d,a,x[i+ 3],22,-1044525330);a=ff(a,b,c,d,x[i+ 4], 7, -176418897);d=ff(d,a,b,c,x[i+ 5],12, 1200080426);
            c=ff(c,d,a,b,x[i+ 6],17,-1473231341);b=ff(b,c,d,a,x[i+ 7],22,  -45705983);a=ff(a,b,c,d,x[i+ 8], 7, 1770035416);
            d=ff(d,a,b,c,x[i+ 9],12,-1958414417);c=ff(c,d,a,b,x[i+10],17,     -42063);b=ff(b,c,d,a,x[i+11],22,-1990404162);
            a=ff(a,b,c,d,x[i+12], 7, 1804603682);d=ff(d,a,b,c,x[i+13],12,  -40341101);c=ff(c,d,a,b,x[i+14],17,-1502002290);
            b=ff(b,c,d,a,x[i+15],22, 1236535329);a=gg(a,b,c,d,x[i+ 1], 5, -165796510);d=gg(d,a,b,c,x[i+ 6], 9,-1069501632);
            c=gg(c,d,a,b,x[i+11],14,  643717713);b=gg(b,c,d,a,x[i+ 0],20, -373897302);a=gg(a,b,c,d,x[i+ 5], 5, -701558691);
            d=gg(d,a,b,c,x[i+10], 9,   38016083);c=gg(c,d,a,b,x[i+15],14, -660478335);b=gg(b,c,d,a,x[i+ 4],20, -405537848);
            a=gg(a,b,c,d,x[i+ 9], 5,  568446438);d=gg(d,a,b,c,x[i+14], 9,-1019803690);c=gg(c,d,a,b,x[i+ 3],14, -187363961);
            b=gg(b,c,d,a,x[i+ 8],20, 1163531501);a=gg(a,b,c,d,x[i+13], 5,-1444681467);d=gg(d,a,b,c,x[i+ 2], 9,  -51403784);
            c=gg(c,d,a,b,x[i+ 7],14, 1735328473);b=gg(b,c,d,a,x[i+12],20,-1926607734);a=hh(a,b,c,d,x[i+ 5], 4,    -378558);
            d=hh(d,a,b,c,x[i+ 8],11,-2022574463);c=hh(c,d,a,b,x[i+11],16, 1839030562);b=hh(b,c,d,a,x[i+14],23,  -35309556);
            a=hh(a,b,c,d,x[i+ 1], 4,-1530992060);d=hh(d,a,b,c,x[i+ 4],11, 1272893353);c=hh(c,d,a,b,x[i+ 7],16, -155497632);
            b=hh(b,c,d,a,x[i+10],23,-1094730640);a=hh(a,b,c,d,x[i+13], 4,  681279174);d=hh(d,a,b,c,x[i+ 0],11, -358537222);
            c=hh(c,d,a,b,x[i+ 3],16, -722521979);b=hh(b,c,d,a,x[i+ 6],23,   76029189);a=hh(a,b,c,d,x[i+ 9], 4, -640364487);
            d=hh(d,a,b,c,x[i+12],11, -421815835);c=hh(c,d,a,b,x[i+15],16,  530742520);b=hh(b,c,d,a,x[i+ 2],23, -995338651);
            a=ii(a,b,c,d,x[i+ 0], 6, -198630844);d=ii(d,a,b,c,x[i+ 7],10, 1126891415);c=ii(c,d,a,b,x[i+14],15,-1416354905);
            b=ii(b,c,d,a,x[i+ 5],21,  -57434055);a=ii(a,b,c,d,x[i+12], 6, 1700485571);d=ii(d,a,b,c,x[i+ 3],10,-1894986606);
            c=ii(c,d,a,b,x[i+10],15,   -1051523);b=ii(b,c,d,a,x[i+ 1],21,-2054922799);a=ii(a,b,c,d,x[i+ 8], 6, 1873313359);
            d=ii(d,a,b,c,x[i+15],10,  -30611744);c=ii(c,d,a,b,x[i+ 6],15,-1560198380);b=ii(b,c,d,a,x[i+13],21, 1309151649);
            a=ii(a,b,c,d,x[i+ 4], 6, -145523070);d=ii(d,a,b,c,x[i+11],10,-1120210379);c=ii(c,d,a,b,x[i+ 2],15,  718787259);
            b=ii(b,c,d,a,x[i+ 9],21, -343485551);a=ad(a,olda);b=ad(b,oldb);c=ad(c,oldc);d=ad(d,oldd);
        }
        return rh(a)+rh(b)+rh(c)+rh(d);
    }

    static removeEmptyKeys(obj) {
        for (let key in obj) {
            if (obj[key] === undefined || obj[key] === null || obj[key] === '' || obj[key] === false) {
            delete obj[key];
            }
        }
        return obj;
    }

    static serialize(obj, keys = []) {
        const escapeString = (str) => {
            return str.replace(/[|:\s]/g, '').trim();
        }

        if (typeof obj === 'function') {
            return '';
        }

        if (Array.isArray(obj)) {
            return obj.map(i => Utils.serialize(i, keys));
        }else if (obj instanceof File) {
            obj = Utils.serialize({
                name: obj.name,
                size: obj.size,
                type: obj.type,
                lastModified: obj.lastModified,
            }, keys);
        } else if (typeof obj === 'object' && obj !== null) {
            let keyList = Object.keys(obj).sort();
            return keyList
                .map(k => {
                    let isFalse = obj[k] === false;
                    let value;
                    if (isFalse) {
                        value = '';
                    }else if (k.includes('_date')) {
                        value = Utils.formatDate(obj[k], 'YYYYMMDDhhmm');
                    }else {
                        value = Utils.serialize(obj[k], keys);
                    }
                    return (keys.includes(k) && value) ? `${escapeString(k)}:${value}` : null;
                })
                .filter(k => k)
                .join('|');
        }

        return (obj !== null && obj !== undefined && obj !== '') ? escapeString(obj.toString()) : '';
    }


    static findSubArrayIndex(haystack, needle, start=0) {
        for (let i = start; i <= haystack.length - needle.length; ++i) {
            let done = -1;
            for (let j = 0; j < needle.length; ++j) {
                if (haystack[i + j] === 0){
                    i++;
                }
                if (haystack[i + j] !== needle[j]) {
                    done = -1;
                    break;
                }else if (done === -1){
                    done = i;
                }
            }

            if (done > -1) {
                return done;
            }
        }


        return -1;
    }

    static findParent(selector, target){
        if (!target.parentElement){
            return;
        }
        const {parentElement} = target;
        if (target === parentElement.querySelector(selector)){
            return target;
        }
        return Utils.findParent(selector, parentElement);
    }

    static sumArray(arr){
        return arr.reduce((partial_sum, a) => partial_sum + a,0);
    }

    static isVisible(element){
        return element.offsetWidth > 0 && element.offsetHeight > 0;
    }
    
    static reversefromNow(input) {
        if (!input){
            return;
        }
        let relativeLocale = JSON.parse(JSON.stringify(moment.localeData()._relativeTime));
        let pastfutureObject = {
            future: relativeLocale.future,
            past: relativeLocale.past
        };
        delete relativeLocale.future;
        delete relativeLocale.past;

        //detect if past or future
        let pastfuture;
        for (const [key, value] of Object.entries(pastfutureObject)) {
            if (input.indexOf(value.replace("%s", "")) !== -1) {
                pastfuture = key;
            }
        }

        //detect the time unit
        let unitkey;
        for (const [key, value] of Object.entries(relativeLocale)) {
            if (input.indexOf(value.replace("%d", "")) !== -1) {
                unitkey = key.charAt(0);
            }
        }

        //if its not in the data, then assume that it is a week
        if (unitkey == null) {
            unitkey = "w";
        }

        const units = {
            M: "month",
            d: "day",
            h: "hour",
            m: "minute",
            s: "second",
            w: "week",
            y: "year"
        };
        //Detect number value 
        const regex = /(\d+)/g;
        let numbervalue = input.match(regex) || [1];
        //Add if future, subtract if past
        if (pastfuture === "past") {
            return moment().subtract(numbervalue, units[unitkey]).valueOf();
        } else {
            return moment().add(numbervalue, units[unitkey]).valueOf();
        }
    }

    static isObjectEmpty(obj){
        return !Object.values(obj).some(x => x !== null && x !== '');
    }

    static isObjectEqual(obj1, obj2){
        if (obj1 && obj2){
            for (let k of Object.keys(obj1)){
                let v1 = obj1[k];
                if (v1?.toString() === 'NaN'){
                    v1 = null;
                }
                let v2 = obj2[k];
                if (v2?.toString() === 'NaN'){
                    v2 = null;
                }
                if (['function'].includes(typeof v1)){
                    continue;
                }
                if (typeof v1 === 'object'){
                    try{
                        if (JSON.stringify(v1) !== JSON.stringify(v2)){
                            return false;
                        }
                    }catch(e){}
                    continue;
                }
                if (v1 !== v2){
                    return false;
                }
            }
        }
        return true;
    }

    static sorterDate(a, b, field){
        a = moment(parseInt(a[field]));
        b = moment(parseInt(b[field]));
        if (a > b){
            return 1;
        }
        if (a < b){
            return -1;
        }
        return 0;
    }
    
    static sort(a, b){
        if (!isNaN(a) || !isNaN(b)){
            a = a || 0;
            b = b || 0;
        }
        if (typeof a === 'string' || typeof b === 'string'){
            a = a || '';
            b = b || '';
            a = a.toString().toLowerCase();
            b = b.toString().toLowerCase();
            if (a > b){
                return 1;
            }
            if (a < b){
                return -1;
            }
            return 0;
        }
        return a - b;
    }
    
    static sorter(a, b, field){
        a = a[field];
        b = b[field];
        return Utils.sort(a, b);
    }

    static multiFieldSort(arr, fields) {
        // const sortedPeople = multiFieldSort(people, [
        //     { key: 'lastName', order: 'asc' },
        //     { key: 'age', order: 'desc' }
        // ]);
        return arr.sort((a, b) => {
          for (const field of fields) {
            const { key, order = 'asc' } = field;
            const comparison = typeof a[key] === 'string' 
              ? a[key].localeCompare(b[key]) 
              : a[key] - b[key];
            
            // Check for order, ascending or descending
            const result = order === 'asc' ? comparison : -comparison;
      
            // If the current field results in a difference, return it
            if (result !== 0) return result;
          }
          return 0; // All fields are equal
        });
      }

    static sortMulti(fields, dir, getValue) {
        return function (a, b) {
            return fields
                .map(function (f) {
                    dir = dir || 1;
                    if (f[0] === '-') {
                        dir = -1;
                        f=f.substring(1);
                    }
                    let _a = a[f];
                    _a = (getValue && getValue(a, f)) || _a;
                    let _b = b[f];
                    _b = (getValue && getValue(b, f)) || _b;
                    if (typeof _a === 'string' && typeof _b === 'string') {
                        _a = _a.toLowerCase();
                        _b = _b.toLowerCase();
                    }
                    if (_a > _b) return dir;
                    if (_a < _b) return -(dir);
                    return 0;
                })
                .reduce(function firstNonZeroValue (p,n) {
                    return p ? p : n;
                }, 0);
        };
    }

    static arrayDifference(arr1, arr2) {
        return arr1.filter(item => !arr2.includes(item));
    }

    static intersection(arrA, arrB){
        return arrA.filter(x => arrB.includes(x)).filter(i => i);
    }
    static uniqArray(arr){
        return arr.filter((item, index) => arr.indexOf(item)===index);
    }
    static uniqArrayByKey(arrayOfObjects, key) {
        const uniqueFieldSet = new Set();
        return arrayOfObjects.filter((obj) => {
            const isUnique = !uniqueFieldSet.has(obj[key]);
            uniqueFieldSet.add(obj[key]);
            return isUnique;
        });
    }
    static uniqueArrayByKeys(arr, indexedKeys, isPrioritizeFormer = true) {
        const lookup = new Map();
        const makeIndex = el => indexedKeys.reduce(
            (index, key) => `${index};;${el[key]}`, ''
        );
        arr.forEach(el => {
            const index = makeIndex(el);
            if (lookup.has(index) && isPrioritizeFormer) {
                return;
            }
            lookup.set(index, el);
        });
    
        return Array.from(lookup.values());
    };

    static dateDiff(unit, from, to){
        return moment(to).diff(moment(from), unit);
    }

    static loadExternalJS(urls){
        if (!urls){
            return Promise.resolve();
        }
        return js_importer[Array.isArray(urls)? 'urls':'url'](urls);
    }

    static resetArray(_array){
        _array.splice(0, _array.length);
        return _array;
    }

    static updateArray(_array, newValues, isClear, isRemove){
        if (isRemove){
            _array.splice(_array.indexOf(newValues), 1);
            return _array;
        }
        isClear && Utils.resetArray(_array);
        for (let v of newValues){
            _array.push(v);
        }
        return _array;
    }

    static getAge(dob, defaultValue){
        if (isNaN(dob))
            return defaultValue;
        dob = moment(parseInt(dob));
        return moment().diff(dob, 'years');
    }

    static isFullScreen() {
        if (document.isFullscreen === undefined) {
            let fs = function () {
                if (document.fullscreenElement !== undefined)
                    return document.fullscreenElement;
                if (document.webkitFullscreenElement !== undefined)
                    return document.webkitFullscreenElement;
                if (document.mozFullScreenElement !== undefined)
                    return document.mozFullScreenElement;
                if (document.msFullscreenElement !== undefined)
                    return document.msFullscreenElement;
            };
            if (fs() === undefined) document.isFullscreen = undefined;
            else document.isFullscreen = fs;
        }
        return document.isFullscreen();
    }

    static toggleFullscreen(elem, cb) {
        if (!elem) {
            return Promise.reject();
        }
        elem.classList.add('fullscreen');   
        let fullScreenEvent = event=> {
            // document.fullscreenElement will point to the element that
            // is in fullscreen mode if there is one. If not, the value
            // of the property is null.
            if (document.fullscreenElement) {
                console.log(`Element: ${document.fullscreenElement.id} entered fullscreen mode.`);
                cb && cb(true);
            } else {
                console.log('Leaving full-screen mode.');
                elem.classList.remove('fullscreen');
                cb && cb();
            }
        };
        elem.removeEventListener('fullscreenchange', fullScreenEvent);
        elem.addEventListener('fullscreenchange', fullScreenEvent); 
        if (!document.fullscreenElement) {
            elem.classList.add('fullscreen');
            return elem.requestFullscreen().catch(err => {
                console.log(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
                return err;
            });
        } else {
            return document.exitFullscreen();
        }
    }

    static clearObject(obj){
        for (const prop of Object.getOwnPropertyNames(obj)) {
            try {
                delete obj[prop];
            }catch(e) {
                console.log(e);
            }
        }
        return obj;
    }

    static groupCount(arr) {
        const counts = new Map();

        arr.forEach((item) => {
            if (!counts.has(item)) {
                counts.set(item, 1);
            } else {
                counts.set(item, counts.get(item) + 1);
            }
        });
        return Object.fromEntries(counts.entries());
    }

    static groupBy(list, keys) {
        if (!list?.filter){
            return list;
        }
        const groupBy = (f) => {
            let groups = {};
            list.forEach(function (o) {
                let group = f(o);
                let key = Object.keys(groups).find(k => {
                    let r = Utils.intersection(group, k.split(',')).length == keys.length;
                    return r;
                });
                key = (key && key.split(',')) || group.join(',');
                groups[key] = groups[key] || [];
                groups[key].push(o);
            });
            return groups;
        }

        let g = groupBy(function (item) {
            return keys.map(k => {
                return item[k];
            });
        });
        return g;
    }

    static concatArray(arr, arr1) {
        arr1.forEach(a => arr.push(a));
        return arr;
    }

    static removeArrayItem(arr, item){
        let keys = Object.keys(item);
        for (let i=0; i<arr.length; i++){
            for (let k of keys) {
                if (arr[i][k] === item[k]) {
                    arr.splice(i, 1);
                    return true;
                }
            }
        }
    }
    static padZeros(num, places){
        if (isNaN(num)) {
            return '';
        }
        
        num = parseInt(num || 0);
        let neg = num < 0? '-':'';
        num = Math.abs(num);
        let zero = places - num.toString().length + 1;
        return neg + Array(+(zero > 0 && zero)).join("0") + num;
    }
    static resetObject(obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === Object(obj[key])) {
                return Utils.resetObject(obj[key]);
            }
            if (obj[key] instanceof Array) obj[key] = [];
            else if (typeof obj[key] === 'boolean') obj[key] = false;
            else obj[key] = '';
        });
        return obj;
    }
    static isSubArray(main, sub) {
        return sub.every((eachEle) => {
            return (main.indexOf(eachEle) + 1);
        });
    }

    static copy(obj, isReset, removes){
        if (!obj){
            return obj;
        }
        let getCircularReplacer = () => {
            const seen = new WeakSet();
            return (key, value) => {
                if (typeof value === 'object' && value !== null) {
                    if (seen.has(value)) {
                        return;
                    }
                    seen.add(value);
                }
                return value;
            };
        };
        let _json;
        try{
            _json = JSON.stringify(obj);
        }catch(e){
            console.log(e);
            _json = JSON.stringify(obj, getCircularReplacer());
        }
         _json = JSON.parse(_json);
        if (removes){
            removes.forEach(r => delete _json[r]);
        }
        return isReset? Utils.resetObject(_json) : _json;
    }

    static deepClone = _src => {
        const clones = new WeakMap();
        return (function baseClone(src) {
            if (src !== Object(src)) {
                return src;
            }
            if (clones.has(src)) {
                return clones.get(src);
            }
            const clone = {}
            clones.set(src, clone);
            Object.entries(src).forEach(([k, v]) => (clone[k] = baseClone(v)));
            return clone;
        })(_src);
    }

    static noCirculars = v => {
        const set = new Set();
        const noCirculars = v => {
            if (Array.isArray(noCirculars))
                return v.map(noCirculars);
            if (typeof v === "object" && v !== null) {
                if (set.has(v)) return undefined;
                set.add(v);

                return Object.fromEntries(Object.entries(v)
                    .map(([k, v]) => ([k, noCirculars(v)])));
            }
            return v;
        };
        return noCirculars(v);
    }

    static deepCopy = (inObject) => {
        let outObject, value, key

        if (typeof inObject !== "object" || inObject === null) {
            return inObject // Return the value if inObject is not an object
        }

        // Create an array or object to hold the values
        outObject = Array.isArray(inObject) ? [] : {}

        for (key in inObject) {
            value = inObject[key]

            // Recursively (deep) copy for nested objects, including arrays
            outObject[key] = Utils.deepCopy(value)
        }

        return outObject
    }

    static pick(o, ...props) {
        return Object.assign({}, ...props.map(prop => {
            if (o[prop]) return {[prop]: o[prop]};
            return;
        }));
    }

    static resizeImageData(data, size, el){
        return new Promise(async (resolve, reject) => {
            let img = el || new Image();
            img.src = data;
            const resize = () => {
                if (img.width <= size){
                    return resolve(data);
                }
                let h = Math.floor((size/img.width) * img.height);
                resolve(Utils.scaleImage(img, {width: size, height: h}));
            }
            img.addEventListener('load', () => {
                resize();
            }, false);
        });
    }

    static scaleImage(img, size){
        size = size || {width: img.width, height: img.height};
        let canvas = document.createElement("CANVAS");
        canvas.setAttribute("width", size.width);
        canvas.setAttribute("height", size.height);
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, size.width, size.height);
        return canvas.toDataURL('image/jpeg', 1.0);
    }

    static capitalize = (s) => {
        if (typeof s !== 'string') return '';
        return s.charAt(0).toUpperCase() + s.slice(1);
    }

    static displayLocalImg(file, img, elData, callback){
        if (file) {
            let reader = new FileReader();
            reader.onload = function (e) {
                function loadImage() {
                    if (elData) {
                        let imgData = Utils.scaleImage(img);
                        imgData = e.target.result.length > imgData.length? imgData:e.target.result;
                        elData.setAttribute('value', imgData);
                        if (callback)
                            callback(imgData);
                    }
                }
                if (img) {
                    loadImage();
                    img.setAttribute('src', e.target.result);
                    console.log('org: ' + e.target.result.length);
                }else if (callback){
                    callback(e.target.result);
                }
            };
            reader.readAsDataURL(file);
        }
    }

    static range(start, end) {
        let ans = [];
        for (let i = start; i <= end; i++) {
            ans.push(i);
        }
        return ans;
    }

    static toFloatString(v, decimalPlaces){
        function format(){
            try {
                return v.toFixed(decimalPlaces || 2);
            }catch (e){
                return '0.00';
            }
        }
        if (!isNaN(v)) {
            v = Utils.removeAllNonNumerics((v||'').toString());
            v = parseFloat(v||'0');
            return format();
        }
        return format();
    }

    static removeAllNonNumerics(str){
        return typeof str === 'string'? str.replace(/[^0-9.]/g,''):str;
    }

    static removeAllSymbols(str){
        return typeof str === 'string'? str.replace(/[^a-zA-Z ]/g,''):str;
    }

    static parseFloat(s, defaultValue){
        if (s) {
            let value = parseFloat(s.toString());
            if (!isNaN(value))
                return value;
        }
        return defaultValue || 0;
    }

    static parseInt(s, d, isStrip){
        if (!s)
            return d;
        if (isStrip)
            s = Utils.removeAllNonNumerics(s);
        if (isNaN(s))
            return d;
        return parseInt(s.toString());
    }

    static deleteCookie(cname){
        Utils.setCookie(cname, '', 0);
    }

    static cookie(cname, cvalue, duration){
        if (!cvalue){
            let v = Utils.getCookie(cname);
            if (!v && cvalue===null){
                return;
            }
            return v;
        }
        Utils.setCookie(cname, cvalue, duration);
        return cvalue;
    }

    static setCookie(cname, cValue, duration=1) {
        let d = new Date();
        d.setTime(d.getTime() + (duration * 24 * 60 * 60 * 1000));
        let expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cValue + ";" + expires + ";path=/";
    }

    static getCookie(cname) {
        let name = cname + "=";
        let ca = document.cookie.split(';');
        for(let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    static createClassnames(classnamesObject) {
        return Object.keys(classnamesObject).filter(key => classnamesObject[key] === true).join(' ');
    }

    static getBrowserLocation(){
        return fetch('https://www.cloudflare.com/cdn-cgi/trace')
                    .then(result => {
                        console.log(result);
                    });
    }

    static getGeoLocation() {
        return new Promise(response => {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(position => {
                    response({lat: position.coords.latitude, long: position.coords.longitude});
                });
            } else {
                console.log("Geolocation is not supported by this browser.");
                response();
            }
        });
    }

    static getDurationSince(pastDate) {
        const now = new Date();
        const past = new Date(parseInt(pastDate));
        const diffMs = now - past; // Difference in milliseconds
    
        // Calculate the time components
        const seconds = Math.floor(diffMs / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const months = Math.floor(days / 30); // Approximate month
        const years = Math.floor(days / 365); // Approximate year
    
        const formatDuration = () => `${duration.join(', ')} ago` ;
        let duration = [];
        if (years > 0) {
            duration.push(`${years} year${years > 1 ? 's' : ''}`);
            return formatDuration();
        }
        if (months > 0) {
            duration.push(`${months % 12} month${months % 12 > 1 ? 's' : ''}`);
            return formatDuration();
        }
        if (days > 0) {
            duration.push(`${days % 30} day${days % 30 > 1 ? 's' : ''}`);
            return formatDuration();
        }
        if (hours > 0) {
            duration.push(`${hours % 24} hour${hours % 24 > 1 ? 's' : ''}`);
            return formatDuration();
        }
        if (minutes > 0) {
            duration.push(`${minutes % 60} minute${minutes % 60 > 1 ? 's' : ''}`);
            return formatDuration();
        }
        if (seconds > 0) {
            duration.push(`${seconds % 60} second${seconds % 60 > 1 ? 's' : ''}`);
        }
    
        return formatDuration();
    }

    static toDuration(seconds=0) {
        seconds = parseInt(seconds);
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const remainingSeconds = seconds % 60;

        const formattedHours = hours && String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(remainingSeconds).padStart(2, '0');

        let duration = [formattedHours, formattedMinutes, formattedSeconds];
        return duration.filter(d => d).join(':');
    }


    static toDateTimeLocalFormat(dt){
        if (isNaN(dt)){
            return;
        }
        return new Date(parseInt(dt)).toLocaleString("sv-SE", {
                    year: "numeric",
                    month: "2-digit",
                    day: "2-digit",
                    hour: "2-digit",
                    minute: "2-digit"
                }).replace(" ", "T");
    }

    static formatDateTime(str, format) {
        if (str && !isNaN(str)) {
            let dt = moment(parseInt(str)).format(format || 'MMM D, YYYY hh:mma');
            return dt;
        }
        return null;
    }

    static formatDate(str, format) {
        if (typeof parseInt(str) === 'number') {
            return moment(parseInt(str)).format(format || 'MMM D, YYYY');
        }
        return null;
    }

    static formatDateRange(startDate, endDate, format) {
        if ((typeof parseInt(startDate) === 'number') && (typeof parseInt(endDate) === 'number')) {
            return `${moment(parseInt(startDate)).format(format || 'MMM D')} - ${moment(parseInt(endDate)).format(format || 'MMM D, YYYY')}`;
        }
        return null;
    }

    static getQueryParams(search) {
        if (search) {
            const paramArr = search.replace('?', '').split('&');
            const obj = {};
            paramArr.forEach((param) => {
                const a = param.split('=');
                obj[a[0]] = a[1];
            });
            return obj;
        }
        return {};
    }
}