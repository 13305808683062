import { useState } from "react";
import {
  IonContent, IonImg,
  IonItem,
  IonItemGroup,
  IonList,
  IonPage,
  IonThumbnail,
  IonTitle, IonButtons,
  IonIcon,
  useIonViewWillEnter
} from "@ionic/react";
import pageStyles from "../Page.module.scss";
import classNames from "classnames";
import LoadingList from "../../components/LoadingList/LoadingList";
import { addCircleOutline as addIcon } from "ionicons/icons";
import { useHistory } from "react-router-dom";
import { Link, ThemeProvider } from "@mui/material";
import TeamModel from "../../serverUtils/models/TeamModel";
import { useStore } from "../../Store";
import Theme from "../../components/FormInput/Theme";
import { FilterInput } from "../../components/Form/Form";
import "./TeamsPage.scss";
import Utils from "../../serverUtils/Utils";


const TeamsPage = () => {
  const history = useHistory();
  const session = useStore((state) => state.session);
  const [teams, setTeams] = useState(null);
  const searchStrings = useStore(state => state.searchStrings);
  const setSearchStrings = useStore(state => state.setSearchStrings);

  const getData = async (search_string = searchStrings.teams) => {
    const teams = await TeamModel.getTeams({ search_string });
    if (teams) {
      teams.teams = teams.teams.map(t => {
        t.name = t.name.trim();
        return t;
      })
      .sort((a, b) => Utils.sorter(a, b, 'name'));
      setTeams(teams);
    }
  };

  useIonViewWillEnter(() => {
    if (!teams) {
      getData();
    }
  }, [teams, searchStrings.teams, session]);

  const getImage = (id) => {
    const image = teams.images_doc.find((img) => img.ref === id);
    if (image) {
      return <img src={image.data.join("")} alt={id} />;
    }
    return <div className={pageStyles.placeholderImage}></div>;
  };

  const doFilter = (s) => {
    setSearchStrings('teams', s);
    getData(s);
  };

  return (
    <IonPage className={`${pageStyles.page} TeamsPage`}>
      <ThemeProvider theme={Theme}>
        <IonContent>
          <div className={pageStyles.pageHeader}>
            <div className={pageStyles.pageHeaderImage} />
            <div className={pageStyles.pageHeaderTitleWrapper}>
              <h1
                id="teams_page"
                className={classNames("display-font", pageStyles.pageHeaderTitle)}
              >
                Teams
              </h1>
              <p className={pageStyles.pageHeaderSubtitle}>
                Find a team and join a league
              </p>
              <FilterInput label="Search Team" entity="teams" onFilter={v => doFilter(v)} />
            </div>
          </div>
          <div className={`${pageStyles.marginBottom} entity-list-body`}>
            {teams ? (
              <IonList >
                {teams.teams.map((team, i) => (
                  <IonItem key={i} routerLink={`/teams/${team.id}`} lines="none">
                    <IonItemGroup>
                      <IonThumbnail className={pageStyles.listImage}>
                        {getImage(team.id)}
                      </IonThumbnail>
                    </IonItemGroup>
                    <IonItemGroup>
                      <IonTitle><span className="entity-item-name">{team.name}</span></IonTitle>
                    </IonItemGroup>
                  </IonItem>
                ))}
              </IonList>
            ) : (
              <LoadingList />
            )}
          </div>
        </IonContent>
      </ThemeProvider>
    </IonPage>
  );
};

export default TeamsPage;
