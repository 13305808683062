import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import FormStyles from "./../Form/Form.module.scss";
import TextFormInput, { isError } from "../FormInput/TextFormInput";
import SelectFormInput from "../FormInput/SelectFormInput";
import {
  CURRENCIES,
  DATA_HAS_CHANGED_MESSAGE_WARNING,
  FormActionButtons,
  handleSubListAdd,
  handleSubListDelete,
  hasTableFormChange,
  IMAGE_TYPE, RemoveSubFormIcon,
  STATUS,
  stopFormSubmitByEnter
} from "../Form/Form";
import ImageFormInput from "../FormInput/ImageFormInput";
import MultiSelectFormInput from "../FormInput/MultiSelectFormInput";
import UserModel from "../../serverUtils/models/UserModel";
import { useHistory } from "react-router-dom";
import TeamModel from "../../serverUtils/models/TeamModel";
import ContactInfos from "../Form/ContactInfos";
import SocialMedias from "../Form/SocialMedias";
import PaymentInfos, { GIHTransactionFee } from "../Form/PaymentInfos";
import ImageModel from "../../serverUtils/models/ImageModel";
import { useStore } from "../../Store";
import { AddressForm } from "../Form/AddressForm";
import { default as AddIcon } from "@mui/icons-material/AddCircle";
import "./TeamInformationForm.scss";
import DateFormInput1 from "../FormInput/DateFormInput1";
import AlertPane from "../FormInput/AlertPane";
import { TeamSchema } from "../../serverUtils/Models";
import Utils from "../../serverUtils/Utils";

const CRC_Fields = [...Utils.listObjectKeys(TeamSchema().model), 'banner_fileName'];
export default function TeamInformationForm({ team, setTitle }) {
  const session = useStore(state => state.session);
  const setSession = useStore((state) => state.setSession);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    setValue,
    setError,
    getValues,
    getFieldState,
  } = useForm();
  const history = useHistory();
  const [message, setMessage] = useState("");
  const [formData, setFormData] = useState(team || {});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [hasChange, setHasChange] = useState(false);

  const checkCRCTimeout = useRef();

  useEffect(() => {
    if (team) {
      setValue("admins", team.admins);
      setValue("contact_infos", team.contact_infos);
      setValue("executives", team.executives);
      setValue("captains", team.captains);
      setValue("social_medias", team.social_medias);
      setFormData(team);
      team.crc = Utils.getCRC({...team, ...getValues()}, CRC_Fields, true);
    }
  }, [team, getValues('currency')]);

  const checkCRC = () => {
    checkCRCTimeout.current && clearTimeout(checkCRCTimeout.current);
    checkCRCTimeout.current = setTimeout(() => {
      let d = {...team, ...getValues()};
      let c = hasTableFormChange(d, CRC_Fields);
      setHasChange(c);
    }, 1000);
  }

  const onSubmit = async (_d) => {
    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);
    try {
      console.log(_d);
      let d = { ..._d, id: formData.id };
      let banner = d.banner;
      delete d.banner;
      let isUpdate = d.id;
      let result = await TeamModel[isUpdate ? "updateTeam" : "addTeam"](d);
      if (result && result.id) {
        d.id = result.id;
        if (banner) {
          await ImageModel.addEntityImage(banner, d, IMAGE_TYPE.team);
          formData.getImage &&
            formData.getImage()?.id &&
            (await ImageModel.deleteImage(formData.getImage().id));
          setFormData({ ...d });
        }
        UserModel.updateAdminInfo(session, setSession, "teams", {
          id: d.id,
          name: formData.name,
        });
        setMessage(`success: Successfully ${isUpdate ? "updated" : "added"} team to server.`);
        if (!isUpdate) {
          setTimeout(() => history.push(`/teams/${d.id}`), 2000);
        }
        checkCRC();
      } else {
        setMessage(`error: Error ${isUpdate ? "updating" : "adding"} team.`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const doDelete = async () => {
    if (isSubmitting) {
      return;
    }
    try {
      let result = await TeamModel.deleteTeam(formData.id);
      if (result && result.id) {
        setMessage("success: Successfully deleted team.");
        return setTimeout(() => {
          history.push("/teams");
        }, 2000);
      }
      setMessage("error: Error deleting team.");
    }finally {
      setIsSubmitting(false);
    }
  };

  console.log('errors: ', errors)
  return (
    <div className="TeamInformationForm">
      <form className={FormStyles.form} 
        onKeyDown={stopFormSubmitByEnter}
        onSubmit={handleSubmit(onSubmit)}>
        {session.is_super && <GIHTransactionFee register={register} formData={formData} />}
        <div className={FormStyles.fieldDescription}>
          <h3>Banner</h3>
        </div>
        <div className={FormStyles.fields}>
          <ImageFormInput
            name="banner"
            images={ImageModel.setImageFormInput(
              IMAGE_TYPE.team,
              getValues,
              getFieldState,
              formData,
              "banner"
            )}
            size={600} maxWidth={200}
            onImage={(d) => {
              setValue("banner", d, { shouldDirty: true });
              setValue('banner_fileName', d.fileName);
              checkCRC();
            }}
          />
        </div>
        <div className={FormStyles.fieldDescription}>
          <h3>General Information</h3>
          <p>This is the name of the team.</p>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            value={formData?.name}
            name="name"
            label="Team Name"
            isRequired
            register={register} setValue={setValue}
            onChange={checkCRC}
          />
          <SelectFormInput
            value={formData?.status}
            name="status"
            label="Team Status"
            setValue={setValue}
            options={STATUS}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Email</h3>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            type="email"
            value={team?.email}
            name="email"
            label="Email"
            register={register} setValue={setValue}
            isRequired validateWithTrim
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Address</h3>
        </div>
        <AddressForm
          address={{
            country: team.country,
            state: team.state,
            address: team.address,
            city: team.city,
            zip: team.zip,
            suburb: team.suburb,
            region: team.region,
          }}
          register={register} 
          setValue={setValue} 
          onChange={checkCRC}
        />

        <div className={FormStyles.fieldDescription}>
          <h3>Executives</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            control={control}
            value={getValues("executives") || formData.executives}
            name="executives"
            label="Executives"
            optionLabel="display"
            optionValue="id"
            fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
            setValue={setValue}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Contacts
            <AddIcon
              id="add_contacts"
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "contact_infos";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
                checkCRC();
              }}
            />
          </h3>
          <p>Set contact information for this league</p>
        </div>
        <div className={FormStyles.fields}>
          <ContactInfos
            data={getValues("contact_infos") || formData.contact_infos}
            register={register} setValue={setValue}
            refresh={() => setRefresh(!refresh)}
            errors={errors} setError={setError}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Social Medias
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "social_medias";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
                checkCRC();
              }}
            />
          </h3>
          <p>Set social medias of this tournament</p>
        </div>
        <div className={FormStyles.fields}>
          <SocialMedias
            data={getValues("social_medias") || formData.social_medias}
            register={register} setValue={setValue}
            errors={errors.social_medias}
            refresh={() => setRefresh(!refresh)}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Captains</h3>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            control={control}
            isRequired 
            value={getValues("captains") || formData.captains}
            name="captains"
            label="Captains"
            optionLabel="display"
            optionValue="id"
            fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
            setValue={setValue}
            errorText={isError(errors, 'captains')}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Admins</h3>
          <p>Important dates for the tournament.</p>
        </div>
        <div className={`${FormStyles.fields} MultiSelectFormInput-wrapper`}>
          <MultiSelectFormInput
            isRequired
            control={control}
            value={getValues("admins") || formData?.admins}
            name="admins"
            label="Admins"
            optionLabel="display"
            optionValue="id"
            fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
            setValue={setValue}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Currency</h3>
        </div>
        <div className={FormStyles.fields}>
          <SelectFormInput
            value={team.currency}
            name="currency"
            label="Currency"
            isRequired
            setValue={setValue}
            placeholder="Currency"
            options={CURRENCIES}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>Registration Fee</h3>
        </div>
        <div className={FormStyles.fields}>
          <TextFormInput
            value={team?.registration_fee}
            type="float"
            name="registration_fee"
            label="Registration Fee"
            register={register} setValue={setValue}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Payment Information
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                let field = "payment_info";
                let list = getValues(field) || [];
                list.push({});
                setValue(field, list, { shouldDirty: true });
                setRefresh(!refresh);
                checkCRC();
              }}
            />
          </h3>
          <p>Enter your payment gateway information</p>
        </div>
        <div className={FormStyles.fields}>
          <PaymentInfos
            data={getValues("payment_info") || formData.payment_info}
            register={register} setValue={setValue}
            refresh={() => setRefresh(!refresh)}
            errors={errors?.payment_info}
            onChange={checkCRC}
          />
        </div>

        <div className={FormStyles.fieldDescription}>
          <h3>
            Sub Teams
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                handleSubListAdd(setFormData, formData, "sub_teams")
                checkCRC();
              }}
            />
          </h3>
        </div>
        <div className={FormStyles.fields}>
          <SubTeams
            getValues={getValues}
            formData={formData}
            setFormData={d => {
              setFormData(d);
              checkCRC();
            }}
            register={register} setValue={setValue}
            errors={errors}
            control={control}
          />
        </div>
        <div className={FormStyles.fieldDescription}/>
        <FormActionButtons hasCRCChange={hasChange}
          doDelete={doDelete} 
          message={message} 
          id={formData.id} 
          isSubmitting={isSubmitting} />
      </form>
      {hasChange && <AlertPane isFloat message={DATA_HAS_CHANGED_MESSAGE_WARNING} />}
    </div>
  );
}

function SubTeams({
  formData,
  setFormData,
  register,
  errors,
  setValue,
  control,
  getValues,
}) {
  return (formData.sub_teams || []).map((t, i) => {
    return (
      <div key={i} className={`SubTeams ${FormStyles.fields}`} style={{ marginBottom: 20 }}>
        <TextFormInput
          name={`sub_teams[${i}].name`}
          value={t?.name}
          label="Name"
          isRequired
          register={register} setValue={setValue}
        />
        <div className={FormStyles.fieldDescription}>
          <h3>
            Members
            <AddIcon
              className={FormStyles.add_icon}
              onClick={() => {
                if (!t.members) {
                  t.members = [];
                }
                t.members.push({ id: "", display: "" });
                setFormData({ ...formData });
              }}
            />
          </h3>
        </div>
        {(t.members || []).map((m, j) => {
          return (
            <div className={`members MultiSelectFormInput-wrapper`} key={i}>
              <MultiSelectFormInput
                control={control}
                multiple={false}
                value={{ id: m.id, display: m.display || "" }}
                name={`members[${j}].name`}
                label="Member"
                optionLabel="display"
                optionValue="id"
                fetchOptions={(searchVal) => UserModel.searchUser(searchVal)}
                setValue={setValue}
                onChange={(v) => {
                  setFormData({
                    ...formData,
                    sub_teams: getValues("sub_teams"),
                  });
                }}
              />
              <DateFormInput1
                value={m.registered_on}
                name={`sub_teams[${i}].members[${j}].registered_on`}
                label="Registered Date"
                register={register}
                control={control}
                isRequired
              />
              <RemoveSubFormIcon right
                onClick={() => {
                  t.members = t.members.filter((tm) => tm.id !== m.id);
                  setFormData({ ...formData });
                }}
                label={`Member ${j + 1}`}
              />
            </div>
          );
        })}
        <div style={{ marginTop: 20 }}>
          <RemoveSubFormIcon
            label={`Subteam ${i + 1}`}
            onClick={() =>
              handleSubListDelete(setFormData, formData, "sub_teams", i, setValue)
            }
          />
        </div>
      </div>
    );
  });
}
