import { useHistory } from 'react-router-dom';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableFormInput, { IndexCell, ToolbarButtons, setTableSortText } from '../FormInput/TableFormInput';
import Utils from '../../serverUtils/Utils';
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import SelectFormInput from '../FormInput/SelectFormInput';
import TextFormInput from '../FormInput/TextFormInput';
import React from 'react';
import { Link } from '@mui/material';
import DateFormInput1, { DATE_TIME_FORMAT, getDateFormat } from '../FormInput/DateFormInput1';
import UserChip from '../UserChip/UserChip';
import UserModel from '../../serverUtils/models/UserModel';
import './TournamentSchedules.scss';
import { BRACKET, STATUS } from '../Bracket/Bracket';
import AlertPane from '../FormInput/AlertPane';
import TournamentModel from '../../serverUtils/models/TournamentModel';
import Icon from "../Icon";
import { RequestUtils } from "../../serverUtils/requests";
import { WINS_BY } from "../Bracket/bracketUtils";
import { DATA_HAS_CHANGED_MESSAGE, DATA_HAS_CHANGED_MESSAGE_WARNING, UnAthorizedMessage, hasTableFormChange } from "../Form/Form";
import { CRC_Bracket_Fields, createBracketNodeId } from "../Bracket/BracketWrapper";
import { getPlacement } from "../../pages/TournamentPage/TournamentPage";
import { useStore } from "../../Store";
import { ScheduleSchema, TournamentSchema } from "../../serverUtils/Models";

const headCells = [
    {
        id: 'division',
        numeric: false,
        disablePadding: false,
        label: 'Division',
        crc: true
    },
    {
        id: 'bracket_type',
        numeric: false,
        disablePadding: false,
        label: 'Bracket Type',
        crc: true
    },
    {
        id: 'round',
        numeric: false,
        disablePadding: false,
        label: 'Round',
        crc: true
    },
    {
        id: 'competitors',
        numeric: false,
        disablePadding: false,
        label: 'Competitors',
        crc: true
    },
    {
        id: 'result',
        disablePadding: false,
        label: 'Outcome',
        disable_filter: true,
        crc: true
    },
    {
        id: 'start_ts',
        numeric: false,
        disablePadding: false,
        label: 'Date Time',
        disable_filter: true,
        crc: true
    },
    {
        id: 'mat',
        numeric: false,
        disablePadding: false,
        label: 'Mat',
        align: 'center',
        crc: true
    },
    {
        id: 'status',
        numeric: true,
        disablePadding: false,
        label: 'Status',
        align: 'center',
        crc: true
    },
    {
        id: 'duration',
        numeric: true,
        disablePadding: false,
        label: 'Duration',
        align: 'center',
        disable_filter: true,
        crc: true
    },
    {
        id: 'overtime_duration',
        numeric: true,
        disablePadding: false,
        label: 'Overtime',
        align: 'center',
        disable_filter: true,
        crc: true
    },
    {
        id: 'scores',
        numeric: true,
        disablePadding: false,
        label: 'Scores',
        align: 'center',
        disable_filter: true,
        crc: true
    },
    
];

export const SCHEDULE_STATUS = {
    IP: 'In-Progress',
    D: 'Delay',
    C: 'Cancel',
    F: 'Completed'
}
const SCHEDULE_STATUS_OPTIONS = Object.keys(SCHEDULE_STATUS).map(k => ({ value: k, label: SCHEDULE_STATUS[k] }));

const CRC_Fields = [...Utils.listObjectKeys(ScheduleSchema().model), 'schedules'];
const TournamentSchedules = forwardRef(({ tournament, tabsRef, tabObjRefs }, ref) => {
    const history = useHistory();
    const localServer = useStore(state => state.local_server);
    const isBracketEntriesUpdate = useStore(state => state.isBracketEntriesUpdate);
    const tableRef = React.useRef();
    const checkCRCMessageRef = useRef();
    const checkCRCMessageRef1 = useRef();
    const toolbarButtonsRef = useRef();
    const toolbarButtonsRef1 = useRef();
    const [message, setMessage] = useState('');
    const [schedules, setSchedules] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const findTablePage = () => {
        if (!tableRef.current) {
            return;
        }
        let urlParams = RequestUtils.getURLParameters();
        if (urlParams.id) {
            tableRef.current.findPage(row => [row.bracket_entry1, row.bracket_entry2].includes(urlParams.id));
        }
    }

    const reloadSchedules = () => tournament.getSchedules()
        .then(ss => {
            tournament.schedules_crc = Utils.getCRC({schedules: ss}, CRC_Fields);
            setSortText(ss);
            setSchedules(ss);
            setTimeout(findTablePage, 500);
        });

    useEffect(() => {
        reloadSchedules();
    }, [tournament]);
    
    useEffect(() => {
        reloadSchedules();
    }, [isBracketEntriesUpdate]);

    useEffect(() => {
        console.log('TournamentSchedules');
    }, []);

    useImperativeHandle(ref, () => ({
        tableRef,
        getSchedules: () => schedules,
        forceUpdate: schedule => {
            let found = schedule && schedules.find(s => s.bracket_entry1 === schedule.bracket_entry1 && s.bracket_entry2 === schedule.bracket_entry2);
            if (found){
                let bentry1 = found.getBracketEntry1();
                Object.assign(bentry1, schedule.getBracketEntry1());
                let bentry2 = found.getBracketEntry2();
                Object.assign(bentry2, schedule.getBracketEntry2());
                Object.assign(found, schedule);
                found.getBracketEntry1 = () => bentry1;
                found.getBracketEntry2 = () => bentry2;
            }else {
                tournament.getSchedules().then(ss => {
                    setSortText(ss);
                    setSchedules(ss);
                });
            }
            setRefresh(!refresh);
        },
        doSave,
        checkCRC,
    }));

    const checkCRC = () => {
        let currentCRC = Utils.getCRC({schedules}, CRC_Fields);
        let r = currentCRC !== tournament.schedules_crc;
        setMessage(r? DATA_HAS_CHANGED_MESSAGE_WARNING: '');
        checkCRCMessageRef.current.innerHTML = '';
        checkCRCMessageRef1.current.innerHTML = '';
        if (r) {
            checkCRCMessageRef.current.innerHTML = DATA_HAS_CHANGED_MESSAGE;
            checkCRCMessageRef1.current.innerHTML = DATA_HAS_CHANGED_MESSAGE;
            toolbarButtonsRef.current.setIndicator({save: 'yellow'});
            toolbarButtonsRef1.current.setIndicator({save: 'yellow'});
        }else {
            toolbarButtonsRef.current.setIndicator({save: ''});
            toolbarButtonsRef1.current.setIndicator({save: ''});
        }
        return r;
    }

    const doSave = async (skipTabRefCheck) => {
        setMessage('');
        !skipTabRefCheck && await tabObjRefs.current.save('tournamentSchedulesRef');
        for (let i = schedules.length - 1; i >= 0; i--) {
            let schedule = schedules[i];
            if (schedule.status === STATUS.Delete) {
                schedules.splice(i, 1);
            }
        }
        let bracketEntries = await tournament.getBracketEntries();
        let divisions = [...new Set(schedules.map(s => s.division))];
        let isSaved = false;
        for (let d of divisions) {
            let entries = bracketEntries.filter(e => e.division === d).filter(e => hasTableFormChange(e, CRC_Bracket_Fields));
            let response = await TournamentModel.updateBracketEntries(
                entries, tournament.id, d, tournament.getAvailableDivisions().find(td => td.id === d).bracket_type);
                isSaved = true;
            if (!response || response.error) {
                return setMessage('error: Error saving bracket');
            }

            let updates = schedules.filter(s => s.division === d).filter(e => hasTableFormChange(e, CRC_Fields));
            let _updates = Utils.copy(updates)
                .map(s => {
                    delete s.id;
                    return s;
                });
            let res = await TournamentModel.updateScheduleEntries(_updates, tournament.id, d);
            isSaved = true;
            if (!res || res.error) {
                return setMessage('error: Error updating schedules to server');
            }
            updates.forEach(s => s.crc = Utils.getCRC(s, CRC_Fields));
        }
        isSaved && setMessage('success: Successfully updated schedules to server');
    }

    const getCompetitorNames = (r) => {
        let mem1 = r.getBracketEntry1() && r.getBracketEntry1().getMembership();
        let mem2 = r.getBracketEntry2() && r.getBracketEntry2().getMembership();
        let name = [mem1 && mem1.first_name,
        mem1 && mem1.last_name,
        mem2 && mem2.first_name,
        mem2 && mem2.last_name];
        return name.filter(n => n).join('');
    }

    const getDivisionName = (row) => {
        return getDivisionOptions().find(o => o.value === row.division).label;
    }

    const tableComparator = (a, b, orderBy) => {
        let _a, _b;
        if (orderBy === 'competitors') {
            _a = getCompetitorNames(a);
            _b = getCompetitorNames(b);
        }else if (orderBy === 'division') {
            _a = getDivisionName(a.division);
            _b = getDivisionName(b.division);
        }else {
            _a = a[orderBy];
            _b = b[orderBy];
        }

        _a = _a? _a.toString() : '';
        _b = _b? _b.toString() : '';
        if (_b < _a) {
            return -1;
        }
        if (_b > _a) {
            return 1;
        }
        return 0;
    }

    const getDivisionOptions = () => {
        return [...tournament.poolings.map(p => ({id: p.pool, name: p.pool})),
            ...tournament.getDivisions()]
              .map(d => ({value: d.id, label: d.name}));
    }

    const getFilterData = ({headCell, filterString}, filterData, isFilter) => {
        if (!filterString){
            return schedules;
        }
        let words = Utils.separateWords(filterString);
        return (filterData || schedules).filter(d => {
            let s = d[headCell.id];
            if (headCell.id === 'competitors') {
                return isFilter(words, () => getCompetitorNames(d))
            }else if (headCell.id === 'division') {
                return isFilter(words, () => getDivisionName(d));
            }else if (headCell.id === 'status') {
                return isFilter(words, () => SCHEDULE_STATUS_OPTIONS.find(o => o.value === filterString))
            } else if (headCell.id === 'start_ts') {
                return isFilter(words, () => Utils.formatDateTime(s.start_date, DATE_TIME_FORMAT));
            } else if (headCell.id === 'round') {
                return isFilter(words, () => d.round-1, true);
            } else if (headCell.id === 'bracket_type') {
                return isFilter(words, () => BRACKET.getBracketName(d.bracket_type));
            } else if (headCell.id === 'start_date') {
                return isFilter(words, () => Utils.formatDateTime(d.start_date))
            }

            return isFilter(words, () => s);
        });
    }

    const doDelete = async () => {
        setMessage('');
        if (tableRef.current.selected.length === 0) {
            return setMessage('info: Nothing is selected');
        }
        tableRef.current.selected.forEach(d => {
            schedules.find(s => s.id === d).status = STATUS.Delete;
        })
        setTimeout(() => {
            tableRef.current.gotoPage(0);
            tableRef.current.setSelected([]);
        }, 500);
    }

    const setSortText = (ss) => {
        let sortableFields = headCells.filter(hc => !hc.disable_filter).map(hc => hc.id);
        ss.forEach(row => {
            sortableFields.forEach(f => {
                f==='division' && setTableSortText(row, row.getBracketEntry1().getDivision().name, f);
                f==='bracket_type' && setTableSortText(row, BRACKET.getBracketName((row.bracket_type || BRACKET.Single_Elimination)), f);
                f==='competitors' && setTableSortText(row, 
                    `${UserModel.getMembershipName(row.getBracketEntry1().getMembership())} ${!row.getBracketEntry2()? '':UserModel.getMembershipName(row.getBracketEntry2().getMembership())}`, 
                    'competitors');
            })
        })
    }

    const toolbarButtons = ref => (
        <ToolbarButtons ref={ref} doSave={!localServer && doSave} doDelete={doDelete} />
    );

    let nonDeletes = schedules.filter(s => s.status !== STATUS.Delete);
    return <div className="TournamentSchedules" >
        <UnAthorizedMessage />
            <AlertPane message={message} isFloat/>
            <TableFormInput name="TournamentSchedules"
                ref={tableRef}
                isEditable
                toolbarButtons={() => toolbarButtons(toolbarButtonsRef)}
                toolbarButtons1={() => toolbarButtons(toolbarButtonsRef1)}
                onSelectedUpdate={<span ref={checkCRCMessageRef} className="warning" />}
                onSelectedUpdate1={<span ref={checkCRCMessageRef1} className="warning" />}
                headCells={headCells}
                data={nonDeletes}
                // getFilterData={getFilterData}
                comparator={tableComparator}
                renderTRow={({ row, isSelected, index, handleClick, updateRowData }) => {
                    const isItemSelected = isSelected(row.id);
                    const getBracketId = () => {
                        let be1 = row.getBracketEntry1();
                        let be2 = row.getBracketEntry2();
                        return [be1.id, be2 && be2.id].join('_');
                    }
                    const labelId = `enhanced-table-checkbox-${index}-${getBracketId()}`;

                    const getStatus = () => {
                        if (row.winner) {
                            return SCHEDULE_STATUS.F;
                        }
                        return SCHEDULE_STATUS[row.status];
                    }
                    const getHighlite = () => {
                        return [row.getBracketEntry1().membership, row.getBracketEntry2().membership].filter(e => e).join('|');
                    }
                    let isEdit = document.location.pathname.includes('/edit');
                    return <TableRow key={index} className={`schedule-${getStatus()}`}
                        hover
                        onClick={(event) => handleClick(event, row.id)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                    >
                        <TableCell padding="checkbox">
                            <IndexCell labelId={labelId} isItemSelected={isItemSelected} index={index + 1} />
                        </TableCell>
                        <TableCell >
                            <Link style={{ cursor: 'pointer' }} division={row.division} 
                                onClick={e => {
                                    e.stopPropagation();
                                    let be = row.getBracketEntry1() || row.getBracketEntry2();
                                    let node_id = createBracketNodeId({ round: be.round + 1, index: Math.floor(be.index / 2), isConsolidation: be.isConsolidation });
                                    history.push(`/tournaments/${row.tournament}${isEdit? '/edit':''}?tab=brackets&id=${row.division}&node=${node_id}&highlite=${getHighlite()}&round=${row.getBracketEntry1().round}`);
                                    tabsRef.current.setActiveTabByName('brackets');
                                }}>
                                {setTableSortText(row, row.getBracketEntry1().getDivision().name, 'division')}    
                            </Link>
                        </TableCell>
                        <TableCell>
                            <div className="flex-row" >
                                <Icon name="bracket" />
                                {setTableSortText(row, BRACKET.getBracketName((row.bracket_type || BRACKET.Single_Elimination)), 'bracket_type')}
                            </div>
                        </TableCell>
                        <TableCell align="center">
                            {row.getBracketEntry1().round + 1}
                        </TableCell>
                        <TableCell>
                            <span className="search-text">
                                {setTableSortText(row, 
                                    `${UserModel.getMembershipName(row.getBracketEntry1().getMembership())} ${!row.getBracketEntry2()? '':UserModel.getMembershipName(row.getBracketEntry2().getMembership())}`, 
                                    'competitors')}
                            </span>
                            <div className={`competitors ${isItemSelected? 'selected':''}`}>
                                <UserChip membership={row.getBracketEntry1().getMembership()} />
                                {row.getBracketEntry2() ? <UserChip membership={row.getBracketEntry2().getMembership()} /> : <b>TBD</b>}
                            </div>
                        </TableCell>
                        <TableCell align="center">
                            <SetOutcome row={row} isSelected={isItemSelected} onUpdate={checkCRC}/>
                        </TableCell>
                        <TableCell align="center">
                            <SetDateTime dt={row.start_ts} 
                                onUpdate={v => {
                                    row.start_ts = v;
                                    checkCRC();
                                }} />
                        </TableCell>
                        <TableCell align="center">
                            <SetMat m={row.mat} 
                                onUpdate={v => {
                                    row.mat = v;
                                    checkCRC();
                                }} />
                        </TableCell>
                        <TableCell align="center">
                            <SetStatus s={row.status} 
                                onUpdate={v => {
                                    row.status = v;
                                    checkCRC();
                                }} />
                        </TableCell>
                        <TableCell align="center">
                            <SetDuration d={row.duration} 
                                onUpdate={v => {
                                    row.duration = v;
                                    checkCRC();
                                }} />
                        </TableCell>
                        <TableCell align="center">
                            <SetDuration d={row.overtime_duration} 
                                onUpdate={v => {
                                    row.overtime_duration = v;
                                    checkCRC();
                                }} />
                        </TableCell>
                        <TableCell align="center">
                            <SetScores row={row} checkCRC={checkCRC}/>
                        </TableCell>
                    </TableRow>
                }}
            />
        </div>
        ;
});

const Placements = ({ row }) => {
    const getPlace = (e) => {
        return e && e.place &&
            <div style={{ whiteSpace: 'nowrap' }}>
                <span style={{ fontStyle: 'italic' }}>{getPlacement(e.place)}: </span>
                <b> {UserModel.getMembershipName(e.getMembership())}</b>
            </div>
    }
    return <div className="Placements">
        {getPlace(row.getBracketEntry1())}
        {getPlace(row.getBracketEntry2())}
    </div>
}
const SetDuration = ({ d, onUpdate }) => {
    const [duration, setDuration] = useState();
    useEffect(() => {
        setDuration(d);
    }, [d]);


    const formatDuration = (v, duration, isMinutes) => {
        if (parseInt(v) < 0 || parseInt(v) > 59) {
            return;
        }
        duration = (duration || '00:00').split(':');
        if (isMinutes) {
            v = `${Utils.padZeros(v, 2)}:${duration[1]}`;
        }else {
            v = `${duration[0]}:${Utils.padZeros(v, 2)}`;
        }

        setDuration(v);
        onUpdate(v)
    }
    const getDuration = (duration, isMinutes) => {
        duration = (duration || '00:00').split(':');
        if (isMinutes) {
            return duration[0];
        }
        return duration[1];
    }

    return < div className="SetDuration">
        <TextFormInput type="number" label="Mins"
            value={getDuration(duration, true)} 
            inputProps={{
                onChange: e => formatDuration(e.target.value, duration, true)
            }}
        />
        <TextFormInput type="number" label="Secs"
            value={getDuration(duration)} 
            inputProps={{
                onChange: e => formatDuration(e.target.value, duration)
            }}
        />
    </div >

}

const SetStatus = ({ s, onUpdate }) => {
    const [status, setStatus] = useState();
    useEffect(() => setStatus(s), [s]);

    return < SelectFormInput value={status || ''} width={120}
        name="status"
        label="Status"
        placeholder="Select Status"
        options={[{ value: '', label: '-' }, ...SCHEDULE_STATUS_OPTIONS]}
        onChange={v => {
            setStatus(v);
            onUpdate(v);
        }}
    />
}

const SetMat = ({ m, onUpdate }) => {
    const [mat, setMat] = useState();
    useEffect(() => setMat(m), [m]);

    return <div style={{ width: 60 }}>
        <TextFormInput
            value={mat} label="Mat"
            inputProps={{
                onChange: e => {
                    let v = e.target.value
                    setMat(v);
                    onUpdate(v);
                },
            }}
        />
    </div>
}

const SetDateTime = ({ dt, onUpdate }) => {
    const [dateTime, setDateTime] = useState();
    useEffect(() => setDateTime(dt), [dt]);

    return <DateFormInput1
        value={dateTime ? getDateFormat(dateTime, true) : ''}
        name="date"
        label="Date"
        onChange={(v) => {
            setDateTime(v);
            onUpdate(v);
        }}
    />
}

const getWinner = (memberships, bracketEntries, division, round, isThird, index) => {
    let found = bracketEntries.filter(be => be.division === division)
        .filter(be => {
            return be.division === division &&
                be.round === round &&
                be.index === index &&
                be.result &&
                (be.isThird||false) === (isThird||false) &&
                memberships.includes(be.membership);
        });
    return found.length>0 && found[0];
}

const SetOutcome = ({ row, onUpdate, isSelected }) => {
    const [winBy, setWinBy] = useState('');
    const [winner, setWinner] = useState();
    const scrollViewRef = useRef();
    useEffect(() => {
        isSelected && scrollViewRef.current && 
        scrollViewRef.current.ref.scrollIntoView({
            behavior: 'smooth', 
            block: 'center',     
        });
    }, []);

    useEffect(() => {
        const updateWinner = (be) => {
            if (be && be.result){
                setWinner(be);
                setWinBy(be.result);
                onUpdate();
            }
        }
        let be1 = row.getBracketEntry1();
        let be2 = row.getBracketEntry2();
        if ((be1 && be1.result) || (be2 && be2.result)){
            updateWinner(be1);
            updateWinner(be2);
        }else {
            setWinner(null);
            setWinBy(null);
        }
    }, [row.bracket_entry1, row.bracket_entry2]);


    return winner ?
        <div className="SetOutcome" ref={isSelected? scrollViewRef:undefined}>
            <div className="winner">Winner: <b>{UserModel.getMembershipName(winner.getMembership())}</b> <span className="place">{getPlacement(winner.place)}</span></div>
            <Placements row={row} />
            <span><b>Win By: </b> {winBy? WINS_BY[winBy].label:''}</span>
        </div> : '';
}

const SetScores = ({row, checkCRC}) => {
    const Score = ({bracketEntry={}}) => {
        const [refresh, setRefresh] = useState(false);

        const updateScore = (e, field) => {
            let v = parseInt(e.target.value);
            if (v > 0) {
                bracketEntry[field] = v;
                setRefresh(!refresh);
            }
            checkCRC();
        }
        return <div className="Scores">
            <TextFormInput type="number"
                value={bracketEntry.score} label="Score1"
                inputProps={{
                    onChange: e => updateScore(e, 'score')
                }}
            />
            <TextFormInput type="number"
                value={bracketEntry.score2} label="Score2"
                inputProps={{
                    onChange: e => updateScore(e, 'score2')
                }}
            />
            <TextFormInput type="number"
                value={bracketEntry.scorep} label="Penalty"
                inputProps={{
                    onChange: e => updateScore(e, 'scorep')
                }}
            />
        </div>;
    }

    return <div className="SetScores">
        <Score bracketEntry={row.getBracketEntry1()} />
        <Score bracketEntry={row.getBracketEntry2()} />
    </div>
}
export default TournamentSchedules;
