import styles from "./FormInput.module.scss";
import { FormControl, FormHelperText, FormLabel, TextareaAutosize, ThemeProvider } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import Theme from "./Theme";
import "./TextAreaFormInput.scss";
import { isKeyEnter } from "../Form/Form";

const MUI_CLASSES = 'MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiFormLabel-filled MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined css-1eluwif-MuiFormLabel-root-MuiInputLabel-root MuiInputBase-input';
const TextAreaFormInput = ({
    value,
    name,
    label,
    placeholder,
    disabled,
    isRequired = false,
    register, setValue,
    hidden = false,
    onChange,
    onKeyUp,
    onBlur,
    onMouseLeave,
    errorText,
    maxRows=6,
}) => {

    const [muiClass, setMuiClass] = useState(MUI_CLASSES);
    const [inputValue, setInputValue] = useState(value);
    const [isFocus, setIsFocus] = useState(false);
    useEffect(() => {
        setInputValue(value||'');
        setValue && setValue(name, value);
    }, [value]);
    const reactHookProps = !register ? {} :
        register(name, {
            required: isRequired && 'This field is required.',
        });

    const isError = () => isRequired && !inputValue? 'true':undefined;
    return <div className={`TextAreaFormInput ${(name||'').split('.').pop()}`}>
        <FormControl id={name} fullWidth className={classNames(hidden ? styles.hiddenInput : '')}>
            <ThemeProvider theme={Theme}>
                <FormLabel className={`FormLabel ${muiClass}  ${isError()? 'error':''} ${!isError() && isFocus? 'focus':''}`}><span>{label || ''}</span></FormLabel>
                <TextareaAutosize className={`TextareaAutosize css-u4tvz2-MuiFormLabel-root ${isError()? 'error':''}`}
                    disabled={disabled}
                    hidden={hidden}
                    value={inputValue || ''}
                    error={isError()}
                    name={name}
                    minRows={2}
                    maxRows={maxRows}
                    placeholder={placeholder || ''}
                    {...reactHookProps}
                    onChange={e => {console.log('onChange', e);
                        let value = e.target.value;
                        setInputValue(value);
                        setValue && setValue(name, value);
                        onChange && onChange(value);
                    }}
                    onFocusCapture={e => {
                        setIsFocus(true);
                    }}
                    onBlur={e => {
                        setIsFocus(false);
                        onBlur && onBlur(e);
                    }}
                    onKeyUp={onKeyUp}
                    onKeyDown={e => {console.log('onKeyDown', e);
                        if (isKeyEnter(e)) {
                            e.stopPropagation();
                            e.preventDefault();
                            function insertReturnChar(str, position) {
                                return str.slice(0, position) + "\n" + str.slice(position);
                            }
                            let textarea = e.target;
                            const curPos = textarea.selectionStart;
                            let value = textarea.value;
                            setInputValue(insertReturnChar(value, curPos));
                            setValue && setValue(name, value);
                            onChange && onChange(value);
                        }
                    }}
                    onMouseLeave={onMouseLeave}
                />
                <FormHelperText className={`FormHelperText ${isError()? 'error':''}`}>
                    {errorText}
                </FormHelperText>
            </ThemeProvider>
        </FormControl>
    </div>;
};

export default TextAreaFormInput;