import { Avatar, Link, Table, TableBody, TableCell, TableHead, TableRow, ThemeProvider } from "@mui/material";
import Theme from "../../components/FormInput/Theme";
import TournamentModel from "../../serverUtils/models/TournamentModel";
import "./Points.scss";
import { useEffect, useState } from "react";
import { getPlacements } from "./Rankings";
import Utils from "../../serverUtils/Utils";

export default function Points({ tournament, point_type='teams' }) {
    const [places, setPlaces] = useState([]);

    useEffect(() => {
        getPlacements(tournament).then(ps => {
            let placements = {};
            const getByPlace = place => {
                let _places = ps.filter(p => p.place === place);
                _places.forEach(p => {
                    let team =  p.getRegistration().getTeam();
                    let gym = p.getGym();
                    let key = point_type === 'teams'? team : gym;
                    if (!key) {
                        return true;
                    }
                    let placement = placements[key.id];
                    if (!placement) {
                        placements[key.id] = placement = [];
                    }
                    placement.push(p);
                    let gs = tournament.getGroupScores().find(gs => gs.divisions.includes(p.division));
                    if (!gs) {
                        gs = tournament.getGroupScores().find(gs => !gs.divisions || gs.divisions.length===0);
                    }
                    if (gs) {
                        const getPoint = () => {
                            const {first=0, second=0, third=0, fourth=0} = gs.placement_point;
                            if (place === 1){
                                return first;
                            }else if (place === 2){
                                return second;
                            }else if (place === 3){
                                return third;
                            }else if (place === 4){
                                return fourth;
                            }
                            return 0;
                        }
                        p.point = getPoint();
                    }
                });
            }
            for (let p=1; p<=4; p++) {
                getByPlace(p);
            }

            let results = [];
            Object.keys(placements).forEach(k => {
                let entityPlacements = placements[k];
                results.push({
                    entity: k,
                    total_points: Utils.sumArray(entityPlacements.map(p => p.point)),
                    placements: entityPlacements,
                    getTeam: entityPlacements.find(e => e.getMembership().getTeam && e.getMembership().getTeam())?.getMembership()?.getTeam,
                    getGym: entityPlacements.find(e => e.getGym())?.getGym,
                    firsts: entityPlacements.filter(e => e.place===1),
                    seconds: entityPlacements.filter(e => e.place===2),
                    thirds: entityPlacements.filter(e => e.place===3),
                    fourths: entityPlacements.filter(e => e.place===4),
                }) 
            });
            results = results.sort((a, b) => Utils.sorter(b, a, 'total_points'));
            setPlaces(results.filter(r => point_type==='teams'? r.getTeam() : r.getGym()));
        });
    }, [tournament]);

    return <ThemeProvider theme={Theme}>
        <div className="Points">
            <Table aria-label="table">
                <TableHead>
                    <TableRow>
                        <TableCell>Place</TableCell>
                        <TableCell align="left">{point_type==='teams'? 'Team':'Gym'}</TableCell>
                        <TableCell align="center">Points</TableCell>
                        <TableCell align="center">1st</TableCell>
                        <TableCell align="center">2nd</TableCell>
                        <TableCell align="center">3rd</TableCell>
                        <TableCell align="center">4th</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {places.map((row, i) => {
                        return (
                            <TableRow
                                key={i}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {TournamentModel.renderPlacement(i+1)}
                                </TableCell>
                                <TableCell align="left">
                                    <div className="team">
                                        <Link href={point_type==='teams'? `/teams/${row?.getTeam().id}`:`/gyms/${row?.getGym().id}`}>
                                            <div className="name">{point_type==='teams'? row.getTeam().name:row.getGym().name}</div>
                                        </Link> 
                                    </div>
                                </TableCell>
                                <TableCell align="center">{row.total_points}</TableCell>
                                <TableCell align="center">{row.firsts.length}</TableCell>
                                <TableCell align="center">{row.seconds.length}</TableCell>
                                <TableCell align="center">{row.thirds.length}</TableCell>
                                <TableCell align="center">{row.fourths.length}</TableCell>
                            </TableRow>
                        )
                    })}
                </TableBody>
            </Table>
        </div>
    </ThemeProvider>
}