import Utils from "../Utils";
import { BRACKET, STATUS } from "../../components/Bracket/Bracket";
import { RequestCart, RequestImage, RequestMessage, RequestStore, RequestTournament, RequestUtils } from "../requests";
import { IMAGE_TYPE, MESSAGE_TYPE } from "../../components/Form/Form";
import { convertDate } from "../Models";
import UserModel, { getMembershipName } from "./UserModel";
import { GRAPPLING_TYPES } from "../../components/LeagueInformationForm/Divisions";
import { getGenderLabel } from "../../components/LeagueInformationForm/WeightClasses";
import CartModel from "./CartModel";
import { Alert, Link } from "@mui/material";
import { default as Trophy } from '@mui/icons-material/EmojiEvents';
import dayjs from "dayjs";
import AlertPane from "../../components/FormInput/AlertPane";
import { BYE, calculateTotalRounds } from "../../components/Bracket/bracketUtils";
import LocalServerModel from "./LocalServerModel";
import { IonSpinner } from "@ionic/react";
const { STORE_ITEM_TYPE, validatePromo } = require("../../serverUtils/models/modelMethods");

export const BAN = {
    membership: 'membership',
    team: 'team',
    gym: 'gym',
    league: 'league',
};

class TournamentModel {
    static changeRegistrationGym = async (params) => {
        let response = await RequestTournament.changeRegistrationGym(params);
        return RequestUtils.getResponseData(response);
    }

    static changeRegistrationTeam = async (params) => {
        let response = await RequestTournament.changeRegistrationTeam(params);
        return RequestUtils.getResponseData(response);
    }

    static getTournamentDates = async (id) => {
        let response = await RequestTournament.getTournamentDates(id);
        return RequestUtils.getResponseData(response);
    }

    static getMessages = async (id) => {
        let response = await RequestTournament.getTournamentMessagesRequest(id);
        return RequestUtils.getResponseData(response);
    }

    static async getDivisions(ids){
        let response = await RequestTournament.getDivisions(ids);
        return RequestUtils.getResponseData(response);
    }
    static async importRegistrations(file, tournament){
        let response = await RequestTournament.importRegistrations(file, tournament);
        return RequestUtils.getResponseData(response);
    }

    static async payOut(ref, secret_key, amount) {
        let response = await RequestCart.payout(ref, secret_key, amount);
        return RequestUtils.getResponseData(response);
    }

    static async checkUrlPath(league_id, url_path) {
        let response = await RequestTournament.checkUrlPath({ league_id, url_path });
        return RequestUtils.getResponseData(response);
    }

    static async addScheduleEntry(params) {
        let response = await RequestTournament.addBracketEntry(params);
        return RequestUtils.getResponseData(response);
    }

    static async deleteScheduleEntry(id) {
        let response = await RequestTournament.deleteBracketEntry(id);
        return RequestUtils.getResponseData(response);
    }

    static async updateScheduleEntries(entries, tounament, division) {
        const segmentSize = 50;
        let segments = Math.ceil(entries.length / segmentSize);
        let result;
        for (let s = 0; s < segments; s++) {
            let startIndex = s * segmentSize;
            let endIndex = Math.min(startIndex + segmentSize, entries.length);
            let uentries = entries.slice(startIndex, endIndex);
            let response = await RequestTournament.updateScheduleEntries(uentries, tounament, division, s > 0);
            result = RequestUtils.getResponseData(response);
            if (result.error) {
                return result;
            }
        }
        return result;
    }

    static async updateBracketEntries(entries, tournament, division, bracket_type) {
        const segmentSize = 50;
        let segments = Math.ceil(entries.length / segmentSize);
        let result;
        for (let s = 0; s <= segments; s++) {
            let startIndex = s * segmentSize;
            let endIndex = Math.min(startIndex + segmentSize, entries.length);
            let uentries = entries.slice(startIndex, endIndex);
            let response = await RequestTournament.updateBracketEntries(uentries, tournament, division, bracket_type, s > 0);
            result = RequestUtils.getResponseData(response);
            if (result && !result.error) {
                uentries.forEach(e => delete e.updated);
            }
        }
        return result;
    }

    static async getScheduleEntries(tournament, isLocalServer) {
        let response = isLocalServer? 
            await LocalServerModel.getScheduleEntries(tournament) :
            await RequestTournament.getScheduleEntries(tournament);
        let result = RequestUtils.getResponseData(response);
        if (!result || result.error) {
            return [];
        }
        return result;
    }

    static async getBracketEntries(tournament, isLocalServer) {
        let response = isLocalServer? 
            await LocalServerModel.getBracketEntries(tournament) :
            await RequestTournament.getBracketEntries(tournament);
        let result = RequestUtils.getResponseData(response);
        if (!result || result.error) {
            return [];
        }
        return result.filter(be => be.division && be.tournament);
    }

    static async addBracketEntry(params) {
        let response = await RequestTournament.addBracketEntry(params);
        return RequestUtils.getResponseData(response);
    }

    static async deleteBracketEntry(id) {
        let response = await RequestTournament.deleteBracketEntry(id);
        return RequestUtils.getResponseData(response);
    }

    static async updateBracketEntry(params) {
        let response = await RequestTournament.updateBracketEntry(params);
        return RequestUtils.getResponseData(response);
    }

    static async updateTournamentPool(id, poolings) {
        let response = await RequestTournament.updateTournamentPoolingsRequest({ id, poolings });
        return RequestUtils.getResponseData(response);
    }

    static async requestRefund(id, request) {
        let response = await RequestTournament.requestRefund(id, request);
        return RequestUtils.getResponseData(response);
    }

    static getLocation(t) {
        let loc = t && t?.locations.length > 0 && t.locations[0];
        return !loc ? '' : <div>at the <b>{[loc.name, loc.address, loc.city, loc.country].filter(l => l).join(', ')}</b></div>;
    }

    static getTournamentDescription(t) {
        return <div>on <b>{`${dayjs(t.dates.start_date).format('MMMM D, YYYY')}`}</b>{TournamentModel.getLocation()}</div>;
    }

    static renderPlacement(place) {
        switch (place) {
            case 1:
                return <div>{'1st'}<Trophy style={{ color: 'gold' }} /></div>;
            case 2:
                return <div>{'2nd'}<Trophy style={{ color: 'silver' }} /></div>;
            case 3:
                return <div>{'3rd'}<Trophy style={{ color: '#cd7f32' }} /></div>;
            case 4:
                return <div>{`${place}th`}<Trophy style={{ color: '#663d14' }} /></div>;
            default:
                return <div>{`${place}th`}<Trophy style={{ color: 'gray' }} /></div>;
        }
    }

    static async searchTournament(search_string = '') {
        let response = await RequestTournament.searchTournament({ search_string });
        let result = RequestUtils.getResponseData(response);
        return result.map(t => {
            let params = t.display.split(' [');
            let date = params[1].split(']')[0];
            t.display = `${params[0]} [${Utils.formatDate(convertDate(date))}]`;
            return t;
        });
    }

    static getDivisionsMessage = (tournament) => {
        if (!tournament.getLeague || !tournament.getLeague()) {
            let message = <Alert severity="error">
                You will need to create a league to setup the
                tournament divisions.  Click here to <Link href="/leagues/add">create a new league</Link>
            </Alert>;
            return <AlertPane message={message} />
        }
        if (!tournament) {
            return 'No division is available. Create your divisions from your league information page.';
        }
        return <div className="tab-busy"><IonSpinner name="circles" className="spinner"/><span className="warning">Loading list...</span></div>;
    }

    static async deleteTournamentDivision(id, divisions) {
        let response = await RequestTournament.deleteTournamentDivisions({ id, divisions });
        return RequestUtils.getResponseData(response);
    }

    static async updateDivisions(id, divisions) {
        let response = await RequestTournament.updateTournamentDivisionsRequest({id, divisions});
        return RequestUtils.getResponseData(response);
    }

    static async updateFeeInfo(id, fee_info) {
        let response = await RequestTournament.updateTournamentFeeInfo({ id, fee_info });
        return RequestUtils.getResponseData(response);
    }

    static async cloneTournament(id) {
        let response = await RequestTournament.cloneTournamentRequest(id);
        return RequestUtils.getResponseData(response);
    }

    static async updateTournament(params) {
        let response = await RequestTournament.updateTournamentRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async deleteTournament(id) {
        let response = await RequestTournament.deleteTournament(id);
        return RequestUtils.getResponseData(response);
    }

    static async saveRegistration(params) {
        let response = await RequestTournament.saveTournamentRegistrationRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async sendMessage(content, subject) {
        let params = {content, subject, message_type: MESSAGE_TYPE.tournament};
        let response = await RequestMessage.sendMessageRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async getTournaments({page, search_string}) {
        let response = await RequestTournament.getTournamentsRequest({page, search_string});
        let data = RequestUtils.getResponseData(response);
        return data && data.tournaments.map(t => {
            t.getImage = () => data.images_doc.find(i => i.ref === t.id);
            t.getLeague = () => t.league && data.leagues_doc.find(l => l && l.id===t.league)
            return t;
        }).sort((a, b) => {
            return Utils.sort(b.dates.start_date, a.dates.start_date);
        });
    }

    static getTournamentRegistrationCartDesc(desc){
        return desc.split('\\').pop().replace('[','').replace(']','');
    }

    static newCart(session, tournament) {
        if(!session) {
            return;
        }
        return {
            status: 'A',
            items: [],
            membership: session.id,
            ref: tournament.id,
            ref_type: CartModel.REF_TYPE.tournament,
            ref_display: tournament.name,
            payment_info: tournament.payment_info,
            currency: tournament.getCurrency(),
        };
    }

    static getDivisionPath(division, excludeName, isForCart){
        let path = [GRAPPLING_TYPES.find(g=> g.value===division.grappling_type)?.label,
                    getGenderLabel(division.gender),
                    division.getRank && division.getRank()?.name,
                    division.getAgeGroup && division.getAgeGroup()?.name,
                    division.getWeightClass && division.getWeightClass()?.name,
                    excludeName? '':`[${division.name}]`
                    ].filter(n => n);
        if (isForCart){
            return path.join('\\');
        }
        return <span>{path.map((p, i) => <span key={i}>{p}{i<path.length-1? <b>\</b>:''}</span>)}</span>;
    }

    static async calculateComboFees(cart, vendor) {
        if (!cart){
            return;
        }
        let _cart = Utils.copy(cart);
        let divisions = _cart?.items?.map(i => i.item_type === STORE_ITEM_TYPE.tournament_registration && i.sku)
                              .filter(i => i);
        if (!divisions) {
            return;
        }

        let response = await RequestTournament.getComboPrice({ divisions, id: _cart.ref})
        const {prices, diffs, combo_only, fee_info: fis} = RequestUtils.getResponseData(response);
        let fee_info = JSON.parse(decodeURIComponent(fis))
                            .map(fi => {
                                fi.fees.forEach(f => {
                                    f.start_date = convertDate(f.start_date);
                                    f.end_date = convertDate(f.end_date);
                                });
                                return fi;
                            });

        let combos = prices;
        for (let combo of combos){
            let skus = [], descriptions=[];
            for (let item of _cart.items){
                if (item.item_type === STORE_ITEM_TYPE.tournament_registration && combo.combo_ids.includes(item.sku)) {
                    item.original_unit_price = item.unit_price;
                    item.unit_price = 0;
                    skus.push(item.sku);
                    item.combo_ids = combo.combo_ids.join(',');
                    descriptions.push(item.description);
                }
            }
            _cart.items.push({
                sku: skus.join(','),
                description: combo.fee_name || `Combo pricing "${descriptions.join(' & ')}"`,
                cart_description: combo.fee_name || `Combo pricing "${descriptions.map(d => TournamentModel.getTournamentRegistrationCartDesc(d)).join(' & ')}"`,
                quantity: 0,
                unit_price: combo.amount,
                item_type: STORE_ITEM_TYPE.tournament_combo_pricing
            });
        }
        let message = combo_only.map(co => {
            let item = _cart.items.find(i => i.sku===co);
            item.invalid = true;
            return vendor.getDivisions().find(d => d.id===co)?.name;
        }).join(', ');

        let priceChangeMsg = diffs.map(i => {
            let item = _cart.items.find(d => d.sku===i);
            let currentPrice = TournamentModel.getDivisionFee({
                division: {id: i}, 
                isNumber: true, 
                tournament: vendor, 
                ts: new Date().getTime(), 
                fee_info
            });
            if (item.unit_price !== currentPrice){
                item.unit_price = currentPrice;
                Object.assign(cart.items.find(_i => _i.sku === item.sku), item);
                return item.cart_description || TournamentModel.getTournamentRegistrationCartDesc(item.description);
            }
        }).filter(m => m).join(', ');

        if (message || priceChangeMsg){
            message = !message? '':`We did not find matching combo pricing for ${message} division(s).  
                                    These division(s) are only available in combo pricing.  
                                    Please remove from cart before you submit.`;
            priceChangeMsg = !priceChangeMsg? '': `Price for ${priceChangeMsg} has been changed.  `;
        }
        console.log(_cart);
        return cart;
    }

    static async getCompetitors(id) {
        if (!id || id.length === 0){
            return [];
        }
        if (!Array.isArray(id)){
            id = [id];
        }
        let response = await RequestTournament.getCompetitors(id);
        return RequestUtils.getResponseData(response);
    }

    static getContacts(tournament, delimiter) {
        const {contact_infos} = tournament;
        let infos = contact_infos.map(c => [c.title, c.name, c.phone, c.email].filter(i => i))
                       .join(delimiter || ', ');
        return !infos? 'No contact information available' : infos;
    }

    static getLocations(tournament, delimiter) {
        const {locations} = tournament;
        return locations.map(l => [l.name,
                                   l.city,
                                   l.state,
                                   l.country,
                                   l.zip,
                                   l.region,
                                   l.suburb].filter(i => i))
                        .flat()
                        .join(delimiter || ', ');
    }

    static async deleteRegistration(id) {
        let response = await RequestTournament.deleteRegistration(id);
        return RequestUtils.getResponseData(response);
    }

    static async updatePromo(params) {
        let response = await RequestTournament.addPromo(params);
        return RequestUtils.getResponseData(response, params.id? 'addPromo':'updatePromo');
    }

    static async deletePromo(id) {
        let response = await RequestTournament.deletePromo(id);
        return RequestUtils.getResponseData(response);
    }

    static async getCartReport(id) {
        let response = await RequestTournament.getMerchantCartRequest(id);
        let cart = RequestUtils.getResponseData(response);
        const {carts, memberships_doc, tournament_docs, images_doc} = cart;
        return carts.map(c => {
            c.getMembership = () => {
                let mem = memberships_doc.find(m => m.id === c.membership);
                mem.getImage = () => images_doc.find(i => i.ref === mem.id);
                return mem;
            };
            c.getTournament = () => tournament_docs.find(t => t.id === c.ref && c.ref_type === 'tournament');
            return c;
        });
    }

    static async getStoreItems(id) {
        let response = await RequestStore.getStoreItemsRequest({ref: id, ref_type: 'tournament'});
        let store = RequestUtils.getResponseData(response);
        if(!store || !store.store_items){
            return;
        }
        const {store_items, images_doc} = store;
        return store_items.map(si => {
            si.getImages = () => images_doc.filter(i => i.ref === si.id);
            return si;
        });
    }

    static async addStoreItem(params) {
        let response = await RequestStore.addStoreItemRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async updateStoreItem(params) {
        let response = await RequestStore.updateStoreItemRequest(params);
        return RequestUtils.getResponseData(response);
    }

    static async deleteStoreItem(id) {
        let response = await RequestStore.deleteStoreItemRequest(id);
        return RequestUtils.getResponseData(response);
    }

    static async getPhotos(params) {
        let response = await RequestImage.getImagesByRefRequest(
                {...params, ids: [params.id], image_type: IMAGE_TYPE.photo});
        return RequestUtils.getResponseData(response)
    }

    static getBans(id, docs, bans_doc=[], banType) {
        let bans = [];
        bans_doc.forEach(b => {
            if (b.ban_type_id === id && b.ban_type === banType) {
                bans.push([
                    ...docs.filter(m => b.ban_ids.includes(m.id))
                        .map(m => {
                            m.ban_reason = b.reason;
                            return m;
                        })
                ]);
            }
        });
        return bans;
    }

    static getDivisionComboPricing(ids, tournament, keepLength) {
        if (ids.length < 2) {
            return;
        }
        const getCombinations = (_ids, len, start = 0, current = [], result = []) => {
            if (current.length === len) {
                result.push([...current]);
                return;
            }

            for (let i = start; i < _ids.length; i++) {
                current.push(_ids[i]);
                getCombinations(_ids, len, i + 1, current, result);
                current.pop();
            }
        }
        const findCombo = (_ids) => {
            let fis = tournament.fee_info.filter(fi => fi.logic.length === _ids.length);
            return fis.find(f => {
                let logics = f.logic.map(l => l.divisions);
                for (let i of _ids) {
                    let index = logics.findIndex(l => l.includes(i));
                    if (index === -1) {
                        return false;
                    }
                    logics.splice(index, 1);
                }
                return true;
            });
        }

        const getAvailCombos = (_ids) => {
            let cs = [];
            for (let len = 1; len <= _ids.length; len++) {
                getCombinations(_ids, len, 0, [], cs);
            }
            return cs.filter(c => c.length > 1);
        }
        let pricings = {};

        let combos = getAvailCombos(ids);
        if (keepLength) {
            combos = combos.filter(c => c.length === ids.length);
        }
        while (combos.length > 0) {
            let c = combos.pop();
            let fi = findCombo(c);
            if (fi) {
                pricings[c] = fi;
                ids = Utils.arrayDifference(ids, c);
                combos = getAvailCombos(ids);
            }
        }

        let ts = new Date();
        const findFee = (fees) => {
            if (!fees || fees.length === 0) {
                return;
            }
            let fee = fees.find(f => {
                return convertDate(f.start_date) <= ts && ts <= convertDate(f.end_date);
            });

            return fee;
        }
        let keys = Object.keys(pricings);
        if (keys.length === 0) {
            return;
        }
        if (keys.length > 1) {
            let p;
            for (let price of Object.values(pricings)) {
                if (!p) {
                    p = price;
                } else {
                    let fee = findFee(price.fees);
                    if (fee.amount < findFee(p.fees).amount) {
                        p = price;
                    }
                }
            }
            return findFee(p.fees);
        }

        let fee_info = Object.values(pricings)[0];
        let fee = findFee(fee_info.fees);
        if (!fee) {
            return;
        }
        return {...fee, combo: Object.keys(pricings)[0], fee_info};
    }

    static getDivisionFee({division, isNumber, tournament, ts, fee_info, isList}) {
        function formatAmount(amount) {
            return isNumber ? amount : `${tournament.getCurrency()} ${Utils.toFloatString(amount, 2)}`;
        }
        fee_info = Utils.copy(fee_info || tournament.fee_info)
            .find(fi => {
                return fi.logic && fi?.logic.map(l => {
                    return (fi.logic.length === 1) && l.divisions;
                }).flat()?.includes(division.id);
            }) || {};
        if (!fee_info) {
            return formatAmount(0);
        }
        let { fees } = fee_info;
        if (!fees || fees.length === 0) {
            if (isList){
                return;
            }
            return formatAmount(0);
        }
        if (isList) {
            return fees;
        }
        let _fees = TournamentModel.getValidFee(fees, ts);
        if (isList) {
            return _fees;
        }
        if (_fees.length === 0) {
            return false;
        }
        
        let fee = _fees.pop();
        return formatAmount(fee.amount);
    }

    static getValidFee(fees, ts) {
        fees.sort((a, b) => a.start_date < b.start_date);
        let _fees = fees.filter(fee => {
            function getDate(dt) {
                if (!isNaN(dt)) {
                    return parseInt(dt);
                }
                return new Date(dt);
            }
            let start_date = getDate(fee.start_date);
            let end_date = getDate(fee.end_date, new Date());
            ts = new Date(ts).getTime();
            return start_date <= ts && ts <= end_date;
        });
        return _fees;
    }

    static calculateStandings(teamMembers=[], group_score={}, poolings) {
        const {placement_point, divisions} = group_score;
        let firsts=0, seconds=0, thirds=0, points=0;
        let _divisions = divisions;
        let poolGS = (poolings||[]).find(p => p.group_score === group_score.id);
        if (poolGS){
            _divisions = [..._divisions, poolGS.pool];
        }
        teamMembers.forEach(m => {
            if (m && m.bracket_locations) {
                let bl = m.bracket_locations.find(bl => bl && bl.place);
                if (!bl || !_divisions.includes(bl.division)){
                    return true;
                }
                if (bl.place === 1){
                    firsts++;
                    points += placement_point.first;
                }else if (bl.place === 2){
                    seconds++;
                    points += placement_point.second;
                }else if (bl.place === 3){
                    thirds++;
                    points += placement_point.third;
                }
            }
        });
        return {firsts, seconds, thirds, points, gs: group_score};
    }

    static convertTimeZoneToLocal = (dts='') => {
        if (isNaN(dts) && dts.length === "2024-03-28T20:00:00-08:00".length){
            return new Date(dts.substring(0, dts.length-6).replace('T', ' ')).getTime();
        }
    }

    static convertToTimeZoneDate(timeStamp, time_zone) {
        if (!timeStamp) {
            return;
        }
        if (!time_zone) {
            time_zone = Utils.getBrowserTimeZone();
        }
        //2021-12-01T12:00:00+13:00
        let tz = Utils.getTimeZone(time_zone);
        if (!tz) {

            return;
        }
        let dt = Utils.formatDateTime(timeStamp, 'YYYY-MM-DD HH:mm:ss').split(' ');
        let offset = tz.offset % 1;
        if (offset !== 0) {
            offset = `${Utils.padZeros(Math.floor(tz.offset), 2)}:${Utils.padZeros(Math.floor(offset), 2)}`;
        } else {
            offset = `${Utils.padZeros(tz.offset, 2)}:00`;
        }
        return `${dt[0]}T${dt[1]}${tz.offset > 0 ? '+' : ''}${offset}`;
    }

    static setScheduleCompetitors = (s, docs) => {
        if (!s || !s.competitor1 || !s.competitor2){
            return;
        }
        function createMembership(id) {
            let membership = docs.memberships_doc.find(m => m.id === id);
            if (membership) {
                membership.getImage = () => docs.images_doc.find(i => i && i.id && i.ref === membership.id);
                membership.getName = () => getMembershipName(membership);
            }
            return membership;
        }
        s.competitor1.getMembership = () => createMembership(s.competitor1.id);
        s.competitor2.getMembership = () => createMembership(s.competitor2.id);
        return s;
    }

    static async applyPromo({ code, session, cart, promo }) {
        if (!promo) {
            let response = await RequestCart.getPromo({ code, ref: cart.getBusiness().id });
            promo = RequestUtils.getResponseData(response);
        }
        if (promo && validatePromo({ cart, session, promo })) {
            cart.promo = { id: promo.id, amount: promo.amount };
        } else {
            delete cart.promo;
        }
        return cart;
    }

    static getBracketId(b) {
        return [b.membership, b.round, b.index, b.isLoser || false, b.isThird || false].join('_');
    }

    static convertTournamentDates(tournament) {
        if (!tournament || !tournament?.dates || tournament.dates.start_date_tz) {
            return;
        }
        const {start_date, end_date, close_date, member_change_date, coach_change_date, refund_date} = tournament.dates;
        tournament.dates = {
            start_date_tz: start_date,
            end_date_tz: end_date,
            close_date_tz: close_date,
            member_change_date_tz: member_change_date,
            coach_change_date_tz: coach_change_date,
            refund_date_tz: refund_date,

            start_date: convertDate(start_date),
            end_date: convertDate(end_date),
            close_date: convertDate(close_date),
            member_change_date: convertDate(member_change_date),
            coach_change_date: convertDate(coach_change_date),
            refund_date: convertDate(refund_date),

        }
        return tournament.dates;
    }

    static formatResult = (data, isLocalServer) => {
        if (!data) {
            return;
        }
        let docs = {...data};
        for (let p of docs.promos_doc||[]) {
            p.rules.forEach(di => di.value = decodeURIComponent(di.value));
        }
        for (let m of docs.memberships_doc||[]) {
            getMembership(m, docs);
        }
        const {tournaments} = data;
        if (!tournaments || tournaments.length === 0) {
            return;
        }
        let tournament = tournaments[0];
        tournament.caches = {};
        tournament.getMemberships = () => docs.memberships_doc;
        for (let m of docs.memberships_doc) {
            getMembership(m, docs);
        }
        tournament.getData = () => {
            return Utils.deepCopy(data);
        };
        tournament.getRuleFile = () => docs.files_doc.find(f => f.id === tournament.rule) || {};
        TournamentModel.convertTournamentDates(tournament);
        const {t_shirt, fee_info=[]} = tournament;
        fee_info.forEach(fi => {
            fi.fees.forEach(f => {
                if (!f.start_date_tz) {
                    f.start_date_tz = f.start_date;
                    f.end_date_tz = f.end_date;
                    f.start_date = convertDate(f.start_date);
                    f.end_date = convertDate(f.end_date);
                }
            });
        });

        if (t_shirt.expiration_date && !t_shirt.expiration_date_tz) {
            t_shirt.expiration_date_tz = t_shirt.expiration_date;
            t_shirt.expiration_date = convertDate(t_shirt.expiration_date);
        }

        tournament.isDivisionStandAlonePricing = (divivisionId) => {
            return tournament.fee_info.find(fi => fi.logic.length === 1 && fi.logic[0].divisions.includes(divivisionId));
        }

        tournament.getDivisionComboPricing = (ids) => TournamentModel.getDivisionComboPricing(ids, tournament);

        tournament.getBracketEntries = async () => {
            if (tournament.caches.bracketEntries) {
                return tournament.caches.bracketEntries;
            }
            let bracketEntries = await TournamentModel.getBracketEntries(tournament.id, isLocalServer);
            tournament.caches.bracketEntries = bracketEntries;
            return bracketEntries.map(b => {
                b = UserModel.setBracketEntry(b,
                    docs.tournaments,
                    docs.divisions_doc,
                    docs.memberships_doc,
                    bracketEntries);
                let membership = b.getMembership();
                if (membership) {
                    membership.getImage = () => docs.images_doc.find(i => i.ref === b.membership);
                }
                if (b.getDivision()) {
                    b.getDivision().getAgeGroup = () => {
                        return docs.age_groups_doc.find(ag => ag.id === b.getDivision().age_group);
                    }
                } 
                b.getRegistration = () => {
                    return tournament.getRegistrations().find(r => r.division === b.division);
                }
                b.getGym = () => {
                    let g = b?.getRegistration()?.getGym();
                    if (!g) {
                        let r = tournament.getRegistrations().find(r => r.membership === b.membership && r.getMembership().primary_gym);
                        if (r) {
                            g = docs.gyms_doc.find(g => g.id === r.getMembership().primary_gym);
                        }
                    }
                    return g;
                }
                return b;
            });
        };
        tournament.getRegistrations = (validOnly) => {
            if (!docs.registrations_doc)
                return [];
            let regs = docs.registrations_doc.map(r => {
                let membership = docs.memberships_doc.find(m => m.id === r.membership);
                if (membership) {
                    Object.assign(r, {
                        first_name: membership.first_name,
                        last_name: membership.last_name,
                        name: `${membership.first_name} ${membership.last_name}`
                    });
                }
                r.getMembership = () => membership;
                r.getCart = () => docs.carts_doc.find(c => c.id === r.cart);
                r.t_shirt = r.t_shirt || r.getCart()?.t_shirt;
                r.getOriginalUnitPrice = () => {
                    let item = r.getCart() && r.getCart()?.items.find(i => [r.division, r.combo_ids].includes(i.sku));
                    return item && (item.original_unit_price || item.unit_price);
                };
                r.getComboPrice = () => {
                    let item = r.getCart() && r.getCart()?.items.find(i => i.combo_ids && i.combo_ids.includes(r.division));
                    return item && (item.unit_price || item.original_unit_price);
                }
                r.getDivision = () => docs.divisions_doc.find(d => d.id === r.division);
                r.getGym = () => {
                    let g = docs.gyms_doc.find(g => g.id === r.gym);
                    if (g){
                        g.getTeams = () => g.teams.map(t => docs.teams_doc.find(td => td.id === t.id));
                    }
                    return g;
                }
                r.getTeam = () => docs.teams_doc.find(t => t.id === r.team);
                return r;
            });
            if (validOnly) {
                regs = regs.filter(r => r && r.getCart());
            }
            return regs;
        };

        tournament.getWeightClasses = () => docs.weight_classes_doc;
        tournament.getAgeGroups = () => docs.age_groups_doc;
        tournament.getRanks = () => docs.ranks_doc;

        tournament.setImagesDoc = images => Utils.updateArray(docs.images_doc, images, true);
        tournament.poolings = (tournament.poolings || []).filter(p => p.pool);
        tournament.poolings.forEach(p => {
                let registrations = tournament.getRegistrations().filter(r => {
                    return (r.pool || '').split('|').includes(p.pool);
                });
                Object.assign(p,  {
                    getBracketType: () => p.bracket_type,
                    getRegistrations: () => registrations,
                    id: p.pool,
                    name: p.pool,
                    getFinalRound: () => calculateTotalRounds(registrations.length, p.bracket_type)
                });
            });
        tournament.getImage = () => docs.images_doc.find(image => image && image.id && image.ref === tournament.id && image.image_type === IMAGE_TYPE.tournament);
        tournament.setImage = image => {
            let found = docs.images_doc.find(i => i.id === image.id);
            if (found){
                return Object.assign(found, image);
            }
            docs.images_doc.push(image);
        }
        tournament.getGroupScores = () => {
            let gs = docs.group_scores_doc.filter(gs => tournament.group_scores.includes(gs.id));
            if (gs.length === 0){
                gs.push({
                    name: 'Default',
                    id: 1,
                    divisions: docs.divisions_doc.map(d => d.id),
                    placement_point: {
                        first: 3,
                        second: 2,
                        third: 1
                    }
                });
            }
            return gs;
        };
        tournament.removeRegistration = id => {
            let reg = docs.registrations_doc.find(r => r.id === id);
            if (reg.combo_ids){
                let comboIds = reg.combo_ids.split(',').filter(r => r!==reg.division);
                docs.registrations_doc.filter(r => comboIds.includes(r))
                    .forEach(r => {
                        r.combo_ids = r.combo_ids.split(',').filter(r !== id).join(',');
                    });
            }
            docs.registrations_doc = docs.registrations_doc.filter(r => r.id !== id);
        }
        tournament.setRegistrations = (registrations, d) => {
            for (let registration of registrations){
                let r = docs.registrations_doc.find(r => r.id === registration.id);
                if (r) {
                    Object.assign(r, registration);
                }else {
                    registration.id && docs.registrations_doc.push(registration);
                }
            }

            if (d) {
                let schedules = TournamentModel.createSchedules(tournament, d);
                tournament.setSchedules(schedules);
            }
        };
        tournament.deleteDivision = (d) => {
            let index = tournament.divisions.findIndex(_d => _d.id===d.id);
            if (index > -1){
                tournament.divisions.splice(index, 1);
            }
        }
        tournament.addDivision = (d) => {
            tournament.divisions.push(d);
        }
        tournament.setDivision = div => {
            let d = docs.divisions_doc.find(_d => _d.id === div.id);
            Object.assign(d, div);
        };
        tournament.getDivisions = () => tournament.divisions.map(d => {
            d.isDivisionComboPricing = () => {
                let feeInfo = tournament.fee_info.find(fi => {
                    return fi.logic.length > 1 && fi.logic.find(l => l.divisions.includes(d.id));
                });
                if (feeInfo) {
                    let fee = TournamentModel.getValidFee(feeInfo.fees, new Date().getTime());
                    return fee.length > 0;
                }
            }
            d.isDivisionStandAlonePricing = () => {
                let feeInfo = tournament.fee_info.find(fi => {
                    return fi.logic.length === 1 && fi.logic[0].divisions.includes(d.id);
                });
                if (feeInfo) {
                    let fee = TournamentModel.getValidFee(feeInfo.fees, new Date().getTime());
                    return fee.length > 0;
                }
            }
            Object.assign(d, docs.divisions_doc.find(_d => _d.id === d.id));

            const {default_timer={}} = d;
            default_timer.timer = default_timer.timer || 180;
            default_timer.interstitial = default_timer.interstitial || 120;
            d.getTournament = () => tournament;
            d.getBracketType = () => {
                return tournament.divisions.find(division => division.id === d.id)?.bracket_type;
            }
            d.setBracketType = bracket_type => {
                let div = tournament.divisions.find(division => division.id === d.id);
                div && (div.bracket_type = bracket_type);
            };
            d.getSchedules = () => tournament.getSchedules().filter(s => s.division === d.id);
            d.getRegistrations = (isActiveOnly) => {
                return tournament.getRegistrations().filter(r => {
                    return r && (isActiveOnly? r.status === STATUS.Active:true) && [r.division, ...(r.pool||'').split('|')].includes(d.id);
                });
            };
            d.getPlacements = () => tournament.getPlacements().filter(p => tournament.id === p.division);
            d.isRegistered = session => session && d.getRegistrations().find(r => r.id === session.id);
            d.isRestricted = session => {
                if (!session)
                    return true;
                let age_group = docs.age_groups_doc.find(ag => ag.id === d.age_group);
                if (tournament.getBanMemberships().find(m => m.id === session.id))
                    return true;
                if (d.gender && d.gender !== session.gender)
                    return true;
                if (age_group){
                    let age = Utils.getAge(session.dob);
                    if (age < age_group.age_min || age_group.age_max <= age)
                        return true;
                }
            };
            d.getFee = (isNumber) => {
                return TournamentModel.getDivisionFee({division: d, isNumber, tournament, ts: new Date().getTime()});
            };
            d.getFees = (isNumber) => {
                return TournamentModel.getDivisionFee({division: d, isNumber, tournament, ts: new Date().getTime(), isList: true});
            }
            d.getRank = () => docs.ranks_doc.find(r => r.id === d.rank);
            d.getAgeGroup = () => {
                return docs.age_groups_doc.find(ag => ag.id === d.age_group)
            };
            d.getWeightClass = () => docs.weight_classes_doc.find(wc => wc.id === d.weight_class);
            d.getFinalRound = () => calculateTotalRounds(d.getRegistrations().length, d.bracket_type) - 1;
            return d;
        });
        tournament.getAvailableDivisions = () => {
            return [...tournament.getDivisions(), ...tournament.poolings];
        }
        tournament.setSchedules = schedules => {
            if (!docs.schedules) {
                docs.schedules = {};
            }
            if (schedules) {
                let list = docs.schedules[tournament.id];
                if (!list) {
                    list = docs.schedules[tournament.id] = [];
                }
                let dId = schedules && schedules.length > 0 && schedules[0].division;
                for (let i = list.length - 1; i >= 0; i--) {
                    let m = list[i];
                    if (m.division === dId) {
                        list.splice(i, 1);
                    }
                }
                schedules.forEach(s => {
                    let _s = TournamentModel.setScheduleCompetitors(s, docs);
                    _s && list.push(_s);
                });
            }
        };

        tournament.getSchedules = async () => {
            let bracketEntries = await tournament.getBracketEntries();
            let schedules = tournament.caches.scheduleEntries;
            if (!schedules) {
                schedules = await TournamentModel.getScheduleEntries(tournament.id, isLocalServer);
                schedules = schedules || [];
                tournament.caches.scheduleEntries = schedules;
            }
            let divisionMap = Utils.groupBy(bracketEntries, ['division']);
            let ss = [];
            Object.keys(divisionMap).forEach(d => {
                let div = tournament.getAvailableDivisions().find(td => td.id === d);
                if (div) {
                    let finalRound = div && div.getFinalRound();
                    let roundMap = Utils.groupBy(divisionMap[d], ['round']);
                    Object.keys(roundMap).forEach(r => {
                        if (r <= finalRound) {
                            let rEntries = roundMap[r].sort((a, b) => Utils.sorter(a, b, 'index'));
                            let entries = [];
                            rEntries.forEach((e, i) => {
                                if (i+1<rEntries.length-1 && rEntries[i + 1].index !== e.index + 1) {
                                    entries.push(null);
                                }else {
                                    entries.push(e);
                                }
                            });
                            for (let i = 0; i < entries.length; i += 2) {
                                let e1 = entries[i];
                                let e2 = entries[i + 1];
                                if (!e1 || !e2 || [e1 && e1.membership, e2 && e2.membership].includes(BYE)) {
                                    continue;
                                }
                                e1.getDivision = () => tournament.getAvailableDivisions().find(d => d.id === e1.division);
                                e2 && (e2.getDivision = () => tournament.getAvailableDivisions().find(d => d.id === e2.division));
                                let bracket_entry1 = e1 && TournamentModel.getBracketId(e1);
                                let bracket_entry2 = e2 && TournamentModel.getBracketId(e2);
                                let schedule = {
                                    id: `-${ss.length}`,
                                    bracket_entry1,
                                    bracket_entry2,
                                    tournament: entries[i].tournament,
                                    division: entries[i].division,
                                    getBracketEntry1: () => e1,
                                    getBracketEntry2: () => e2,
                                    getTournament: () => tournament,
                                    getDivision: () => tournament.getAvailableDivisions().find(td => td.id === d)
                                };
                                let s = schedules.find(s => s.bracket_entry1 === bracket_entry1
                                    && (s.bracket_entry2 || '') === (bracket_entry2 || ''));
                                s = s ? Object.assign(schedule, s) : schedule; 
                                ss.push(s);
                            }
                        }
                    });
                }
            });
            return ss;
        };

        tournament.addSchedule = (s) => {
            docs.schedules[tournament.id].push(s);
        }
        tournament.getGyms = () => {
            let gyms = docs.gyms_doc.map(g => {
                g.getImage = () => docs.images_doc.find(image => image && image.id && image.ref===g.id && image.image_type===IMAGE_TYPE.gym);
                g.getIcon = () => docs.images_doc.find(image => image && image.id && image.ref===g.id && image.image_type===IMAGE_TYPE.gym_icon);
                return g;
            })

            gyms = gyms.map(g => {
                let gymMembers = tournament.getRegistrations()
                    .filter(r => r)
                    .map( r => {
                        try{
                            let gym = r.getMembership().getGym();
                            return gym && gym.id === g.id;
                        }catch(e) {
                            console.log(e)
                        }
                    }).filter(r => r);
                g.standings = [];
                for (let gs of tournament.getGroupScores()) {
                    g.standings.push(TournamentModel.calculateStandings(gymMembers, gs, tournament.poolings));
                }
                return g;
            });
            return gyms;
        };
        tournament.addTeam = (team) => {
            docs.teams_doc.push(team);
        };
        tournament.getTeams = () => {
            if (!docs.teams){
                docs.teams = {};
            }
            if (docs.teams[tournament.id]){
                return docs.teams[tournament.id];
            }
            let teams = docs.teams_doc.map(t => {
                t.getImage = () => docs.images_doc.find(image => image && image.id && image.ref===t.id && image.image_type===IMAGE_TYPE.team);
                t.getIcon = () => docs.images_doc.find(image => image && image.id && image.ref===t.id && image.image_type===IMAGE_TYPE.team_icon);
                return t;
            })

            teams = teams.map(t => {
                let teamMembers = tournament.getRegistrations()
                    .filter(r => {
                        if (r && r.getMembership) {
                            let team = r.getTeam();
                            return team && team.id === t.id;
                        }
                    });
                t.standings = [];
                for (let gs of tournament.getGroupScores()) {
                    t.standings.push(TournamentModel.calculateStandings(teamMembers, gs, tournament.poolings));
                }
                return t;
            });
            return docs.teams[tournament.id] = teams;
        };
        tournament.getAdmins = () => docs.memberships_doc.filter(m => {
            return tournament.admins.find(a => a.id===m.id);
        });
        tournament.getOwner = () => docs.memberships_doc.find(m => m.id===tournament.owner);
        tournament.getVolunteers = () => docs.memberships_doc.filter(m => {
            let admin = tournament.volunteers.find(a => a.id===m.id);
            admin.getImage = () => docs.images_doc.find(i => i.ref === m.id);
            return admin;
        });
        tournament.getLeague = () => {
            let league = docs.leagues_doc.find(m => m.id = tournament.league);
            if (league){
                league.getTeams = () => docs.teams_doc.filter(t => t.league === league.id);
            }
            return league;
        };
        tournament.getMessages = () => docs.messages_doc.filter(m => m.from === tournament.id);
        tournament.getBanMemberships = () => TournamentModel.getBans(tournament.id, docs.memberships_doc, docs.bans_doc, BAN.membership);
        tournament.getBanTeams = () => TournamentModel.getBans(tournament.id, docs.teams_doc, docs.bans_doc, BAN.team);
        tournament.getBanGyms = () => TournamentModel.getBans(tournament.id, docs.gyms_doc, docs.bans_doc, BAN.gym);
        tournament.getPromos = () => {
            let promoIds = docs.promos_doc.map(p => p.id);
            return docs.promos_doc.filter(m => promoIds.includes(m.id));
        };
        tournament.addPromo = d => docs.promos_doc.push(d);
        tournament.deletePromo = id => Utils.removeArrayItem(docs.promos_doc, {id});
        tournament.getCurrency = () => {
            return tournament.currency;
        };
        tournament.addRegistration = (r, membership) => {
            if (r) {
                let id = '-1';
                for (let i=2; i<1000; i++){
                    if (!docs.registrations_doc.find(_r => _r.id === id)){
                        break;
                    }
                    id = `-${i}`;
                }
                let cart = {
                    id: r.id,
                    status: 'A',
                    created_on: new Date().getTime,
                    ref: tournament.id,
                    ref_type: 'tournament',
                    ref_display: tournament.name,
                    items: [],
                    is_waiver: true
                };
                r.cart = r.id;
                r.getCart = () => cart;
                docs.registrations_doc.push(r);
                docs.carts_doc.push(cart);
                r.getMembership = () => membership;
            }
            if (membership && !docs.memberships_doc.find(m => m.id ===membership.id)) {
                docs.memberships_doc.push(membership);
            }
        }
        tournament.deleteRegistration = (r) => {
            let index = docs.registrations_docs.findIndex(_r => _r.id === r.id);
            if (index > -1) {
                docs.registrations_docs.splice(index, 1);
            }
        }

        tournament.canRegister = membership => {
            if (membership){
                let message = '';
                let teams = docs.teams_doc.map(t => t.id);
                let isRestrictTeam = false;
                let requireTeams = (tournament.registration_requirements.restricted_to_teams || []).filter(t => t);
                if (requireTeams.length > 0) {
                    teams = tournament.registration_requirements.restricted_to_teams;
                    isRestrictTeam = true
                }
                let isTeam = Utils.intersection(teams, membership.getTeams().map(t => t.id)).length > 0;
                if (!isTeam){
                    message = isRestrictTeam ? <div className="registration-error">
                            This tournament is restricted to the following team(s):
                            <ul className="team-list">
                                {tournament.getTeams().map(t => <li>{t.name}</li>)}
                            </ul>
                        </div> :
                        <div className="registration-error">
                            Registration is not available.  You are not associated with any team or gym for this league.
                            <h3>Associate Teams</h3>
                            <ul className="team-list">
                                {tournament.getTeams().map(t => <li>{t.name}</li>)}
                            </ul>
                            <h3>Associate Gyms</h3>
                            <ul className="gym-list">
                                {tournament.getGyms().map(t => <li>{t.name}</li>)}
                            </ul>
                        </div>;
                }
                return {
                    team: isTeam,
                    gym: Utils.intersection(docs.gyms_doc.map(g => g.id), membership.gyms.map(g =>g.id)).length > 0,
                    message
                }
            }
        };
        tournament.getPlacements = async (divisionId) => {
            let placements = [];
            let bes = await tournament.getBracketEntries();
            bes.filter(be => !divisionId || be.division === divisionId).forEach(be => {
                if (!isNaN(parseInt(be.place))){
                    placements.push(be);
                }
            });  
            return placements;
        };
        tournament.getPoints = () => {
            const sortStandings = (list, gs) => {
                function calPoints(l){
                    const {points, firsts, seconds, thirds} = l.standings.find(p => p.gs.id===gs.id);
                    return points * 1000 + firsts * 100 + seconds * 10 + thirds;
                }
                return list.sort((a, b) => {
                    return calPoints(b) - calPoints(a);
                });
            }
            let results = [];
            ['getTeams', 'getGyms'].forEach(getFunc => {
                tournament.getGroupScores().map(gs => {
                    let list = tournament[getFunc]().filter(t => t.standings.find(s => s.gs.id === gs.id));
                    let place=0, prevPoints = -1;
                    sortStandings(list, gs).forEach(entry => {
                        const {firsts, seconds, thirds, points} = entry.standings.find(s => s.gs.id === gs.id);
                        let currentPoints = points*1000 + firsts*100 + seconds*10 + thirds;
                        if (prevPoints !== currentPoints){
                            place++;
                        }
                        prevPoints = currentPoints;
                        results.push({
                            id: entry.id,
                            type: getFunc === 'getTeams' ? 'Teams' : 'Gyms',
                            place,
                            group_score: gs.name,
                            name: entry.name,
                            firsts, seconds, thirds, points,
                            getImage: () => entry.getImage && entry.getImage()?.data.join('')
                        });
                    });

                });
            });
            return results.sort((a, b) => a.place - b.place);
        }
        return tournament;
    }

    static async getTournament(id) {
        if (!id) {
            return;
        }
        const response = await RequestTournament.getTournamentRequest(id);
        let data = RequestUtils.getResponseData(response);
        return TournamentModel.formatResult(data);
    }

    static async getTournamentInfo(id) {
        if (!id) {
            return;
        }
        const response = await RequestTournament.getTournamentInfo(id);
        return RequestUtils.getResponseData(response);
    }

    static async getBusinessInfo(id) {
        if (!id) {
            return;
        }
        let response = await RequestTournament.getBusinessInfo(id);
        let businessInfo = RequestUtils.getResponseData(response);

        if (businessInfo) {
            response = await RequestCart.getPromos({ ref: id });
            let promos = RequestUtils.getResponseData(response);
            businessInfo.getPromo = () => promos;
        }
        return businessInfo;
    }
}

export default TournamentModel;

export class Bracket2Utils {
    static getRoundLength(regCount) {
        let length = regCount;
        if (regCount % 4 !== 0) {
            length += regCount % 4;
        }
        let temp = length;
        while (temp > 0) {
            temp = temp / 2;
            length += temp;
        }

        return length;
    }
    static getBracketSize(regCount) {
        let baseNodes = 2;
        let i = 0;
        if (regCount > 2) {
            for (i = 2; i < 16; i++) {
                if (Math.pow(regCount, 1 / i) <= 2) {
                    baseNodes = Math.pow(2, i);
                    break;
                }
            }
        }
        return baseNodes;
    }

    static getNextRound({ round, index, bracket_type, isLoser, isThirdPlace }) {
        let params = { round, index, bracket_type, isLoser, isThirdPlace };
        if (bracket_type === BRACKET.Round_Robin) {
            return {
                ...params,
                round: round++,
                isLoser: false
            }
        }
        if (isLoser) {
            return {
                ...params,
                index: round % 2 == 0 ? Math.floor(index / 2) : index,
                round: round++,
            }
        }

        return {
            round: round++,
            index: Math.floor(index / 2)
        }
    }

    static getBracketStructure(entries) {
        let bracket = Utils.groupBy(entries, ['isLoser']);
        let loser = Utils.groupBy(bracket[true], ['round']);
        let main = Utils.groupBy(bracket[false], ['isThird']);
        let third = Utils.groupBy(main[true], 'round');
        main = Utils.groupBy(main[false], 'round');

        return {
            main: Utils.groupBy(main, ['index']),
            loser: Utils.groupBy(loser, ['index']),
            third: Utils.groupBy(third, ['index']),
        }
    }
}

function getMembership(membership, docs) {
    membership.getName = () => getMembershipName(membership);
    membership.getImage = () => docs.images_doc.find(i => i && i.id && i.ref === membership.id);
    membership.getGyms = () => {
        let gymIds = [membership.primary_gym, membership.gyms.map(g => g.id)].filter(g => g);
        return docs.gyms_doc.filter(g => gymIds.includes(g.id));
    };
    let teamIds = membership.getGyms().map(g => g.teams).flat().map(t => t.id);
    membership.getTeams = () => {
        return teamIds && docs.teams_doc.filter(t => teamIds.includes(t.id));
    };
}
