import * as React from "react";
import PropTypes from "prop-types";
import { alpha, ThemeProvider } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { IonIcon, IonSpinner } from "@ionic/react";
import { useState } from "react";
import TextFormInput from "./TextFormInput";
import { createOutline as editIcon } from "ionicons/icons";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Utils from "../../serverUtils/Utils";
import { default as DeleteIcon } from "@mui/icons-material/Delete";
import { default as SaveIcon } from "@mui/icons-material/Save";
import { default as AddIcon } from "@mui/icons-material/AddCircleOutline";
import { default as ClearIcon } from "@mui/icons-material/HighlightOffOutlined";
import { default as FilterIcon } from "@mui/icons-material/FilterAlt";
import { default as AcsIcon } from "@mui/icons-material/South";
import { default as DescIcon } from "@mui/icons-material/North";
import { default as NoSortIcon } from "@mui/icons-material/ImportExport";
import { default as ResetIcon } from "@mui/icons-material/HighlightOff";

import Theme from "./Theme";
import "./TableFormInput.scss";
import { divScroller } from "../Form/Form";
import FilterChips from "../Filters/FilterChips";

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  try {
    array = array?.sort(comparator);
  } catch (e) {
    console.log("stableSort", e);
  }
  return array;
}

export const ToolbarButtons = React.forwardRef(({ doAdd, doSave, doDelete, buttons, indicator: _indicator }, ref) => {
  const [isDeleting, setIsDeleteing] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [indicator, setIndicator] = useState({});
  React.useEffect(() => _indicator && setIndicator(_indicator), [_indicator]);
  React.useImperativeHandle(ref, () => ({
    setIndicator: v => setIndicator({...indicator, ...v}),
  }));
  const handleBusy = (e, fn, busyFn) => {
    e.stopPropagation();
    if (busyFn || fn.toString().includes('async') || fn.toString().includes('function(){return e.apply(this,arguments)}')){
      busyFn && busyFn(true);
      const doCall = async () => {
        try{
          await fn(e);
        }finally {
          busyFn && busyFn(false);
        }
      }
      return doCall();
    }
    fn(e);
  }
  return <div className="ToolbarButtons">
    {doAdd && <Tooltip title="Add a new entry">
      <button className={`icon_button ${indicator.add || ''}`} onClick={e => handleBusy(e, doAdd, setIsAdding)}>
        <AddIcon /> {isAdding && <IonSpinner name="circles" className="spinner"/>}
      </button>
    </Tooltip>}
    {doSave && <Tooltip title="Save">
      <button className={`icon_button ${indicator.save || ''}`} onClick={e => handleBusy(e, doSave, setIsSaving)}>
        <SaveIcon /> {isSaving && <IonSpinner name="circles" className="spinner"/>}
      </button>
    </Tooltip>}
    {doDelete && 
      <Tooltip title="Delete selected entries">
        <button className={`icon_button ${indicator.delete || ''}`} onClick={e => handleBusy(e, doDelete, setIsDeleteing)} >
          <DeleteIcon /> {isDeleting && <IonSpinner name="circles" className="spinner"/>}
        </button>
      </Tooltip>}
    {buttons}
  </div>;
});

export const IndexCell = ({ index, isItemSelected, labelId }) => {
  return <div style={{ display: 'flex', alignItems: 'center', paddingRight: 20 }}>
    <Checkbox
      color="primary"
      checked={isItemSelected}
      inputProps={{
        "aria-labelledby": labelId,
      }}
    />
    <span>{index}</span>
  </div>
}

export const CellRenderer = ({
  id,
  align,
  display = "",
  isItemSelected,
  isCheckBox,
}) => {
  return (
    <TableCell 
      component="th"
      id={id}
      scope="row"
      padding="none"
      align={align || "left"}
    >
      <div style={{
        marginRight: 20,
        display: 'flex',
        flexWrap: id === 'index' ? 'nowrap' : 'wrap',
        alignItems: 'center',

      }}>
        {id === 'index' &&
          <Checkbox disabled={!isCheckBox}
            color="primary"
          checked={isCheckBox ? isItemSelected : false}
          inputProps={{
            "aria-labelledby": id,
          }}
        />
        }

        {display}
      </div>
    </TableCell>
  );
};

export const TableEditInput = ({
  value,
  type,
  setValue,
  label,
  updateRowData,
  setCell,
  placeholder,
}) => {
  let onChange = (e) => {
    setValue(e.target.value);
    updateRowData && updateRowData();
  };

  let inputProps = {
    onChange: onChange,
    onKeyUp: (e) => {},
  };

  if (type === "text_area") {
    return (
      <TextareaAutosize
        label={label}
        minRows={3}
        placeholder={placeholder || ""}
        value={value}
        onChange={onChange}
      />
    );
  }
  let isFloat = type === "float";
  return (
    <TextFormInput
      label={label}
      type={isFloat ? "" : type}
      placeholder={placeholder || ""}
      value={isFloat ? Utils.toFloatString(value) : value}
      inputProps={inputProps}
    />
  );
};

export const TableCellEditable = ({
  align,
  setValue,
  updateRowData,
  setEditable,
  setCell,
  cell,
  field,
  index,
  row,
  label,
  type,
  value,
}) => {
  setEditable =
    setEditable || ((f) => cell && cell.field === f && cell.index === index);
  value = value || row[field];
  return (
    <TableCell
      align={align || "center"}
      onClick={(e) => {
        e.stopPropagation();
        setCell({ index, row, field: field });
      }}
    >
      {setEditable(field) ? (
        <TableEditInput
          label={label}
          value={value}
          setValue={setValue}
          updateRowData={updateRowData}
          setCell={setCell}
          type={type}
        />
      ) : (
        TableEditableIcon(type === "float" ? Utils.toFloatString(value) : value)
      )}
    </TableCell>
  );
};

export const TableEditableIcon = (cell) => (
  <div className="display">
    <IonIcon icon={editIcon} />
    {cell}
  </div>
);

const EnhancedTableHead = React.forwardRef((props, ref) => {
  const {
    onSelectAllClick,
    orderBys,
    numSelected,
    rowCount,
    onRequestSort,
    headCells,
    isEditable,
    setFilter,
    id,
    onFilter,
    isLoading,
  } = props;
  const [headCellsSelect, setHeadCellsSelect] = useState([]);
  const [isHover, setIsHover] = useState();
  const [sortClick, setSortClick] = useState();
  React.useEffect(() => {
    if (!isLoading && sortClick) {
      let found = headCells.find(hc => hc.id === sortClick);
      found.sorterIconRef.current.style.display = 'none';
    } 
  }, [isLoading]);

  const filterInputTimeoutRef = React.useRef();
  React.useEffect(() => {
    // headCellSelect && setFilter(headCellSelect);
  }, [headCellsSelect, rowCount]);

  React.useEffect(() => {
    console.log('EnhancedTableHead')
  }, []);

  const createSortHandler = (property) => (event) => {
    event.stopPropagation();
    setSortClick(property);
    let found = headCells.find(hc => hc.id === property);
    if (found && found.sorterIconRef.current) {
      found.sorterIconRef.current.style.display = '';
      setTimeout(() => onRequestSort(event, property), 1000);
    }
  };

  React.useImperativeHandle(ref, () => ({
    setHeadCellsSelect, headCellsSelect,
  }));

  const handleLabelClick = (e, headCell) => {
    e.stopPropagation();
    if (!headCell.disable_filter) {
      headCellsSelect.push({headCell});
      setHeadCellsSelect(headCellsSelect);
      setFilter(headCellsSelect, true);
    }
  };

  const findHeadCellSelect = (headCell, isFilterString) => {
    let hc = headCellsSelect.find(hc => hc.headCell.id === headCell.id);
    if (isFilterString) {
      return hc.filterString || '';
    }
    return hc;
  }

  const getSortDirection = (h) => {
    let found = orderBys.find(ob => ob.property === h);
    if (found) {
      return found.direction;
    }
    return 0;
  }

  return (
    <TableHead className="TableHead">
      <TableRow>
        {isEditable && (
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              onClick={e => {
                e.stopPropagation();
                onSelectAllClick && onSelectAllClick(e);
              }}
              inputProps={{
                "aria-label": "select all desserts",
              }}
            />
          </TableCell>
        )}
        {headCells.map((headCell, i) => {
          const sorterIconRef = React.createRef();
          headCell.sorterIconRef = sorterIconRef;
          let sortDir = getSortDirection(headCell[id]);
          const SortIcon = () => {
            if (sortDir === 1) {
              return <AcsIcon className="AcsIcon"/>;
            } 
            if (sortDir === -1) {
              return <DescIcon className="DescIcon"/>;
            }
            if (!headCell.disable_filter && !sortDir) {
              return <NoSortIcon className="NoSortIcon"/>;
            }

            return '';
          }
          return <TableCell
            style={
              findHeadCellSelect(headCell)
                ? { minWidth: 200 }
                : { width: headCell.width }
            }
            key={i}
            align={
              headCell.align
                ? headCell.align
                : headCell.numeric
                ? "right"
                : "center"
            }
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            <div className="TableSortHeader"
              onClick={
                !headCell.disable_filter? createSortHandler(headCell[id]):undefined
              }
            >
              {!headCell.disable_filter && findHeadCellSelect(headCell) ? (
                <div className="filter-input">
                  <TextFormInput
                    value={findHeadCellSelect(headCell, true)}
                    type="search"
                    label={findHeadCellSelect(headCell).headCell.label}
                    onChange={value => {
                      if (value === undefined) {
                        let filters = headCellsSelect.filter(hc => hc.headCell.id !== headCell.id);
                        setFilter(filters);
                        setHeadCellsSelect(filters);
                        onFilter(filters);
                        return;
                      }
                      let filter = headCellsSelect.find(hc => hc.headCell.id === headCell.id);
                      filter.filterString = value;
                      filterInputTimeoutRef.current && clearTimeout(filterInputTimeoutRef.current);
                      filterInputTimeoutRef.current = setTimeout(() => {
                        setHeadCellsSelect(headCellsSelect);
                        setFilter(headCellsSelect);
                        onFilter(headCellsSelect);
                      }, [1000]);
                    }}
                  />
                  
                </div>
              ) : (
                <span 
                  className={`header-label ${orderBys.find(ob => ob.property===headCell.id)? 'sorting':''}`}
                  onClick={(e) => {
                    handleLabelClick(e, headCell);
                  }}
                  onMouseEnter={() => {
                    !headCell.disable_filter && setIsHover(headCell[id]);
                  }}
                  onMouseLeave={() => setIsHover(null)}
                >
                  {headCell[id] && isHover === headCell[id] && !headCell[id].disable_filter && <FilterIcon style={{width: 16, height: 16, opacity: .6}}/>}
                  {headCell.label}
                </span>
              )}
              <SortIcon className="SortIcon"/>
              <IonSpinner style={{display: 'none'}} name="circles" className="spinner" ref={sorterIconRef}/>
            </div>
          </TableCell>;
        })}
      </TableRow>
    </TableHead>
  );
});

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  rowCount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { numSelected, toolbarButtons, onSelectedUpdate } = props;
  const [refresh, setRefresh] = useState(false);
  React.useEffect(() => {
    setRefresh(!refresh);
  }, [onSelectedUpdate]);

  return (
    <Toolbar className="EnhancedTableToolbar"
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 || onSelectedUpdate ? (
        <Typography className="message"
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {onSelectedUpdate? onSelectedUpdate : `${numSelected} selected`}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
            {props.title || ''}
        </Typography>
      )}

      {numSelected > 0 &&
        props.onToolBarDelete && (
          <Tooltip title="Delete">
            <IconButton
              onClick={(e) => {
                props.onToolBarDelete(e);
              }}
            >
            <DeleteIcon />
            </IconButton>
          </Tooltip>
        )}
      {toolbarButtons && toolbarButtons()}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export class TableUtils {
  static isComparable(a, b) {
    if (!a && !b) {
      return 0;
    }
    if (a && !b) {
      return -1;
    }
    if (!a && b) {
      return 1;
    }
  }

  static compareString(_a, _b) {
    _a = _a ? _a.toString() : "";
    _b = _b ? _b.toString() : "";
    if (_b < _a) {
      return -1;
    }
    if (_b > _a) {
      return 1;
    }
    return 0;
  }

  static compare(a, b, orderBy, cb) {
    let r = TableUtils.isComparable(a, b);
    if (r !== undefined) {
      return r;
    }

    let _a, _b;
    r = cb && cb(a, b, orderBy);
    if (!isNaN(r)) {
      return r;
    }
    if (r) {
      _a = r.a;
      _b = r.b;
    } else {
      _a = a[orderBy];
      _b = b[orderBy];
    }

    return TableUtils.compareString(_a, _b);
  }
}

export const setTableSortText = (row, txt, field) => {
  row[`getSortText_${field}`] = () => txt;
  return txt;
}

const DEFAULT_ROWS_PER_PAGE = [5, 10, 25, 50, 100];
const TableFormInput = React.forwardRef(
  (
    {
      name,
      id = "id",
      data,
      headCells,
      renderTRow,
      isEditable,
      onToolBarDelete,
      onFilter, onFilterData,
      onSelectedUpdate, onSelectedUpdate1,
      toolbarButtons, toolbarButtons1,
      title, title1,
      isDense,
      comparator,
      getFilterData,
      onClick,
      onSelect,
      className='',
      showFilterChips=false,
      pageSize=0,
    },
    ref
  ) => {
    const [refresh, setRefresh] = useState(false);
    const [rowData, setRowData] = useState([]);
    const [orderBys, setOrderBys] = React.useState([]);
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState();
    const [dense, setDense] = React.useState(isDense);
    const [rowsPerPage, setRowsPerPage] = React.useState(pageSize);
    const [rowsPerPageOptions, setRowsPerPageOptions] = React.useState([{value: 0, label: '0'}]);
    const [headerFilter, setHeaderFilter] = React.useState();
    const [isLoading, setIsLoading] = useState(false);
    const [isGotoLastPage, setIsGotoLastPage] = useState(false);
    const [filterChips, setFilterChips] = useState([]);
    const divRef = React.useRef();
    const headerRef = React.useRef();
    const tablePaginationRef = React.useRef();
    const [initRowsPerPage, setInitRowPerPage] = useState();

    const savePos = (p=page, rpp=rowsPerPage) => {
      try{
        if (!initRowsPerPage || (headerRef.current && headerRef.current.headCellsSelect && headerRef.current.headCellsSelect.length > 0)) {
          return;
        }
        if (!isNaN(parseInt(p)) && !isNaN(parseInt(rpp))) {
          let pos = {
            page: p || 0,
            rowsPerPage: rpp || pageSize || 0
          }
          Utils.cookie(`TableFormInput_${name}`, encodeURIComponent(JSON.stringify(pos)));
        }
      }catch(e) {
        console.log(e);
      }
    }

    const initRowsPerPageOptions = () => {
      const options = DEFAULT_ROWS_PER_PAGE;
      if (rowData) {
        const getPageSize = (size, i) => {
          if (rowData.length === 0){
            return;
          }
          if (rowData.length > size) {
            return {label: size.toString(), value: size};
          }
        };
        const rPerPageOptions = [
          ...options.map((o, i) => getPageSize(o, i)), 
          {label: 'All', value: -1}
        ].filter(o => o);
        setRowsPerPageOptions(rPerPageOptions);
      }
    }

    const initPageRowPerPage = () => {
      let p, rpp;
      if (name) {
        let pos = Utils.cookie(`TableFormInput_${name}`);
        if (pos) {
          try{
            pos = JSON.parse(decodeURIComponent(pos));
            if (isNaN(pos.page) || pos.page < 0) {
              pos.page = 0;
            }
            if (isNaN(pos.rowsPerPage) || pos.rowsPerPage === 0){
              pos.rowsPerPage = DEFAULT_ROWS_PER_PAGE[0];
            }
            rpp = data.length < pos.rowsPerPage? DEFAULT_ROWS_PER_PAGE[0] : pos.rowsPerPage;
            let totalPage = Math.ceil(data.length/rpp);
            p = pos.page >= totalPage? 0 : pos.page;
            return setInitRowPerPage({page: p, rowsPerPage: rpp});
          }catch(e) {
            console.log(e);
          }
        }
        setInitRowPerPage({page: 0, rowsPerPage: 5});
      }
    }

    React.useEffect(() => {
      initPageRowPerPage();
    }, []);

    const getFilterChips = () => headCells.map(hc => ({label: hc.label, value: hc.id}));
    React.useEffect(() => {
      let keys = Utils.cookie(getFilterChipsCookieKey());
      let fc = getFilterChips();
      if (keys) {
        try {
          keys = JSON.parse(decodeURIComponent(keys));
          keys = keys.map(k => k.value);
          fc = fc.filter(fc => keys.length===0 || keys.includes(fc.value));
        }catch(e){
          console.log(e);
        }
      }
      setFilterChips(fc);
    }, [headCells]);

    React.useEffect(() => {
      // setFilter(headerFilter);
      try {
        const {page: p, rowsPerPage: rpp} = initRowsPerPage || {page: 0, rowsPerPage: 5};
        setRowsPerPage(rpp);
        setPage(p); 
        // savePos(p, rpp);
        initRowsPerPageOptions();
      }finally {
        setIsLoading(false);
      }
    }, [rowData]);

    React.useEffect(() => {
      setFilter(headerFilter);
      setIsLoading(true);
      setRowData(data);
    }, [data]);

    React.useEffect(() => {
      pageSize !== 0 && setRowsPerPage(pageSize);
    }, [pageSize]);

    React.useEffect(() => {
      if (isGotoLastPage) {
        let p = rowsPerPage===-1? 0:(Math.ceil(rowData.length / rowsPerPage) - 1);
        if (p < 0) {
          p = 0;
        }
        setPage(p);
        savePos(p);
        tablePaginationRef.current.scrollIntoView({ behavior: 'smooth' });
        return setIsGotoLastPage(false);
      }
    }, [isGotoLastPage]);

    React.useEffect(() => {
      const multiColumnSort = (sortCriteria) => {
        const getData = (headCell, filterString) => {
          if (getFilterData) {
            let displayString = getFilterData({headCell, filterString}, rowData, isFilter, isRange);
            if (displayString) {
              return displayString
            }
          }
        }
        return rowData.sort((a, b) => {
          for (let i = 0; i < sortCriteria.length; i++) {
            const [key, direction] = sortCriteria[i];
            let aValue = a[key] || '';
            let bValue = b[key] || '';
            if (comparator){
              let r = comparator(a, b, key);
              if (r) {
                aValue = r.a;
                bValue = r.b;
              }
            }
      
            if (typeof aValue === 'string' && typeof bValue === 'string'){
              aValue = aValue.toLowerCase();
              bValue = bValue.toLowerCase();
            }
            if (aValue < bValue) {
              return direction === 1 ? -1 : 1;
            } else if (aValue > bValue) {
              return direction === 1 ? 1 : -1;
            }
          }
          return 0; 
        });
      }
      let sortCriteria = orderBys.filter(ob => ob.direction !== 0).map(ob => [ob.property, ob.direction]);
      if (sortCriteria.length > 0) {
        let sdata = multiColumnSort(sortCriteria);
        setIsLoading(false);
        setRowData([...sdata]);
        // setIsLoading(true);
        // setTimeout(() => {
        //   setRowData(sdata);
        //   setIsLoading(false);
        // }, 10);
        
      }
    }, [orderBys]);

    React.useEffect(() => {
      setIsLoading(false);
    }, [rowsPerPage]);

    const isWordsInString = (txt, word) => {
      return Utils.isWordsInString(txt, word);
    }

    const isFilter = (words, getCellDisplayString, isNumber) => {
      for (let w of words) {
        let s = getCellDisplayString(true) || '';
        if (isNumber){
          return parseInt(s) === parseInt(w);
        }else if (isWordsInString(s, w)) {
          continue;
        }
        return false;
      }
      return true;
    }

    const isRange = (minMax, getCellDisplayNumber) => {
      const getMinMax = () => {
        let range = minMax.split('-');
        let min = parseInt(range[0]);
        let max = parseInt(range.length>1? range[1]:range[0]);
        if (isNaN(min) || isNaN(max)){
            return;
        }
        return [min, max];
      }
      minMax = getMinMax();
      if (!minMax){
        return;
      }
      let value = getCellDisplayNumber();
      return value && minMax[0] <= value && value <= minMax[1];
    }

    const setFilter = (filters) => {
      if (!filters || filters.length === 0) {
        setIsLoading(true);
        return setRowData(data);
      }
      try {
        let filterData = [...data];
        for (let filter of filters) {
          const { headCell, filterString } = filter;
          if (!filterString) {
            continue;
          }
          let words = Utils.separateWords(filterString);
          const doFilter = () => {
            return filterData.filter(d => {
                let sortTextFunc = d[`getSortText_${headCell[id]}`];
                let txt = (sortTextFunc && sortTextFunc()) || d[headCell[id]];
                for (let w of words) {
                  if (txt) {
                    if (isWordsInString(txt, w)) {
                      continue;
                    }
                  } 
                  return false;
                }
                return true;
              }
            );
          };
          if (!getFilterData) {
            filterData = doFilter();
          } else {
            let extFilterData = getFilterData({...filter}, filterData, isFilter, isRange);
            if (extFilterData) {
              filterData = extFilterData;
            }else {
              filterData = doFilter();
            }
          }
        }
        setIsLoading(true);
        setRowData(filterData);
        onFilterData && onFilterData(filterData, filters, orderBys);
      } finally {
        setPage(0);
        savePos();
      }
    };

    const gotoPage = (page = 0) => {
      setPage(page = page<0 ? 0:page);
      savePos(page);
    };
    const gotoLastPage = () => {
      setIsGotoLastPage(true);
    };

    const findPage = (funcIsFound) => {
      let index = rowData.findIndex(r => {
        return funcIsFound(r);
      });
      let page = Math.floor((index < 0 ? 0 : index) / rowsPerPage);
      gotoPage(page);
      index > -1 && setSelected([rowData[index][id]]);
    }

    React.useImperativeHandle(ref, () => ({
      findPage,
      gotoPage,
      gotoLastPage,
      selected,
      setSelected,
      setFilter, setOrderBys,
      headerRef,
      rowData,
      refresh: () => setRefresh(!refresh),
    }));

    const handleRequestSort = (event, property) => {
      // const isAsc = orderBy === property && order === "asc";
      // setOrder(isAsc ? "desc" : "asc");
      // setOrderBy(property);
      let ob = orderBys.find(ob => ob.property === property);
      if (ob) {
        if (ob.direction !== 1) {
          ob.direction = 1; //asc
        }else {
          ob.direction = -1; //desc
        }
      }else if (orderBys.length === 0 || event.shiftKey) {
        orderBys.push({property, direction: 1});
      }else {
        return setOrderBys([{property, direction: 1}]);
      }

      setOrderBys([...orderBys]);
    };

    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        let newSelected = rowData.map((n) => n[id]);
        setSelected(newSelected);
        onSelect && onSelect(newSelected);
      }else {
        setSelected([]);  
        onSelect && onSelect([]);
      }
    };

    const handleClick = (event, name) => {
      let newSelected = [...selected];
      const selectedIndex = newSelected.indexOf(name);
      let isCheck = event.target.checked;
      if (isCheck){
        selectedIndex===-1 && newSelected.push(name);
      }else {
        newSelected = newSelected.filter(s => s !== name);
      }
      
      setSelected(newSelected);
      onSelect && onSelect(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage = newPage<0? 0:newPage);
      savePos(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setIsLoading(true);
      let rpp = parseInt(event.target.value, 10);
      setRowsPerPage(rpp);
      let p = 0;
      setPage(p);
      savePos(p, rpp);
      setInitRowPerPage({page: p, rowsPerPage: rpp});
    };

    const handleChangeDense = (event) => {
      setDense(event.target.checked);
    };

    const isSelected = (name) => {
      return selected && selected.indexOf(name) !== -1;
    };

    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rowData.length) : 0;

    const getHeaderLabel = ob => {
      let found = headCells.find(hc => hc.id === ob.property);
      return found && found.label;
    }

    const getFilterChipsCookieKey = () => `TableFormInput_${name}.HeaderFilterChips`;
    const HeaderFilterChips = () => {
      return <div className="FilterChips-wrapper">
        <div className="buttons">
          <button className="button small_button" 
            onClick={e => {
              e.stopPropagation();
              setFilterChips(getFilterChips());
              showFilterChips && Utils.deleteCookie(getFilterChipsCookieKey);
            }}>Show All</button>
          <button className="button small_button"
            onClick={e => {
              e.stopPropagation();
              setFilterChips([]);
              showFilterChips && Utils.deleteCookie(getFilterChipsCookieKey);
            }}>Clear</button>
        </div>
        <FilterChips 
          filters={getFilterChips()}
          activeFilters={filterChips}
          onClick={fc => {
            let found = filterChips.find(f => f.value === fc.value);
            let filters;
            if (found) {
              filters = filterChips.filter(f => f.value !== fc.value);
            }else {
              filters = [...filterChips, fc];
            }
            setFilterChips(filters);
            showFilterChips && Utils.cookie(getFilterChipsCookieKey(), encodeURIComponent(JSON.stringify(filters)));
          }}
        />
      </div>;
    }

    const RowDatas = () => {
      let ts = new Date().getTime();
      try{
        return rowData
          .slice(page * rowsPerPage, rowsPerPage===-1? rowData.length: (page * rowsPerPage + rowsPerPage))
          .map((row, index) => {
            return renderTRow({
              row,
              isSelected,
              index: page * rowsPerPage + index,
              handleClick,
              updateRowData: () => {
                setIsLoading(true);
                setRowData([...data]);
              },
              selected,
              setSelected,
              setFilter: () => {
                setFilter(headerFilter);
              },
              rowData,
              filterChips: getFilterChips().map(f => activeFilterChips.includes(f.value) && f.value)
            });
          });
      }finally {
        // console.log('RowDatas: ', new Date().getTime() - ts);
      }
    }

    const Pagination = () => {
      return <TablePagination  
        rowsPerPageOptions={rowsPerPageOptions}
        component="div"
        count={rowData?.length || 0}
        rowsPerPage={rowsPerPage}
        page={page||0}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        showFirstButton={true}
        showLastButton={true}
      />;
    }

    console.log('TableInput: render', name)
    let activeFilterChips = filterChips.map(f => f.value);
    return <ThemeProvider theme={Theme}>
      <div className={`TableFormInput ${className}`}
        onMouseMove={mouseEvent => divScroller({divRef, mouseEvent})}>
        <div className="sort-indicator">
          {orderBys.length>0 && 
            <Tooltip title="Clear sort order priority">
              <ResetIcon className="ResetIcon" 
                onClick={() => {
                  setOrderBys([]);
                  setIsLoading(true);
                  setRowData([...rowData]);
                }}/>
            </Tooltip>}
          {orderBys.filter(ob => ob !== 1)
            .map((ob, i) => <div className="field" key={i}>
              {getHeaderLabel(ob)}{ob.direction===1? <AcsIcon key={i}/>:<DescIcon key={i}/>}
          </div>)}
        </div>
        <div onClick={onClick}>
          {(onToolBarDelete || toolbarButtons) && 
            <EnhancedTableToolbar
              onSelectedUpdate={onSelectedUpdate}
              numSelected={selected.length}
              title={title}
              onToolBarDelete={
                onToolBarDelete &&
                (() =>
                  onToolBarDelete(selected, () => {
                    setSelected([]);
                    onSelect && onSelect([]);
                  }))
              }
              toolbarButtons={isEditable && toolbarButtons}
              rowCount={(rowData && rowData.length) || 0}
            />}
          <Pagination />
          <TableContainer ref={divRef} className="TableContainer">
            {showFilterChips && <HeaderFilterChips />}
            <Table 
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={"small"}
            >
              <EnhancedTableHead ref={headerRef}
                id={id}
                numSelected={selected.length}
                orderBys={orderBys}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={(event, property) => {
                  setIsLoading(true);
                  handleRequestSort(event, property);
                }}
                rowCount={rowData && rowData.length}
                headCells={headCells.map(hc => {
                  if (filterChips.find(f => f.value === hc.id)) {
                    return hc;
                  }
                }).filter(hc => hc)}
                isEditable={isEditable || onToolBarDelete}
                setFilter={setFilter}
                onFilter={(filter) => {
                  setHeaderFilter(filter);
                  onFilter && onFilter(filter, rowData);
                }}
                isLoading={isLoading}
              />
              
              <TableBody className="TableBody">
                <RowDatas />
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="TablePagination" ref={tablePaginationRef}>
            <Pagination />
          </div>
          {(onToolBarDelete || toolbarButtons) && 
            <EnhancedTableToolbar
              onSelectedUpdate={onSelectedUpdate1 || onSelectedUpdate}
              numSelected={selected.length}
              title={title1 || title}
              onToolBarDelete={
                onToolBarDelete &&
                (() =>
                  onToolBarDelete(selected, () => {
                    setSelected([]);
                    onSelect && onSelect([]);
                  }))
              }
              toolbarButtons={isEditable && (toolbarButtons1 || toolbarButtons)}
              rowCount={rowData && rowData.length}
            />}
        </div>
      </div>
    </ThemeProvider>;
  }
);

export default TableFormInput;
